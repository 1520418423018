import React, { useState } from "react";
import { Modal, Button, Row, Col, Form } from "react-bootstrap";
import axios from "axios";
import AlertCustom from "../../../Components/Modal/AlertCustomComponent";
import { AiFillEdit } from "react-icons/ai";

function EditFileProspect(props) {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [message, setMessage] = useState({
    type: "",
    message: "",
    show: "d-none",
  });
  const [disable, setDisable] = useState(false);
  const [namaFile, setNamaFile] = useState("");
  const loginUrl = process.env.REACT_APP_LOGIN_TOKEN_URL;

  const getDataEdit = () => {
    setDisable(true);
    axios
      .get(
        process.env.REACT_APP_BE_DBTALENT_URL +
          `talent/report/prospect/edit/` +
          props.id,
        {
          headers: {
            Authorization: `Bearer ${props.token}`,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        setNamaFile(res.data.data.report_name);
        setDisable(false);
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 401) {
            window.open(loginUrl, "_parent");
          }
        }
      });
  };

  const submitEditFileProspect = () => {
    const data = new FormData();
    data.append("report_name", namaFile);

    if (namaFile === "") {
      var message = "Nama file tidak boleh kosong";
      setMessage({
        type: "danger",
        message: message,
        show: "d-block",
      });
    } else {
      setDisable(true);
      axios
        .post(
          process.env.REACT_APP_BE_DBTALENT_URL +
            `talent/report/prospect/update/` +
            props.id,
          data,
          {
            headers: {
              Authorization: `Bearer ${props.token}`,
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          setMessage({
            type: "success",
            message: "Berhasil Mengubah File",
            show: "d-block",
          });
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        })
        .catch((error) => {
          setDisable(false);
          var message = error.message;
          if (typeof error.response != "undefined") {
            message = error.response.data.message;
          }
          setMessage({
            type: "danger",
            message: message,
            show: "d-block",
          });

          if (error.response) {
            if (error.response.status === 401) {
              window.open(loginUrl, "_parent");
            }
          }
        });
    }
  };

  const changeNamaFile = (e) => {
    let val = e.target.value;
    setNamaFile(val);
  };

  return (
    <>
      <Button
        disabled={props.disable}
        variant="light"
        className="mr-2 pt-1"
        onClick={() => {
          handleShow();
          getDataEdit();
        }}
      >
        <AiFillEdit className="mr-2" size={16} />
        Edit File
      </Button>
      <Modal
        show={show}
        onHide={handleClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <AlertCustom
          setMessage={setMessage}
          showError={message.show}
          message={message.message}
          type={message.type}
        ></AlertCustom>

        <Modal.Header closeButton>
          <Modal.Title>Edit File</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="mb-0">
            <Col sm="12" className="mt-0">
              <Form.Group className="mb-0" controlId="nama">
                <Form.Label>Edit File</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Masukan Nama File"
                  value={namaFile}
                  onChange={changeNamaFile}
                />
              </Form.Group>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="outline-danger"
            className="px-5 mr-2"
            onClick={handleClose}
          >
            Batal
          </Button>
          <Button
            variant="danger"
            className="px-5 py-2"
            disabled={disable}
            onClick={submitEditFileProspect}
          >
            Ubah
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default EditFileProspect;
