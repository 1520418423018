import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";

function PaginationSelectedCard(prop) {
  var startOffset =
    ((parseInt(prop.item.page) - 1) * parseInt(prop.item.per_page)) %
    parseInt(prop.item.total);
  var endOffset = startOffset + parseInt(prop.item.tampil);

  var tampilHapusSemua = false;

  function hapusFilter(key, value) {
    var array = "";
    if (key === "gender" || key === "peran" || key === "talent_prospect") {
      array = prop.filter[key];
      var index = array.indexOf(value);
      if (index !== -1) {
        array.splice(index, 1);
      }
    }
    prop.setFilter({
      ...prop.filter,
      submit: true,
      filter_id: "",
      [key]: array,
    });
  }

  function hapusSemua() {
    prop.setFilter({
      nama_artis: "",
      gender: [1, 2],
      usia_min: "",
      usia_maks: "",
      tinggi_min: "",
      tinggi_maks: "",
      berat_min: "",
      berat_maks: "",
      peran: [],
      nilai_casting_min: "",
      nilai_casting_maks: "",
      talent_prospect: [],
      judul_sinetron: "",
      available: "",
      penerimaan_artis_dari_tanggal: "",
      penerimaan_artis_sampai_tanggal: "",
      filter_id: "",
      tampil_filter: false,
      submit: true,
      category_casting: [],
    });
  }

  return (
    <div className="mt-5">
      <span>
        {prop.item.total > 0
          ? `Menampilkan ${prop.item.tampil} Artis (${
              startOffset + 1
            }-${endOffset} dari ${prop.item.total})`
          : "Data tidak ditemukan"}
      </span>
      <div className="d-flex align-items-center">
        <div className="d-flex align-items-center my-3 overflow-auto">
          {Object.keys(prop.filter).map((item, index) => {
            if (
              prop.filter[item].length > 0 &&
              prop.filter.tampil_filter === true
            ) {
              tampilHapusSemua = true;
              var nama = prop.filter[item];
              if (item === "gender") {
                return (
                  prop.filter[item] &&
                  prop.filter[item].map((subItem, subIndex) => {
                    nama = subItem === 1 ? "Laki-Laki" : "Perempuan";
                    return (
                      <Button
                        variant="outline-dark"
                        className="filter-button"
                        type="button"
                        onClick={() => hapusFilter(item, subItem)}
                      >
                        {item.replace(/_/g, " ") + ": " + nama}{" "}
                        <i className="fa fa-times-circle"></i>
                      </Button>
                    );
                  })
                );
              } else if (item === "peran") {
                return (
                  prop.filter[item] &&
                  prop.filter[item].map((subItem, subIndex) => {
                    nama = subItem === 1 ? "Protagonis" : "Antagonis";
                    return (
                      <Button
                        variant="outline-dark"
                        className="filter-button"
                        type="button"
                        onClick={() => hapusFilter(item, subItem)}
                      >
                        {item.replace(/_/g, " ") + ": " + nama}{" "}
                        <i className="fa fa-times-circle"></i>
                      </Button>
                    );
                  })
                );
              } else if (item === "talent_prospect") {
                return (
                  prop.filter[item] &&
                  prop.filter[item].map((subItem, subIndex) => {
                    const index = prop.talentProsepect
                      .map((object) => object.id)
                      .indexOf(prop.filter[item][subIndex]);
                    if (index > -1) {
                      return (
                        <Button
                          variant="outline-dark"
                          className="filter-button"
                          type="button"
                          onClick={() => hapusFilter(item, subItem)}
                        >
                          {item.replace(/_/g, " ") +
                            ": " +
                            prop.talentProsepect[index].nama}{" "}
                          <i className="fa fa-times-circle"></i>
                        </Button>
                      );
                    }
                  })
                );
              } else if (item === "available") {
                var namas = "Available & Not Available";
                if (nama === "1") {
                  namas = "Available";
                } else if (nama === "0") {
                  namas = "Not Available";
                }
                return (
                  <Button
                    variant="outline-dark"
                    className="filter-button"
                    type="button"
                    onClick={() => hapusFilter(item, nama)}
                  >
                    {item.replace(/_/g, " ") + ": " + namas}{" "}
                    <i className="fa fa-times-circle"></i>
                  </Button>
                );
              } else if (item === "eksklusif") {
                var namas = "Eksklusif & Not Eksklusif";
                if (nama === "1") {
                  namas = "Eksklusif";
                } else if (nama === "0") {
                  namas = "Not Eksklusif";
                }
                return (
                  <Button
                    variant="outline-dark"
                    className="filter-button"
                    type="button"
                    onClick={() => hapusFilter(item, nama)}
                  >
                    {item.replace(/_/g, " ") + ": " + namas}{" "}
                    <i className="fa fa-times-circle"></i>
                  </Button>
                );
              } else if (item === "category_casting") {
                let namas = "";

                if (Array.isArray(nama)) {
                  namas = nama
                    .map((element) => {
                      const data = prop.categoryCasting.find(
                        (items) => items.value == element
                      );
                      return data ? data.label : null;
                    })
                    .filter(Boolean)
                    .join(", ");
                }

                return (
                  <Button
                    variant="outline-dark"
                    className="filter-button"
                    type="button"
                    onClick={() => hapusFilter(item, nama)}
                  >
                    {item.replace(/_/g, " ") + ": " + namas.trim()}{" "}
                    <i className="fa fa-times-circle"></i>
                  </Button>
                );
              } else {
                return (
                  <Button
                    variant="outline-dark"
                    className="filter-button"
                    type="button"
                    onClick={() => hapusFilter(item, nama)}
                  >
                    {item.replace(/_/g, " ") + ": " + nama}{" "}
                    <i className="fa fa-times-circle"></i>
                  </Button>
                );
              }
            }
          })}
        </div>
        {tampilHapusSemua === true ? (
          <div className="ml-5">
            <a className="text-danger" onClick={() => hapusSemua()}>
              Hapus Semua
            </a>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}

export default PaginationSelectedCard;
