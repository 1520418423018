import React, { useEffect, useRef, useState } from "react";
import { Container, Button, Navbar, Image } from "react-bootstrap";
import {
  Grid,
  Form,
  Card,
  TabbedHeader,
  TabbedContainer,
  Tab,
} from "tabler-react";
import { Link, useHistory } from "react-router-dom";
import { Collapse } from "antd";
import "react-datepicker/dist/react-datepicker.css";
import {
  DiagramComponent,
  SymbolPaletteComponent,
  NodeConstraints,
  NodeModel,
  ConnectorModel,
  AnnotationConstraints,
  GridlinesModel,
  Node,
  IDraggingEventArgs,
  IDragEnterEventArgs,
  IDragLeaveEventArgs,
  IDragOverEventArgs,
  ICollectionChangeEventArgs,
  Inject,
  PrintAndExport,
  CommandManagerModel,
  Keys,
  KeyModifiers,
  snapSettings,
  SnapConstraints
} from "@syncfusion/ej2-react-diagrams";
import axios from "axios";
import Loader from "react-loader-spinner";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import domtoimage from "dom-to-image-more";
import AlertCustom from "../../../../Components/Modal/AlertCustomComponent";
import ReactToPrint, { PrintContextConsumer } from 'react-to-print';

function EditBagan(prop) {
  const urlMicroservice = process.env.REACT_APP_BE_DBTALENT_URL;
  const token = localStorage.getItem("token");
  const [loader, setLoader] = useState(true);
  const [selectedTitle, setSelectedTitle] = useState("Pemain Kontrak");
  const [bagan, setBagan] = useState({
    id: prop.match.params.id,
    bagan_name: "",
    diagram: "",
  });
  const [flowshapes, setFlowshapes] = useState([]);
  const [baganData, setBaganData] = useState([]);
  const [baganSp, setBaganSp] = useState([]);
  const [updated, setUpdated] = useState(0);
  const [message, setMessage] = useState({
    type: "",
    message: "",
    show: "d-none",
  });
  const [disable, setDisable] = useState(false);
  const history = useHistory();
  let diagramInstance;

  const [snapSet, setSnapSet] = useState(false);
  const onBeforeGetContentResolve = React.useRef(null);

  const getCommandManagerSettings = () => {
    let commandManager: CommandManagerModel = {
      commands: [
        {
          name: "copy",
          execute: function (args) {},
        },
      ],
    };
    return commandManager;
  };

  const castingImage = (
    nama_peran,
    talent,
  ) => `<div class="text-center font-weight-bold">
                                                  <div class="d-flex" style="justify-content:center;">
                                                    ${
                                                      talent
                                                        ? talent
                                                        : '<div class="casting-bagan-component mx-2 mt-2"><i class="bx bxs-user"></i></div>'
                                                    }
                                                  </div>
                                                  <div class="pt-2 font-14">${nama_peran}</div>
                                                </div>
                                            `;

  function tabClick(params) {
    setSelectedTitle(params);
  }

  function onItemClick(args) {
    switch (args) {
      case "zoom-in":
        let zoomin = { type: "ZoomIn", zoomFactor: 0.2 };
        diagramInstance.zoomTo(zoomin);
        break;
      case "zoom-out":
        let zoomout = { type: "ZoomOut", zoomFactor: 0.2 };
        diagramInstance.zoomTo(zoomout);
        break;
    }
  }

  useEffect(() => {
    axios
      .get(
        `${urlMicroservice}talent/bagan/create/bagan-flow/${prop.match.params.id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {})
      .catch((e) => {
        var message = e.response.data.message;
        if (!message) {
          message = e.message;
        }
        setMessage({
          type: "danger",
          message: JSON.stringify(message),
          show: "d-block",
        });
      });

    axios
      .get(
        `${urlMicroservice}talent/bagan/create/bagan-flow/${prop.match.params.id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        setBagan({
          id: res.data.data.bagan.id,
          bagan_name: res.data.data.bagan.bagan_name,
          diagram: res.data.data.bagan.diagram,
        });
        setBaganData(res.data.data.bagan.shapes);
        setBaganSp(res.data.data.bagan_sp);
        setLoader(false);
      })
      .catch((e) => {
        var message = e.response.data.message;
        if (!message) {
          message = e.message;
        }
        setMessage({
          type: "danger",
          message: JSON.stringify(message),
          show: "d-block",
        });
      });
  }, []);

  useEffect(() => {
    if (baganData) {
      let flow = [];
      let oldId = [];
      baganData &&
        baganData.map((item, index) => {
          oldId.push(item.id);
          // if(item.is_selected==0){
          flow.push({
            id: "shape" + item.id,
            shape: {
              type: "HTML",
              content: castingImage(item.casting.nama_peran, null),
            },
            addInfo: { index: index, id: item.id },
            annotations: [
              {
                // template: `<div style="width:140px; text-align: center; margin-left: 25px">${item.casting.deskripsi}</div>`,
                content: item.casting.deskripsi,
                offset: { x: 0.5, y: 1 },
                margin: {
                  top: -20,
                },
                horizontalAlignment: "Center",
                verticalAlignment: "Top",
                style: {
                  color: "#73767A",
                  fontSize: "12",
                  textWrapping: "WrapWithOverflow",
                },
                constraints: AnnotationConstraints.ReadOnly,
              },
            ],
            constraints:
              NodeConstraints.Default &
              ~(NodeConstraints.Rotate | NodeConstraints.Resize),
          });
          // }else{
          let talent = "";
          let newCastingImage = castingImage(item.casting.nama_peran, null);
          let width = 130;

          var res = item.detail_shapes.filter((val) => {
            return val.is_selected == 1;
          });
          if (res.length > 0) {
            width = 0;
          }

          if (item.detail_shapes.some((data) => data.is_selected == 1)) {
            item.detail_shapes &&
              item.detail_shapes.map((subItem, subIndex) => {
                if (subItem.is_selected == 1) {
                  width += 130;
                  talent += `<div class="casting-bagan-component mx-2 mt-2">
                              <img src="${subItem.casting_talent.artist.image}">
                          </div>
                        `;
                }
              });
            newCastingImage = castingImage(item.casting.nama_peran, talent);
          }
          if (item.old_id) {
            var newIndex = diagramInstance.nodes.findIndex((p) =>
              p.addInfo ? p.addInfo.id == item.old_id : false
            );
            if(newIndex === -1){
              var newIndex = diagramInstance.nodes.findIndex((p) =>
                p.addInfo ? p.addInfo.id == item.id : false
              );
            }
            if (newIndex !== -1) {
              diagramInstance.nodes[newIndex].addInfo.id = item.id;
              diagramInstance.nodes[newIndex].addInfo.index = index;
            }
            item.old_id = null;
          } else {
            var newIndex = diagramInstance.nodes.findIndex((p) =>
              p.addInfo ? p.addInfo.id == item.id : false
            );
          }

          if (newIndex !== -1) {
            diagramInstance.nodes[newIndex].addInfo.index = index;
            diagramInstance.nodes[newIndex].shape = {
              type: "HTML",
              content: newCastingImage,
            };
            diagramInstance.nodes[newIndex].width = width;
          }
          // }
        });
      setFlowshapes(flow);

      let oldIdDiagram = [];
      if (oldId.length > 0) {
        diagramInstance.nodes.map((item2, index2) => {
          if (item2.addInfo) {
            if (!oldId.includes(item2.addInfo.id)) {
              item2.addInfo.jenis = "old";
              oldIdDiagram.push(item2.addInfo.id);
            }
          }
        });
      }

      oldIdDiagram.map((item3, index3) => {
        var oldIndex = diagramInstance.nodes.findIndex((p) =>
          p.addInfo ? p.addInfo.id == item3 : false
        );
        diagramInstance.remove(diagramInstance.nodes[oldIndex]);
      });
    }
  }, [baganData]);

  useEffect(() => {
    if (bagan.diagram) {
      var baganNew = bagan.diagram;
      if (!baganNew) {
        baganNew = { width: "100%", height: "720" };
      }
      diagramInstance.loadDiagram(JSON.stringify(baganNew));
    }
  }, [bagan.diagram]);

  function tambahCastingData(args) {
    if (args.dragItem.addInfo) {
      var newIndex = diagramInstance.nodes.findIndex((p) =>
        p.addInfo ? p.addInfo.id == args.dragItem.addInfo.id : false
      );
      if (newIndex != -1) {
        setMessage({
          type: "danger",
          message: "Casting sudah dipilih!",
          show: "d-block",
        });
        args.cancel = true;
      } else {
        var index = args.dragItem.addInfo.index;
        var oldBaganData = baganData[index];
        oldBaganData.is_selected = 1;

        setBaganData((data) => {
          return [
            ...data.slice(0, index),
            oldBaganData,
            ...data.slice(index + 1),
          ];
        });
      }
    }
  }

  function hapusCastingData(args) {
    if (
      args.state === "Changed" &&
      args.type === "Removal" &&
      args.element.addInfo &&
      !args.element.addInfo.jenis
    ) {
      var index = args.element.addInfo.index;
      var oldBaganData = baganData[index];
      oldBaganData.is_selected = 0;

      if (oldBaganData.detail_shapes.length > 0) {
        oldBaganData.detail_shapes.map((item) => {
          item.is_selected = 0;
        });
      }

      setBaganData((data) => {
        return [
          ...data.slice(0, index),
          oldBaganData,
          ...data.slice(index + 1),
        ];
      });
    }
  }

  function aksiPemainData(shape_index, detail_shapes_index, aksi) {
    var oldPemainData = baganData[shape_index];
    oldPemainData.detail_shapes[detail_shapes_index].is_selected = aksi;
    setBaganData((data) => {
      return [
        ...data.slice(0, shape_index),
        oldPemainData,
        ...data.slice(shape_index + 1),
      ];
    });
  }

  function changePemainSp(bagan_sp_index, sp_cast_details_index, aksi) {
    var oldPemainSp = baganSp[bagan_sp_index];
    oldPemainSp.sp_cast_details[sp_cast_details_index].is_selected = aksi;
    setBaganSp((data) => {
      return [
        ...data.slice(0, bagan_sp_index),
        oldPemainSp,
        ...data.slice(bagan_sp_index + 1),
      ];
    });
  }

  function print() {
    const input = document.getElementById("diagram");
    domtoimage.toPng(input).then(function (blob) {
      const pdf = new jsPDF({
        orientation: "landscape",
        unit: "in",
        format: [10, 10],
      });
      pdf.addImage(blob, "PNG", 0, 0);
      pdf.save(bagan.bagan_name + ".pdf");
    });
  }

  function simpan() {
    setDisable(true);
    const data = new FormData();

    baganSp &&
      baganSp.map((item) => {
        item.sp_cast_details.map((subItem) => {
          data.append("sp_cast_detail_id[]", subItem.id);
          data.append("selected_sp_cast_detail[]", subItem.is_selected);
        });
      });

    baganData &&
      baganData.map((item) => {
        data.append("shape_id[]", item.id);
        data.append("selected_shape[]", item.is_selected);
        item.detail_shapes.map((subItem) => {
          data.append("detail_shape_id[]", subItem.id);
          data.append("selected_detail_shape[]", subItem.is_selected);
        });
      });

    data.append("diagram", diagramInstance.saveDiagram());
    data.append("is_updated", updated);

    axios
      .post(
        `${urlMicroservice}talent/bagan/modify/bagan-flow/${prop.match.params.id}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        setMessage({
          type: "success",
          message: `Berhasil mengubah bagan`,
          show: "d-block",
        });
        setTimeout(function () {
          history.push(`/bagan/detail/${prop.match.params.id}`);
        }, 1000);
      })
      .catch((e) => {
        setDisable(false);
        var message = e.response.data.message;
        if (!message) {
          message = e.message;
        }
        setMessage({
          type: "danger",
          message: JSON.stringify(message),
          show: "d-block",
        });
      });
  }
  
  function simpanExport() {
    setDisable(true);
    const data = new FormData();

    baganSp &&
      baganSp.map((item) => {
        item.sp_cast_details.map((subItem) => {
          data.append("sp_cast_detail_id[]", subItem.id);
          data.append("selected_sp_cast_detail[]", subItem.is_selected);
        });
      });

    baganData &&
      baganData.map((item) => {
        data.append("shape_id[]", item.id);
        data.append("selected_shape[]", item.is_selected);
        item.detail_shapes.map((subItem) => {
          data.append("detail_shape_id[]", subItem.id);
          data.append("selected_detail_shape[]", subItem.is_selected);
        });
      });

    data.append("diagram", diagramInstance.saveDiagram());
    data.append("is_updated", updated);

    axios
      .post(
        `${urlMicroservice}talent/bagan/modify/bagan-flow/${prop.match.params.id}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        
      })
      .catch((e) => {
        setDisable(false);
        var message = e.response.data.message;
        if (!message) {
          message = e.message;
        }
        setMessage({
          type: "danger",
          message: JSON.stringify(message),
          show: "d-block",
        });
      });
  }

  const handleAfterPrint = React.useCallback(() => {
    setSnapSet(false);
    window.location.reload();
  }, []);

  const handleBeforePrint = React.useCallback(() => {
    setSnapSet(true);
  }, []);
  
  const handleOnBeforeGetContent = React.useCallback(() => {
    setLoader(true);

    return new Promise((resolve) => {
      onBeforeGetContentResolve.current = resolve;
      setSnapSet(true);

      setTimeout(() => {
        setLoader(false);
        resolve();
      }, 2000);
    });
  }, [setLoader]);

  let connectorSymbols: ConnectorModel[] = [
    {
      id: "links1",
      type: "Orthogonal",
      sourcePoint: { x: 0, y: 0 },
      targetPoint: { x: 70, y: 70 },
      targetDecorator: {
        shape: "Arrow",
        style: { strokeColor: "#757575", fill: "#757575" },
      },
      style: { strokeWidth: 1, strokeColor: "#757575" },
    },
    {
      id: "links2",
      type: "Orthogonal",
      sourcePoint: { x: 0, y: 0 },
      targetPoint: { x: 70, y: 70 },
      style: { strokeWidth: 1, strokeColor: "#757575" },
      targetDecorator: {
        shape: "None",
        style: { strokeColor: "#757575", fill: "#757575" },
      },
    },
    {
      id: "links3",
      type: "Straight",
      sourcePoint: { x: 0, y: 0 },
      targetPoint: { x: 70, y: 70 },
      targetDecorator: {
        shape: "Arrow",
        style: { strokeColor: "#757575", fill: "#757575" },
      },
      style: { strokeWidth: 1, strokeColor: "#757575" },
    },
    {
      id: "links4",
      type: "Straight",
      sourcePoint: { x: 0, y: 0 },
      targetPoint: { x: 70, y: 70 },
      style: { strokeWidth: 1, strokeColor: "#757575" },
      targetDecorator: { shape: "None" },
    },
    {
      id: "links5",
      type: "Bezier",
      sourcePoint: { x: 0, y: 0 },
      targetPoint: { x: 70, y: 70 },
      style: { strokeWidth: 1, strokeColor: "#757575" },
      targetDecorator: { shape: "None" },
    },
    {
      id: "links6",
      type: "Orthogonal",
      sourcePoint: { x: 0, y: 0 },
      targetPoint: { x: 70, y: 70 },
      targetDecorator: {
        shape: "Arrow",
        style: { strokeColor: "#757575", fill: "#757575" },
      },
      style: { strokeWidth: 1, strokeColor: "#757575", strokeDashArray: "5,5" },
    },
    {
      id: "links7",
      type: "Orthogonal",
      sourcePoint: { x: 0, y: 0 },
      targetPoint: { x: 70, y: 70 },
      style: { strokeWidth: 1, strokeColor: "#757575", strokeDashArray: "5,5" },
      targetDecorator: {
        shape: "None",
        style: { strokeColor: "#757575", fill: "#757575" },
      },
    },
    {
      id: "links8",
      type: "Straight",
      sourcePoint: { x: 0, y: 0 },
      targetPoint: { x: 70, y: 70 },
      targetDecorator: {
        shape: "Arrow",
        style: { strokeColor: "#757575", fill: "#757575" },
      },
      style: { strokeWidth: 1, strokeColor: "#757575", strokeDashArray: "5,5" },
    },
    {
      id: "links9",
      type: "Straight",
      sourcePoint: { x: 0, y: 0 },
      targetPoint: { x: 70, y: 70 },
      style: { strokeWidth: 1, strokeColor: "#757575", strokeDashArray: "5,5" },
      targetDecorator: { shape: "None" },
    },
    {
      id: "links10",
      type: "Bezier",
      sourcePoint: { x: 0, y: 0 },
      targetPoint: { x: 70, y: 70 },
      style: { strokeWidth: 1, strokeColor: "#757575", strokeDashArray: "5,5" },
      targetDecorator: { shape: "None" },
    },
  ];

  let textSymbol: NodeModel[] = [
    {
      id: "Text",
      width: 50,
      height: 20,
      shape: {
        type: "Text",
        content: "Text 1",
      },
      style: {
        strokeColor: "none",
        fill: "none",
        color: "black",
        textAlign: "Center",
      },
    },
  ];

  let gridlines: GridlinesModel = {
    lineColor: "#ebebeb",
    lineIntervals: [1, 9, 0.01],
  };

  return (
    <>
      <style type="text/css" media="print">{"\
        @page {\ size: landscape;\ }\
      "}</style>
      <Container fluid>
        <Loader
          visible={loader}
          className="loaders"
          type="ThreeDots"
          color="#ED1C24"
          height="100"
          width="100"
        />
        <AlertCustom
          setMessage={setMessage}
          showError={message.show}
          message={message.message}
          type={message.type}
        ></AlertCustom>
        <Form>
          <Grid.Row className="px-0">
            <Grid.Col width="12">
              <Navbar expand="lg" variant="light" bg="white" fixed="top">
                <Container>
                  <Navbar.Toggle />
                  <Navbar.Collapse className="justify-content-between">
                    <Navbar.Brand className="d-flex align-self-center font-weight-bold">
                      <Link to={`/bagan/detail/` + bagan.id}>
                        <i className="bx bx-x p-1 rounded bg-light"></i>
                      </Link>{" "}
                      <span className="ml-3">{bagan.bagan_name}</span>
                    </Navbar.Brand>
                    <ul class="nav nav-tabs Tab_header_tabs">
                      <li
                        class="nav-item"
                        onClick={() => tabClick("Pemain Kontrak")}
                      >
                        <a
                          class={
                            "nav-link " +
                            (selectedTitle === "Pemain Kontrak" ? "active" : "")
                          }
                        >
                          Pemain Kontrak
                        </a>
                      </li>
                      <li
                        class="nav-item"
                        onClick={() => tabClick("Pemain SP")}
                      >
                        <a
                          class={
                            "nav-link " +
                            (selectedTitle === "Pemain SP" ? "active" : "")
                          }
                        >
                          Pemain SP
                        </a>
                      </li>
                    </ul>
                    <Navbar.Text className="d-flex">
                      <div className="bg-light mr-2 border">
                        <Button
                          variant="none"
                          type="button"
                          className="border"
                          onClick={() => onItemClick("zoom-out")}
                        >
                          <i className="bx bx-zoom-out font-16"></i>
                        </Button>
                        <Button
                          variant="none"
                          type="button"
                          className="border"
                          onClick={() => onItemClick("zoom-in")}
                        >
                          <i className="bx bx-zoom-in font-16"></i>
                        </Button>
                      </div>
                      
                      <ReactToPrint 
                        content={() => diagramInstance}
                        documentTitle={bagan.bagan_name}
                        onAfterPrint={handleAfterPrint}
                        onBeforeGetContent={handleOnBeforeGetContent}
                        onBeforePrint={handleBeforePrint}
                        removeAfterPrint
                      >

                        <PrintContextConsumer>
                          {({ handlePrint }) => (
                            <Button
                              variant="light"
                              className="mr-2"
                              onClick={() => {simpanExport(); handlePrint();}}
                            >
                              <i className="bx bxs-file-pdf"></i> Export ke PDF
                            </Button>
                          )}
                        </PrintContextConsumer>

                      </ReactToPrint>

                      <Button
                        variant="danger"
                        onClick={() => simpan()}
                        disabled={disable}
                      >
                        Simpan
                      </Button>
                    </Navbar.Text>
                  </Navbar.Collapse>
                </Container>
              </Navbar>
            </Grid.Col>
          </Grid.Row>
          {/* <TabbedContainer selectedTitle={selectedTitle}> */}
          <Grid.Row
            className={
              "justify-content-md-center page-top-2 " +
              (selectedTitle === "Pemain Kontrak" ? "" : "d-none")
            }
            title="Pemain Kontrak"
          >
            <Grid.Col width="3" className="p-0">
              <Card className="h-100">
                <Card.Body className="p-0 componentPalette-card"
                  style={{overflow:"auto",height:"720px"}}>
                  <h4 className="font-weight-bold px-3 pt-5 pb-2">Komponen</h4>
                  <SymbolPaletteComponent
                    id="componentPalette"
                    expandMode="Single"
                    palettes={[
                      {
                        id: "flow",
                        expanded: true,
                        symbols: flowshapes,
                        title: "Casting",
                      },
                      {
                        id: "connectors",
                        expanded: false,
                        symbols: connectorSymbols,
                        title: "Konektor",
                      },
                      {
                        id: "texts",
                        expanded: false,
                        symbols: textSymbol,
                        title: "Teks",
                      },
                    ]}
                    width={"100%"}
                    style={{overflow:"unset"}}
                    getNodeDefaults={(symbol: NodeModel): void => {
                      if (symbol.id != "Text") {
                        symbol.width = 95;
                        symbol.height = 186;
                        symbol.style.strokeColor = "#fff";
                      }
                    }}
                    symbolMargin={{
                      left: 5,
                      right: 5,
                      top: 15,
                      bottom: 15,
                    }}
                  />
                </Card.Body>
              </Card>
            </Grid.Col>
            <Grid.Col width="6" className="p-0">
              <div className="w-100 bg-white diagram-test">
                <DiagramComponent
                  id="diagram"
                  ref={(diagram) => (diagramInstance = diagram)}
                  width={"100%"}
                  height={"720"}
                  snapSettings={
                    snapSet? 
                    {
                        horizontalGridlines: gridlines,
                        verticalGridlines: gridlines,
                        constraints: SnapConstraints.None
                    }
                    :
                    {
                        horizontalGridlines: gridlines,
                        verticalGridlines: gridlines,
                    }
                  }
                  dragEnter={(args: IDragEnterEventArgs) => {
                    tambahCastingData(args);
                  }}
                  collectionChange={(args: ICollectionChangeEventArgs) => {
                    hapusCastingData(args);
                  }}
                  commandManager={getCommandManagerSettings()}
                >
                  <Inject services={[PrintAndExport]} />
                </DiagramComponent>
                <div className="text-center text-grey">
                  Cara menghapus gambar dari playbook dengan cara klik “Delete”
                  pada keyboard
                </div>
              </div>
            </Grid.Col>
            <Grid.Col width="3" className="p-0">
              <Card className="h-100">
                <Card.Body className="px-5">
                  <div className="mb-4 font-12 text-justify">
                    Klik talent untuk otomatis menempatkannya pada placeholder
                    casting yang telah di atur pada playbook.
                  </div>
                  <div className="casting-scroll2">
                    {baganData &&
                      baganData.map((item, index) => {
                        if (item.is_selected == 1) {
                          return (
                            <div className="bagan-pemeran mb-5">
                              <div className="text-grey mb-2">
                                {item.casting.nama_peran}
                              </div>
                              {item.detail_shapes &&
                                item.detail_shapes.map((subItem, subIndex) => {
                                  return (
                                    <div className="mb-2 d-flex justify-content-between align-items-center">
                                      <div>
                                        <Image
                                          src={
                                            subItem.casting_talent.artist.image
                                          }
                                        ></Image>
                                        <span className="font-weight-bold font-14 ml-4">
                                          {
                                            subItem.casting_talent.artist
                                              .nama_artis
                                          }
                                        </span>
                                      </div>
                                      {subItem.is_selected == 0 ? (
                                        <Button
                                          variant="outline-danger"
                                          onClick={() =>
                                            aksiPemainData(index, subIndex, 1)
                                          }
                                        >
                                          <i className="bx bx-plus"></i>
                                        </Button>
                                      ) : (
                                        <Button
                                          variant="outline-danger"
                                          onClick={() =>
                                            aksiPemainData(index, subIndex, 0)
                                          }
                                        >
                                          <i className="bx bx-minus"></i>
                                        </Button>
                                      )}
                                    </div>
                                  );
                                })}
                            </div>
                          );
                        }
                      })}
                  </div>
                </Card.Body>
              </Card>
            </Grid.Col>
          </Grid.Row>
          <Grid.Row
            className={
              "justify-content-md-center page-top " +
              (selectedTitle === "Pemain SP" ? "" : "d-none")
            }
            title="Pemain SP"
          >
            <Grid.Col width="6">
              <Card className="h-100">
                <Card.Body className="px-5">
                  <h4 className="font-weight-bold pb-2">Daftar Pemain SP</h4>
                  {baganSp &&
                    baganSp.map((item, index) => {
                      return (
                        <div className="mb-5">
                          <div className="text-grey mb-2">
                            Pemeran {item.casting.nama_peran}
                          </div>
                          <div className="bagan-sp">
                            {item.sp_cast_details &&
                              item.sp_cast_details.map((subItem, subIndex) => {
                                if (subItem.is_selected == 1) {
                                  return (
                                    <div className="mb-2 list-sp">
                                      <Image
                                        src={
                                          subItem.casting_talent.artist.image
                                        }
                                        className="rounded"
                                      ></Image>
                                      <div className="img-close">
                                        <Button
                                          variant="outline-danger"
                                          onClick={() =>
                                            changePemainSp(index, subIndex, 0)
                                          }
                                        >
                                          <i className="bx bxs-x-circle"></i>
                                        </Button>
                                      </div>
                                      <div className="font-weight-bold mt-2 text-center">
                                        {
                                          subItem.casting_talent.artist
                                            .nama_artis
                                        }
                                      </div>
                                    </div>
                                  );
                                }
                              })}
                          </div>
                        </div>
                      );
                    })}
                </Card.Body>
              </Card>
            </Grid.Col>
            <Grid.Col width="3">
              <Card className="h-100">
                <Card.Body className="px-5">
                  <h4 className="font-weight-bold pb-2">
                    Pemain Yang Tidak Digunakan
                  </h4>
                  {baganSp &&
                    baganSp.map((item, index) => {
                      return (
                        <div className="bagan-pemeran mb-5">
                          <div className="text-grey mb-2">
                            Pemeran {item.casting.nama_peran}
                          </div>
                          {item.sp_cast_details &&
                            item.sp_cast_details.map((subItem, subIndex) => {
                              if (subItem.is_selected == 0) {
                                return (
                                  <div className="mb-2 d-flex justify-content-between align-items-center">
                                    <div>
                                      <Image
                                        src={
                                          subItem.casting_talent.artist.image
                                        }
                                      ></Image>
                                      <span className="font-weight-bold font-14 ml-4">
                                        {
                                          subItem.casting_talent.artist
                                            .nama_artis
                                        }
                                      </span>
                                    </div>
                                    <Button
                                      variant="outline-danger"
                                      onClick={() =>
                                        changePemainSp(index, subIndex, 1)
                                      }
                                    >
                                      <i className="bx bx-plus"></i>
                                    </Button>
                                  </div>
                                );
                              }
                            })}
                        </div>
                      );
                    })}
                </Card.Body>
              </Card>
            </Grid.Col>
          </Grid.Row>
          {/* </TabbedContainer> */}
        </Form>
      </Container>
    </>
  );
}

export default EditBagan;
