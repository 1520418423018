export const URL_MICROSERVICE_ENV = process.env.REACT_APP_BE_DBTALENT_URL;

export const API_CREATE = {
  getArtis: (params) =>
    `${URL_MICROSERVICE_ENV}talent/filter/artis/create?${params}`,
  GetKandidatDanArtis: (params) =>
    `${URL_MICROSERVICE_ENV}talent/filter/all/create?${params}`,
  getArtisDetail: (params) =>
    `${URL_MICROSERVICE_ENV}talent/artis/show?${params}`,
  getArtisNpwp: (params) =>
    `${URL_MICROSERVICE_ENV}talent/artis/npwp?${params}`,
  getArtisBiografi: (params) =>
    `${URL_MICROSERVICE_ENV}talent/artis/biografi?${params}`,
  updateArtisNpwp: (artisId) =>
    `${URL_MICROSERVICE_ENV}talent/artis/npwp/store/${artisId}`,
  updateArtisBiografi: (params) =>
    `${URL_MICROSERVICE_ENV}talent/artis/biografi/store/?${params}`,
  getArtisPhoto: (params) =>
    `${URL_MICROSERVICE_ENV}talent/artis/foto?${params}`,
  getArtisVideo: (params) =>
    `${URL_MICROSERVICE_ENV}talent/artis/video?${params}`,
  getKandidatArtis: (params) =>
    `${URL_MICROSERVICE_ENV}talent/filter/kandidat-artis/create?${params}`,
  getTalentProspect: () => `${URL_MICROSERVICE_ENV}talent/talent-prospect`,
  getTalentProvinces: () => `${URL_MICROSERVICE_ENV}talent/provinsi`,
  getSupplierDataByNIk: () =>
    `${URL_MICROSERVICE_ENV}management/pengajuan/data-supplier-by-nik`,
  getDataNpwp: () => `${URL_MICROSERVICE_ENV}management/pengajuan/data-npwp`,
  validationData: () =>
    `${URL_MICROSERVICE_ENV}management/pengajuan/validation`,
};
