import React, { useState, useEffect, useContext } from "react";
import SiteWrapper from "../../Layouts/SiteWrapper";
import {
  Row,
  Col,
  Accordion,
  Button,
  InputGroup,
  FormControl,
  Card,
  CardGroup,
} from "react-bootstrap";
import ArtisCard from "../../Components/Report/ArtisCardComponent";
import ArtisCardGrade from "../../Components/Report/ArtisCardGradeComponent";
import { AiFillEdit, AiFillDownCircle } from "react-icons/ai";
import { BsBoxArrowUp, BsTrashFill } from "react-icons/bs";
import { Tabs, Collapse, Empty } from "antd";
import "./Report.css";
import { RightCircleFilled } from "@ant-design/icons";
import { Grid, Tab, TabbedHeader, TabbedContainer, Badge } from "tabler-react";
import axios from "axios";
import Loader from "react-loader-spinner";
import BuatFileGrade from "./Modal/BuatFileGrade";
import BuatFileProspect from "./Modal/BuatFileProspect";
import EditFileGrade from "./Modal/EditFileGrade";
import EditFileProspect from "./Modal/EditFileProspect";
import HapusFileGrade from "./Modal/HapusFileGrade";
import HapusFileProspect from "./Modal/HapusFileProspect";
import AlertCustom from "../../Components/Modal/AlertCustomComponent";
import { ContextPermission } from "../../Context/Permission";
import ExpoortPdfGrade from "./Modal/ExportPdf";
import ExpoortPdfProspect from "./Modal/ExportPdfProspect";
import { useLocation, useHistory } from "react-router-dom";
import ShareFileReports from "./Modal/ShareFileReports";
import UnShareFileReports from "./Modal/UnShareFileReports";

const { TabPane } = Tabs;
const { Panel } = Collapse;

function Report() {
  type breadcrumbItem = {|
    +value: string,
    +to?: string,
    +active?: boolean,
  |};

  const breadcrumbItems: Array<breadcrumbItem> = [
    {
      value: "Report",
      to: "/report",
      active: false,
    },
  ];

  const history = useHistory();
  const location = useLocation();
  const lastSegment = location.pathname.split("/").pop();
  const [selectedTitle, setSelectedTitle] = useState(
    lastSegment === "grade" ? "Grade Talent" : "Talent Prospect"
  );
  const [loader, setLoader] = useState(true);
  const token = localStorage.getItem("token");

  const [fileGrade, setFileGrade] = useState("");
  const [listGrade, setListGrade] = useState("");
  const [idFileGrade, setIdFileGrade] = useState("");
  const [nameFileGrade, setNameFileGrade] = useState("");
  const [listArtisGrade, setListArtisGrade] = useState([]);

  const [fileProspect, setFileProsfect] = useState("");
  const [listProspect, setListProsfect] = useState("");
  const [idFileProspect, setIdFileProspect] = useState("");
  const [nameFileProspect, setNameFileProspect] = useState("");
  const [listCategory, setListCategory] = useState("");
  const [enabledButton, setEnabledButton] = useState(false);

  const [message, setMessage] = useState({
    type: "",
    message: "",
    show: "d-none",
  });
  const statePermission = useContext(ContextPermission);
  const loginUrl = process.env.REACT_APP_LOGIN_TOKEN_URL;

  function tabClick(params) {
    setSelectedTitle(params);

    if (params === "Grade Talent") {
      history.push(`/report/grade`);
    } else {
      history.push(`/report/prospect`);
    }
  }

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_BE_DBTALENT_URL + `talent/report/grade`, {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        setLoader(false);
        const grouped = groupByYear(res.data.data.list_report);
        setFileGrade(grouped);
        setListGrade(res.data.data.list_artist);
        setIdFileGrade(res.data.data.report_id);
        setNameFileGrade(res.data.data.report_name);
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 401) {
            window.open(loginUrl, "_parent");
          }
        }
      });

    axios
      .get(process.env.REACT_APP_BE_DBTALENT_URL + `talent/report/prospect`, {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        setLoader(false);
        const grouped = groupByYear(res.data.data.list_report);
        setFileProsfect(grouped);
        // setFileProsfect(res.data.data.list_report);
        setListProsfect(res.data.data.list_artist);
        setNameFileProspect(res.data.data.report_name);
        setIdFileProspect(res.data.data.report_id);
        setListCategory(res.data.data.list_category);
        setEnabledButton(res.data.data.enabled_button);
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 401) {
            window.open(loginUrl, "_parent");
          }
        }
      });
  }, [token]);

  const pilihFileGrade = (id) => {
    setIdFileGrade(id);
    axios
      .get(
        process.env.REACT_APP_BE_DBTALENT_URL + `talent/report/grade/` + id,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        const grouped = groupByYear(res.data.data.list_report);
        setFileGrade(grouped);
        setListGrade(res.data.data.list_artist);
        setNameFileGrade(res.data.data.report_name);
        setEnabledButton(res.data.data.enabled_button);
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 401) {
            window.open(loginUrl, "_parent");
          }
        }
      });
  };

  const pilihFileProspect = (id) => {
    setIdFileProspect(id);
    axios
      .get(
        process.env.REACT_APP_BE_DBTALENT_URL + `talent/report/prospect/` + id,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        const grouped = groupByYear(res.data.data.list_report);
        setFileProsfect(grouped);
        // setFileProsfect(res.data.data.list_report);
        setListProsfect(res.data.data.list_artist);
        setNameFileProspect(res.data.data.report_name);
        setEnabledButton(res.data.data.enabled_button);
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 401) {
            window.open(loginUrl, "_parent");
          }
        }
      });
  };

  const hapusArtisGrade = (idArtis, idFile) => {
    axios
      .delete(
        process.env.REACT_APP_BE_DBTALENT_URL +
          `talent/report/grade/delete-report/` +
          idFile +
          `/artist/` +
          idArtis,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        setMessage({
          type: "success",
          message: "Artis berhasil dihapus dari Grade",
          show: "d-block",
        });
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((error) => {
        var message = error.message;
        if (typeof error.response != "undefined") {
          message = error.response.data.message;
        }
        setMessage({
          type: "danger",
          message: message,
          show: "d-block",
        });

        if (error.response) {
          if (error.response.status === 401) {
            window.open(loginUrl, "_parent");
          }
        }
      });
  };

  const hapusArtisProspect = (idArtis, idFile) => {
    axios
      .delete(
        process.env.REACT_APP_BE_DBTALENT_URL +
          `talent/report/prospect/delete-report/` +
          idFile +
          `/artist/` +
          idArtis,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        setMessage({
          type: "success",
          message: "Artis berhasil dihapus dari Prospect",
          show: "d-block",
        });
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((error) => {
        var message = error.message;
        if (typeof error.response != "undefined") {
          message = error.response.data.message;
        }
        setMessage({
          type: "danger",
          message: message,
          show: "d-block",
        });

        if (error.response) {
          if (error.response.status === 401) {
            window.open(loginUrl, "_parent");
          }
        }
      });
  };

  const ubahKategoriArtisProspect = (idArtis, idCategory, idReport) => {
    const data = new FormData();
    data.append("category_id", idCategory);
    data.append("artist_id", idArtis);

    axios
      .post(
        process.env.REACT_APP_BE_DBTALENT_URL +
          `talent/report/prospect/update-category/` +
          idReport,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        setMessage({
          type: "success",
          message: "Berhasil mengubah kategori artis",
          show: "d-block",
        });
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((error) => {
        var message = error.message;
        if (typeof error.response != "undefined") {
          message = error.response.data.message;
        }
        setMessage({
          type: "danger",
          message: message,
          show: "d-block",
        });

        if (error.response) {
          if (error.response.status === 401) {
            window.open(loginUrl, "_parent");
          }
        }
      });
  };

  const exportGrade = (id) => {
    axios
      .get(
        process.env.REACT_APP_BE_DBTALENT_URL +
          `talent/report/prospect/export/` +
          id,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        console.log("export grade", res);
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 401) {
            window.open(loginUrl, "_parent");
          }
        }
      });
  };

  const groupByYear = (reports) => {
    return reports.reduce((acc, report) => {
      const year = new Date(report.created_at).getFullYear();
      const existingYearGroup = acc.find((group) => group.tahun === year);

      if (existingYearGroup) {
        existingYearGroup.data.push(report);
      } else {
        acc.push({
          tahun: year,
          data: [report],
        });
      }

      return acc;
    }, []);
  };

  function funcSelected(item) {
    return item?.data.some((element) => element.selected == 1) ? 0 : 99;
  }

  return (
    <SiteWrapper breadcrumbItems={breadcrumbItems} title={"Report"}>
      <Loader
        visible={loader}
        className="loaders"
        type="ThreeDots"
        color="#ED1C24"
        height="100"
        width="100"
      />
      {statePermission.home_menu.report.active === 1 ? (
        <>
          <AlertCustom
            setMessage={setMessage}
            showError={message.show}
            message={message.message}
            type={message.type}
          ></AlertCustom>
          <Row className="">
            <Col sm="12">
              <Card className="border-card mt-5">
                <Card.Body className="px-4 py-0 d-flex">
                  <TabbedHeader
                    selectedTitle={selectedTitle}
                    stateCallback={tabClick}
                  >
                    <Tab title="Grade Talent"></Tab>
                    <Tab title="Talent Prospect"></Tab>
                  </TabbedHeader>
                </Card.Body>
              </Card>
            </Col>
          </Row>

          <Row>
            <TabbedContainer selectedTitle={selectedTitle}>
              <Grid.Row cards={true} title="Grade Talent" className="col-12">
                <Col md="3">
                  <Card className="border-card">
                    <Card.Body>
                      <Row className="mb-5 border-bottom">
                        <Col md="12" className="px-3 mb-5">
                          {statePermission.permission.artis
                            .tambah_ke_grade_talent_report.active === 1 ? (
                            <BuatFileGrade token={token} />
                          ) : (
                            <></>
                          )}
                        </Col>
                      </Row>
                      <Row className="mt-0">
                        <Col sm="12">
                          <div className="mt-0">
                            <div className="ml-0">
                              {/* {fileGrade &&
                                fileGrade.map((item, i) => (
                                  <a
                                    className={
                                      (item.selected === 1 ? "active" : "") +
                                      " mini-nav p-2 rounded w-100"
                                    }
                                    onClick={() =>
                                      pilihFileGrade(item.id_parent)
                                    }
                                    type="button"
                                    key={i}
                                  >
                                    {item.report_name}
                                  </a>
                                ))} */}

                              {fileGrade &&
                                fileGrade.map((item, i) => (
                                  <Collapse
                                    defaultActiveKey={funcSelected(item)}
                                    expandIconPosition={"left"}
                                    className="site-collapse-custom-collapse"
                                    expandIcon={({ isActive, key }) => (
                                      <RightCircleFilled
                                        style={{
                                          fontSize: "18px",
                                          color: "#73767A",
                                        }}
                                        rotate={isActive ? 90 : 0}
                                      />
                                    )}
                                  >
                                    <Panel
                                      header={
                                        <span className="font-weight-bold">
                                          {item.tahun}
                                        </span>
                                      }
                                      style={{
                                        borderRadius: "10px",
                                        marginBottom: "5px",
                                      }}
                                    >
                                      <Row className="px-0">
                                        <Col md="12">
                                          {item.data &&
                                            item.data.map((value, key) => (
                                              <a
                                                className={
                                                  (value.selected === 1
                                                    ? "active"
                                                    : "") +
                                                  " mini-nav p-2 rounded w-100"
                                                }
                                                onClick={() =>
                                                  pilihFileGrade(
                                                    value.id_parent
                                                  )
                                                }
                                                type="button"
                                                key={i}
                                              >
                                                {value.report_name}
                                              </a>
                                            ))}
                                        </Col>
                                      </Row>
                                    </Panel>
                                  </Collapse>
                                ))}
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                </Col>
                <Col md="9">
                  <Card className="border-card">
                    <Row className="p-4 justify-content-center align-items-center">
                      <Col sm="4">
                        <h4 className="font-weight-bold">{nameFileGrade}</h4>
                      </Col>
                      <Col sm="8" className="text-right">
                        <UnShareFileReports
                          token={token}
                          disable={!enabledButton}
                          name={nameFileGrade}
                          id={idFileGrade}
                        />
                        <ShareFileReports
                          token={token}
                          disable={!enabledButton}
                          name={nameFileGrade}
                          id={idFileGrade}
                        />
                        <HapusFileGrade
                          token={token}
                          disable={!enabledButton}
                          name={nameFileGrade}
                          id={idFileGrade}
                        />
                        <EditFileGrade
                          disable={!enabledButton}
                          token={token}
                          id={idFileGrade}
                        />
                        <ExpoortPdfGrade
                          token={token}
                          id={idFileGrade}
                          disabled={
                            listGrade && listGrade.length === 0 ? true : false
                          }
                        />
                      </Col>
                    </Row>

                    {listGrade && listGrade.length > 0 ? (
                      <Row className="px-4">
                        <Col md="12">
                          {listGrade &&
                            listGrade.map((item, i) => (
                              <Collapse
                                expandIconPosition={"left"}
                                className="site-collapse-custom-collapse"
                                expandIcon={({ isActive }) => (
                                  <RightCircleFilled
                                    style={{
                                      fontSize: "18px",
                                      color:
                                        item.grade === "A"
                                          ? "#EB4B56"
                                          : item.grade === "B"
                                          ? "#FFB21A"
                                          : "#00B815",
                                    }}
                                    rotate={isActive ? 90 : 0}
                                  />
                                )}
                              >
                                <Panel
                                  header={
                                    <span
                                      className={
                                        item.grade === "A"
                                          ? "text-danger font-weight-bold"
                                          : item.grade === "B"
                                          ? "text-warning font-weight-bold"
                                          : "text-success font-weight-bold"
                                      }
                                    >
                                      Talent Grade {item.grade}
                                    </span>
                                  }
                                  key={i}
                                  className="site-collapse-custom-panel collapse-hot"
                                  style={{
                                    background:
                                      item.grade === "A"
                                        ? "#FFEBEC"
                                        : item.grade === "B"
                                        ? "#FFF8EB"
                                        : "#EBFFED",
                                    borderRadius: "10px",
                                  }}
                                >
                                  {listGrade && listGrade.length > 0 ? (
                                    <Row className="px-0">
                                      <CardGroup className="col-12">
                                        {item.artists.map((item, i) => (
                                          <div
                                            className="d-flex align-items-stretch col-md-3"
                                            style={{ paddingRight: "unset" }}
                                          >
                                            <ArtisCardGrade
                                              listArtis={item}
                                              onClick={hapusArtisGrade}
                                              idFile={idFileGrade}
                                            ></ArtisCardGrade>
                                          </div>
                                        ))}
                                      </CardGroup>
                                    </Row>
                                  ) : (
                                    <Row className="px-4 justify-content-md-center">
                                      <Col sm="12 my-5">
                                        <Empty
                                          image={Empty.PRESENTED_IMAGE_SIMPLE}
                                        />
                                      </Col>
                                    </Row>
                                  )}
                                </Panel>
                              </Collapse>
                            ))}
                        </Col>
                      </Row>
                    ) : (
                      <Row className="px-4 justify-content-md-center">
                        <Col sm="12 my-5">
                          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                        </Col>
                      </Row>
                    )}
                  </Card>
                </Col>
              </Grid.Row>

              <Grid.Row cards={true} title="Talent Prospect" className="col-12">
                <Col md="3">
                  <Card className="border-card">
                    <Card.Body>
                      <Row className="mb-5 border-bottom">
                        <Col md="12" className="px-3 mb-5">
                          {statePermission.permission.kandidat
                            .tambah_ke_talent_prospect_report.active === 1 ? (
                            <BuatFileProspect token={token} />
                          ) : (
                            <></>
                          )}
                        </Col>
                      </Row>
                      <Row className="mt-0">
                        <Col sm="12">
                          <div className="mt-0">
                            <div>
                              {/* {fileProspect &&
                                fileProspect.map((item, i) => (
                                  <a
                                    className={
                                      (item.selected === 1 ? "active" : "") +
                                      " mini-nav p-2 rounded w-100"
                                    }
                                    onClick={() =>
                                      pilihFileProspect(item.id_parent)
                                    }
                                    type="button"
                                    key={i}
                                  >
                                    {item.report_name}
                                  </a>
                                ))} */}
                              {fileProspect &&
                                fileProspect.map((item, i) => (
                                  <Collapse
                                    defaultActiveKey={funcSelected(item)}
                                    expandIconPosition={"left"}
                                    className="site-collapse-custom-collapse"
                                    expandIcon={({ isActive, key }) => (
                                      <RightCircleFilled
                                        style={{
                                          fontSize: "18px",
                                          color: "#73767A",
                                        }}
                                        rotate={isActive ? 90 : 0}
                                      />
                                    )}
                                  >
                                    <Panel
                                      header={
                                        <span className="font-weight-bold">
                                          {item.tahun}
                                        </span>
                                      }
                                      style={{
                                        borderRadius: "10px",
                                        marginBottom: "5px",
                                      }}
                                    >
                                      <Row className="px-0">
                                        <Col md="12">
                                          {item.data &&
                                            item.data.map((value, key) => (
                                              <a
                                                className={
                                                  (value.selected === 1
                                                    ? "active"
                                                    : "") +
                                                  " mini-nav p-2 rounded w-100"
                                                }
                                                onClick={() =>
                                                  pilihFileProspect(
                                                    value.id_parent
                                                  )
                                                }
                                                type="button"
                                                key={i}
                                              >
                                                {value.report_name}
                                              </a>
                                            ))}
                                        </Col>
                                      </Row>
                                    </Panel>
                                  </Collapse>
                                ))}
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                </Col>
                <Col md="9">
                  <Card className="border-card">
                    <Row className="p-4 justify-content-center align-items-center">
                      <Col sm="4">
                        <h4 className="font-weight-bold">{nameFileProspect}</h4>
                      </Col>
                      <Col sm="8" className="text-right">
                        <UnShareFileReports
                          token={token}
                          disable={!enabledButton}
                          name={nameFileProspect}
                          id={idFileProspect}
                        />
                        <ShareFileReports
                          token={token}
                          disable={!enabledButton}
                          name={nameFileProspect}
                          id={idFileProspect}
                        />

                        <HapusFileProspect
                          token={token}
                          disable={!enabledButton}
                          name={nameFileProspect}
                          id={idFileProspect}
                        />
                        <EditFileProspect
                          disable={!enabledButton}
                          token={token}
                          id={idFileProspect}
                        />
                        <ExpoortPdfProspect
                          token={token}
                          id={idFileProspect}
                          disabled={
                            listProspect && listProspect.length === 0
                              ? true
                              : false
                          }
                        />
                      </Col>
                    </Row>

                    {listProspect && listProspect.length > 0 ? (
                      <Row className="px-4">
                        <Col md="12">
                          {listProspect &&
                            listProspect.map((item, i) => (
                              <Collapse
                                key={i}
                                expandIconPosition={"left"}
                                className="site-collapse-custom-collapse"
                                expandIcon={({ isActive, key }) => (
                                  <RightCircleFilled
                                    style={{
                                      fontSize: "18px",
                                      color: "#73767A",
                                    }}
                                    rotate={isActive ? 90 : 0}
                                  />
                                )}
                              >
                                <Panel
                                  header={
                                    <span className="font-weight-bold">
                                      {item.category_name}
                                    </span>
                                  }
                                  key={i}
                                  className="site-collapse-custom-panel"
                                  style={{
                                    background: "#F5F5F7",
                                    borderRadius: "10px",
                                  }}
                                >
                                  {listProspect && listProspect.length > 0 ? (
                                    <Row className="px-0">
                                      <CardGroup className="col-12">
                                        {item.artists.map((item, i) => (
                                          <div
                                            key={i}
                                            className="d-flex align-items-stretch col-md-3"
                                            style={{ paddingRight: "unset" }}
                                          >
                                            <ArtisCard
                                              listArtis={item}
                                              disable={enabledButton}
                                              klikHapusArtis={
                                                hapusArtisProspect
                                              }
                                              idFile={idFileProspect}
                                              listCategory={listCategory}
                                              klikUbahKategori={
                                                ubahKategoriArtisProspect
                                              }
                                            ></ArtisCard>
                                          </div>
                                        ))}
                                      </CardGroup>
                                    </Row>
                                  ) : (
                                    <Row className="px-4 justify-content-md-center">
                                      <Col sm="12 my-5">
                                        <Empty
                                          image={Empty.PRESENTED_IMAGE_SIMPLE}
                                        />
                                      </Col>
                                    </Row>
                                  )}
                                </Panel>
                              </Collapse>
                            ))}
                        </Col>
                      </Row>
                    ) : (
                      <Row className="px-4 justify-content-md-center">
                        <Col sm="12 my-5">
                          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                        </Col>
                      </Row>
                    )}
                  </Card>
                </Col>
              </Grid.Row>
            </TabbedContainer>
          </Row>
        </>
      ) : (
        <Row>
          <Col width="12" className="text-center">
            Anda Tidak Memiliki Akses Report
          </Col>
        </Row>
      )}
    </SiteWrapper>
  );
}

export default Report;
