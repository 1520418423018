import React, { useState, useEffect, useRef, useContext } from "react";
import { Modal, Button, Image } from "react-bootstrap";
import { Grid, Form, FormTextInput } from "tabler-react";
import axios from "axios";
import Loader from "react-loader-spinner";
import { ContextAlert } from "../../Context/Alert";

function TambahFoto(prop) {
  const urlMicroservice = process.env.REACT_APP_BE_DBTALENT_URL;
  const token = localStorage.getItem("token");
  const [show, setShow] = useState(false);
  const [disabledButton, setDisabledButton] = useState(true);
  const [textButton, setTextButton] = useState("Pilih Foto");
  const [imageUpload, setImageUpload] = useState("");
  const inputReference = useRef(null);
  const [showInput, setShowInput] = useState(false);
  const [loader, setLoader] = useState(false);
  const [message, setMessage] = useContext(ContextAlert);
  const [input, setInput] = useState({
    artist_id: prop.id,
    foto: "",
    nama: "",
  });

  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true), setInput({ ...input, foto: "", nama: "" });
  };

  const fileUploadAction = () => {
    inputReference.current.click();
  };

  const fileUploadInputChange = (e) => {
    const objectUrl = URL.createObjectURL(e.target.files[0]);
    setImageUpload(objectUrl);
    setTextButton("Ganti Foto");
    setDisabledButton(false);
    setShowInput(true);
    setInput({
      ...input,
      foto: e.target.files[0],
    });
  };

  function handleChange(evt) {
    let value = evt.target.value;
    setInput({
      ...input,
      [evt.target.name]: value,
    });
  }

  function submit() {
    setDisabledButton(true);
    setLoader(true);
    const data = new FormData();
    data.append("artist_id", input.artist_id);
    data.append("foto", input.foto);
    data.append("nama", input.nama);

    axios
      .post(`${urlMicroservice}talent/${prop.jenis}/foto/create`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        setMessage({
          type: "success",
          message: `Berhasil menambahkan foto`,
          show: "d-block",
        });
        prop.setChange(true);
        setLoader(false);
        handleClose();
      })
      .catch((e) => {
        var message = e.response.data.message;
        if (!message) {
          message = e.message;
        }
        setMessage({
          type: "danger",
          message: JSON.stringify(message),
          show: "d-block",
        });
        setLoader(false);
      });
  }

  useEffect(() => {
    if (show == true) {
      setImageUpload(null);
      setTextButton("Pilih Foto");
      setDisabledButton(true);
      setShowInput(false);
    }
  }, [show]);

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      submit();
    }
  };

  return (
    <>
      <Button
        variant="outline-danger"
        className="ml-auto my-auto"
        type="button"
        onClick={handleShow}
      >
        <i class="bx bx-plus"></i> Tambah Foto
      </Button>
      <Modal show={show} onHide={handleClose}>
        <Modal.Body>
          <Grid.Row className="mb-5 mt-3">
            <Grid.Col width="12">
              <h5 className="font-weight-bold">Tambah Foto</h5>
            </Grid.Col>
            <Grid.Col width="12" className="mt-5">
              <div className="custom-input-file">
                <input
                  name="photo_file"
                  ref={inputReference}
                  onChange={fileUploadInputChange}
                  type="file"
                  accept="image/png, image/jpeg, image/jpg"
                />
                <Button
                  variant="outline-danger"
                  className="px-2 mr-3"
                  onClick={fileUploadAction}
                >
                  {textButton}
                </Button>
                <span>Pilih foto dengan format .jpg / .png</span>
              </div>
              <div className="text-center">
                <Loader
                  visible={loader}
                  type="ThreeDots"
                  color="#ED1C24"
                  height="50"
                  width="100"
                />
              </div>
            </Grid.Col>
            {showInput ? (
              <Grid.Col width="12" className="mt-5 image-upload">
                <Image src={imageUpload} />
                <Form.Input
                  name="nama"
                  placeholder="Masukkan nama foto"
                  value={input.nama}
                  onChange={handleChange}
                  onKeyDown={handleKeyDown}
                />
              </Grid.Col>
            ) : null}
          </Grid.Row>
          <Grid.Row className="mb-2">
            <Grid.Col width="12" className="text-right">
              <Button
                variant="outline-danger"
                className="px-5 mr-3"
                onClick={handleClose}
              >
                Batal
              </Button>
              <Button
                variant="danger"
                className="px-5"
                onClick={() => submit()}
                disabled={disabledButton}
              >
                Tambah Foto
              </Button>
            </Grid.Col>
          </Grid.Row>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default TambahFoto;
