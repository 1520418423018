import React, { useContext, useState } from "react";
import { Modal, Button, Dropdown } from "react-bootstrap";
import { FormTextInput, Form } from "tabler-react";
import axios from "axios";
import { ContextAlert } from "../../../Context/Alert";

function HapusFinalBagan(prop) {
  const [show, setShow] = useState(false);
  const [message, setMessage] = useContext(ContextAlert);
  const urlMicroservice = process.env.REACT_APP_BE_DBTALENT_URL;
  const token = localStorage.getItem("token");

  const handleClose = () => {
    setShow(false);
  };
  const handleShow = () => setShow(true);

  const onSubmit = (data) => {
    data.preventDefault();
    axios
      .post(
        `${urlMicroservice}talent/bagan/remove-final/${prop.id}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setMessage({
          type: "success",
          message: "Berhasil Menghapus Bagan dari Final",
          show: "d-block",
        });
        setTimeout(() => {
          window.location.reload();
        }, 500);
      })
      .catch(function (e) {
        var message = e.response.data.message;
        if (!message) {
          message = e.message;
        }
        setMessage({
          type: "danger",
          message: JSON.stringify(message),
          show: "d-block",
        });
      });
  };

  return (
    <>
      <Button variant="light" onClick={handleShow}>
        <i className="bx bxs-x-circle"></i> Hapus dari Final{" "}
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Hapus dari Final</Modal.Title>
        </Modal.Header>
        <form onSubmit={onSubmit}>
          <Modal.Body className="my-2">
            Apakah Anda yakin ingin menghapus bagan ini dari Final?
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="outline-danger"
              type="button"
              onClick={handleClose}
            >
              Batal
            </Button>
            <Button variant="danger" type="submit">
              Yakin
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
}

export default HapusFinalBagan;
