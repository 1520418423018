import React, { useState, useEffect } from "react";
import { Modal, Button, Image, ProgressBar } from "react-bootstrap";
import { Grid, Form } from "tabler-react";
import BuatComposite from "./BuatComposite";
import FotoComposite from "./FotoCompositeComponent";

function Composite(prop) {
  const [show, setShow] = useState(false);
  const [disabledButton, setDisabledButton] = useState(true);
  const [progress, setProgress] = useState(0);
  const [checkedItemIds, setCheckedItemIds] = useState([]);
  const [dataComposite, setDataComposite] = useState([]);

  const setNumberIncrement = (e, index, item) => {
    if (e.target.checked === true) {
      setProgress(progress + 1);
      let newArr = [...checkedItemIds];
      newArr[e.target.id] = progress + 1;
      setCheckedItemIds(newArr);

      let newComposite = [...dataComposite];
      newComposite[e.target.id] = { item: item, index: progress + 1 };
      setDataComposite(newComposite);
    } else {
      let newArr = [...checkedItemIds];
      for (var i = 0; i < prop.data.length; i++) {
        if (newArr[i] > newArr[e.target.id]) {
          newArr[i] = newArr[i] - 1;
        }
      }
      newArr[e.target.id] = null;
      setCheckedItemIds(newArr);
      setProgress(progress - 1);

      let newComposite = [...dataComposite];
      for (var i = 0; i < prop.data.length; i++) {
        if (newComposite[i]) {
          if (newComposite[i].index > newComposite[e.target.id].index) {
            newComposite[i].index = newComposite[i].index - 1;
          }
        }
      }
      newComposite[e.target.id].index = null;
      setDataComposite(newComposite);
    }
  };

  useEffect(() => {
    if (progress === 5) {
      setDisabledButton(false);
    } else {
      setDisabledButton(true);
    }
  }, [progress]);

  useEffect(() => {
    setCheckedItemIds([]);
    setProgress(0);
    setDisabledButton(true);
  }, [show]);

  const handleClose = () => {
    setShow(false);
    setDataComposite([]);
  };
  const handleShow = () => setShow(true);

  return (
    <>
      <Button variant="light" type="button" onClick={handleShow}>
        <i class="bx bx-images"></i> Buat Composite
      </Button>
      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Body>
          <Grid.Row className="mb-5 mt-3">
            <Grid.Col width="12">
              <h5 className="font-weight-bold">Buat Composite</h5>
            </Grid.Col>
            <Grid.Col width="6" className="mt-3">
              <div>Pilih Foto</div>
              <div className="text-grey font-12">
                Foto yang pertama dipilih akan ditetapkan sebagai foto primary.
              </div>
            </Grid.Col>
            <Grid.Col width="6" className="mt-3 text-right">
              <div className="d-flex align-items-center">
                <ProgressBar
                  now={progress}
                  min={0}
                  max={5}
                  variant="danger"
                  className="w-50 ml-auto mr-3"
                />
                <div>{progress} dari 5</div>
              </div>
            </Grid.Col>
            <Grid.Col width="12 mt-5">
              <div class="row row gutters-sm">
                {prop.data &&
                  prop.data.map((item, index) => {
                    return (
                      <FotoComposite
                        data={item}
                        onClick={(e) => setNumberIncrement(e, index, item)}
                        id={index}
                        imageIncrement={checkedItemIds[index]}
                        progress={progress}
                      ></FotoComposite>
                    );
                  })}
              </div>
            </Grid.Col>
          </Grid.Row>
          <Grid.Row className="mb-2">
            <Grid.Col width="12" className="d-flex justify-content-end">
              <Button
                variant="outline-danger"
                className="px-5 mr-3"
                onClick={handleClose}
              >
                Batal
              </Button>
              <BuatComposite
                disabled={disabledButton}
                handleClose={handleClose}
                handleShow={handleShow}
                item={prop.item}
                dataComposite={dataComposite}
              ></BuatComposite>
            </Grid.Col>
          </Grid.Row>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default Composite;
