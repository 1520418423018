import React, { useState, useEffect, useContext } from "react";
import { Modal, Button, Dropdown } from "react-bootstrap";
import { FormTextInput, Form, Grid } from "tabler-react";
import axios from "axios";
import Loader from "react-loader-spinner";
import { ContextAlert } from "../../Context/Alert";

function TambahCasting(prop) {
  const urlMicroservice = process.env.REACT_APP_BE_DBTALENT_URL;
  const token = localStorage.getItem("token");
  const [loader, setLoader] = useState(true);
  const [show, setShow] = useState([false, false, false]);
  const handleClose = () => setShow([false, false, false]);
  const handleShow = () => setShow([true, false, false]);
  const handleShowSecond = (title_id, title) => {
    setJudul(title);
    setInput({
      title_id: title_id,
      nama_peran: "",
      status_peran: "1",
      deskripsi: "",
    });
    setInputCasting({ ...inputCasting, title_id: title_id });
    setShow([false, true, false]);
    setDisabled(true);
  };
  const handleShowThird = () => setShow([false, false, true]);
  const [message, setMessage] = useContext(ContextAlert);
  const [disabled, setDisabled] = useState(true);
  const [dataValue, setDataValue] = useState([]);
  const [dataValueCasting, setDataValueCasting] = useState([]);
  const [keyword, setKeyword] = useState("");
  const [totalData, setTotalData] = useState(0);
  const [totalDataCasting, setTotalDataCasting] = useState(0);
  const [lainnya, setLainnya] = useState(1);
  const [lainnyaCasting, setLainnyaCasting] = useState(1);
  const [judul, setJudul] = useState("");
  const [inputCasting, setInputCasting] = useState({
    casting_id: "",
    artist_id: prop.data.id,
    title_id: "",
  });

  const [input, setInput] = useState({
    title_id: "",
    nama_peran: "",
    status_peran: "1",
    deskripsi: "",
  });

  function handleChange(evt) {
    let value = evt.target.value;
    if (evt.target.name === "nama_peran") {
      if (value) {
        setDisabled(false);
      } else {
        setDisabled(true);
      }
    }
    setInput({
      ...input,
      [evt.target.name]: value,
    });
  }

  function sortByTitle(data) {
    return data.slice().sort((a, b) => {
      const titleA = a.title.toLowerCase();
      const titleB = b.title.toLowerCase();
      if (titleA < titleB) return -1;
      if (titleA > titleB) return 1;
      return 0;
    });
  }

  useEffect(() => {
    if (show[0] === false && lainnya != 1) {
      setLainnya(1);
    }
    if (show[0] === true) {
      axios
        .get(`${urlMicroservice}talent/casting/judul`, {
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          // if (lainnya === 1) {
          //   setDataValue(res.data.data.data);
          // } else {
          //   Object.values(res.data.data.data) &&
          //   Object.values(res.data.data.data).map((item) => {
          //     setDataValue((dataValue) => [...dataValue, item]);
          //   });
          // }
          // console.log(res);
          // setDataValue(res.data.data);
          setDataValue(sortByTitle(res.data.data));
          setTotalData(res.data.data.total);
          setLoader(true);
        })
        .catch((e) => {
          var message = e.response.data.message;
          if (!message) {
            message = e.message;
          }
          setMessage({
            type: "danger",
            message: JSON.stringify(message),
            show: "d-block",
          });
        });
    }
  }, [show[0], lainnya]);

  useEffect(() => {
    if (show[1] === false && lainnyaCasting != 1) {
      setLainnyaCasting(1);
    }
    if (show[1] === true) {
      axios
        .get(
          `${urlMicroservice}talent/casting/judul/casting?title_id=${inputCasting.title_id}&artist_id=${prop.data.id}&per_page=""&page=""`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          setDataValueCasting(res.data.data);
          setLoader(false);

          // if (lainnyaCasting === 1) {
          // setDataValueCasting(res.data.data.data);
          // } else {
          //   Object.values(res.data.data.data) &&
          //     Object.values(res.data.data.data).map((item) => {
          //       setDataValueCasting((dataValue) => [...dataValue, item]);
          //     });
          // }
          // setTotalDataCasting(res.data.data.total);
        })
        .catch((e) => {
          setDataValueCasting([]);
          var message = e.response.data.message;
          if (!message) {
            message = e.message;
          }
          setMessage({
            type: "danger",
            message: JSON.stringify(message),
            show: "d-block",
          });
        });
    }
  }, [show[1], lainnyaCasting, loader]);

  const handleScroll = (e) => {
    const bottom =
      e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    if (bottom) {
      if (dataValue.length < totalData) {
        setLainnya(lainnya + 1);
      }
    }
  };

  const handleScrollCasting = (e) => {
    const bottom =
      e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    if (bottom) {
      if (dataValueCasting.length < totalDataCasting) {
        console.log(totalDataCasting);
        setLainnyaCasting(lainnyaCasting + 1);
      }
    }
  };

  function handleChangeCheckbox(evt, index, casting_talent_id) {
    let value = evt.target.value;
    let check = evt.target.checked;
    if (check === true) {
      const data = {
        casting_id: value,
        artist_id: prop.data.id,
        title_id: inputCasting.title_id,
      };
      axios
        .post(`${urlMicroservice}talent/casting/talent/create`, data, {
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          setDataValueCasting((dataValue) => {
            let oldData = dataValueCasting[index];
            oldData.checked = 1;
            return [
              ...dataValue.slice(0, index),
              oldData,
              ...dataValue.slice(index + 1),
            ];
          });
          setLoader(true);
          setMessage({
            type: "success",
            message: `Artis telah ditambahkan ke Casting`,
            show: "d-block",
          });
        })
        .catch((e) => {
          var message = e.response.data.message;
          if (!message) {
            message = e.message;
          }
          setMessage({
            type: "danger",
            message: JSON.stringify(message),
            show: "d-block",
          });
        });
    } else {
      axios
        .delete(
          `${urlMicroservice}talent/casting/talent/delete?id=${casting_talent_id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          setDataValueCasting((dataValue) => {
            let oldData = dataValueCasting[index];
            oldData.checked = 0;
            return [
              ...dataValue.slice(0, index),
              oldData,
              ...dataValue.slice(index + 1),
            ];
          });
          setLoader(true);
          setMessage({
            type: "success",
            message: `Artis telah dihapus dari Casting`,
            show: "d-block",
          });
        })
        .catch((e) => {
          var message = e.response.data.message;
          if (!message) {
            message = e.message;
          }
          setMessage({
            type: "danger",
            message: JSON.stringify(message),
            show: "d-block",
          });
        });
    }
  }

  function submit() {
    setDisabled(true);
    axios
      .post(`${urlMicroservice}talent/casting/create`, input, {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        handleShowSecond(input.title_id, judul);
      })
      .catch((e) => {
        var message = e.response.data.message;
        if (!message) {
          message = e.message;
        }
        setMessage({
          type: "danger",
          message: JSON.stringify(message),
          show: "d-block",
        });
      });
  }

  const handleInputChange = (e) => {
    setKeyword(e.target.value);
  };

  const filterDataByTitle = (keyword) => {
    return dataValue.filter((item) =>
      item.title.toLowerCase().includes(keyword.toLowerCase())
    );
  };

  const filteredData = filterDataByTitle(keyword);

  const style2 = {
    border: "1px solid #ccc",
    borderRadius: "8px",
    padding: "8px",
    outline: "none",
    fontSize: "13px",
    width: "80%",
    marginBottom: "10px",
    transition: "red 0.3s ease",
  };

  const style = {
    padding: "4px",
    outline: "none",
    fontSize: "14px",
    width: "80%",
  };

  return (
    <>
      <Dropdown.Item className="text-dark" onClick={handleShow}>
        <i class="bx bxs-group"></i> Tambah ke Casting
      </Dropdown.Item>

      <Modal show={show[0]} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Tambah ke Casting</Modal.Title>
        </Modal.Header>
        <Modal.Body className="max-body">
          <div style={style}>
            <span>Search Judul</span>
            <input
              type="text"
              style={style2}
              placeholder="Masukkan Nama Judul"
              onFocus={(e) => (e.target.style.borderColor = "lightblue")}
              onBlur={(e) => (e.target.style.borderColor = "#ccc")}
              value={keyword}
              onChange={handleInputChange}
            />
          </div>
          {filteredData &&
            sortByTitle(filteredData).map((item) => {
              return (
                <Button
                  variant="list"
                  onClick={() => handleShowSecond(item.id, item.title)}
                >
                  {item.title}{" "}
                  <i className="bx bx-chevron-right text-danger"></i>
                </Button>
              );
            })}
        </Modal.Body>
      </Modal>

      <Modal show={show[1]} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            <Button variant="none" className="p-0" onClick={handleShow}>
              <i className="bx bx-arrow-back"></i>
            </Button>{" "}
            {judul}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="my-2 max-body" onScroll={handleScrollCasting}>
          {loader === true ? (
            <Loader
              visible={loader}
              type="ThreeDots"
              color="#ED1C24"
              height="100"
              width="100"
            />
          ) : (
            <Form.Group>
              {dataValueCasting &&
                dataValueCasting.map((item, index) => {
                  return (
                    <Form.Checkbox
                      name="casting_id"
                      label={item.nama_peran}
                      value={item.id}
                      className="mb-3"
                      checked={item.checked == "1" ? true : false}
                      onChange={(evt) =>
                        handleChangeCheckbox(evt, index, item.casting_talent_id)
                      }
                    />
                  );
                })}
            </Form.Group>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-danger" onClick={handleShowThird}>
            <i className="bx bx-plus"></i> Buat Casting Baru
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={show[2]} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Buat Casting Baru</Modal.Title>
        </Modal.Header>
        <Modal.Body className="my-2">
          <Grid.Row>
            <Grid.Col width={12}>
              <FormTextInput
                name="nama_peran"
                label="Nama Casting"
                placeholder="Masukkan Nama Casting"
                value={input.nama_peran}
                onChange={handleChange}
              />
            </Grid.Col>
            <Grid.Col width={12}>
              <Form.Group label="Jenis Peran">
                <Form.Radio
                  checked={input.status_peran == "1" ? true : false}
                  name="status_peran"
                  label="Utama"
                  value="1"
                  onChange={handleChange}
                  className={
                    "custom-radios " + (input.status_peran == 1 ? "active" : "")
                  }
                  isInline
                />
                <Form.Radio
                  checked={input.status_peran == "0" ? true : false}
                  name="status_peran"
                  label="SP"
                  value="0"
                  onChange={handleChange}
                  className={
                    "custom-radios " +
                    (input.status_peran == "0" ? "active" : "")
                  }
                  isInline
                />
                <div className="text-grey">
                  <div>
                    <span className="font-weight-bold">Utama</span> : Peran akan
                    dimasukkan ke dalam bagan.
                  </div>
                  <div>
                    <span className="font-weight-bold">SP</span> : Peran tidak
                    dimasukkan ke dalam bagan.
                  </div>
                </div>
              </Form.Group>
            </Grid.Col>
            <Grid.Col width={12}>
              <Form.Group label={<Form.Label children="Deskripsi Peran" />}>
                <Form.Textarea
                  name="deskripsi"
                  rows={3}
                  placeholder="Masukkan deskripsi peran"
                  value={input.deskripsi}
                  onChange={handleChange}
                />
              </Form.Group>
            </Grid.Col>
          </Grid.Row>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="outline-danger"
            onClick={() => handleShowSecond(input.title_id, judul)}
          >
            Batal
          </Button>
          <Button variant="danger" onClick={() => submit()} disabled={disabled}>
            Buat
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default TambahCasting;
