import React, {useState, useEffect, useContext} from "react";
import SiteWrapper from "../../../../Layouts/LayoutManajemen/SiteWrapper";
import { Card, Badge } from "tabler-react";
import { Row, Col, Button} from 'react-bootstrap';
import { Input, Dropdown, Menu, Button as BtnAntd, Empty } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { BsFillPersonFill } from "react-icons/bs";
import { AiFillClockCircle } from "react-icons/ai";
import BuatCasting from "./Modal/BuatCasting";
import { Link } from "react-router-dom";
import axios from 'axios';
import Loader from 'react-loader-spinner';
import { ContextPermission } from "../../../../Context/Permission";
import PaginationCard from "../../../../Components/Artis/PaginationCardComponent";

const { Search } = Input;

function Casting (props){
    type breadcrumbItem = {|
        +value: string,
        +to?: string,
        +active?: boolean,
    |};

    const breadcrumbItems: Array<breadcrumbItem> = [
      {
        value: "Casting",
        to: "/casting/"+props.match.params.id,
        active: false,
      }
    ];

    const onSearch                          = value => console.log(value);
    const [loader, setLoader]               = useState(true);
    const token                             = localStorage.getItem('token');
    const [paginate, setPaginate]           = useState({
        search: "",
        id: props.match.params.id,
        order: 'asc',
    });
    const [casting, setCasting]             = useState([]);
    const statePermission                   = useContext(ContextPermission);
    const loginUrl                          = process.env.REACT_APP_LOGIN_TOKEN_URL;
    const [statusCasting, setStatusCasting] = useState('Terlama');
    const [pagination, setPagination]     = useState({
        page:1,
        per_page:5,
        prev_page_url:'',
        next_page_url:'',
        from:'',
        to:'',
        last_page: '',
        total: '',
        tampil: '',
    });

    useEffect( () => {
        axios.get(process.env.REACT_APP_BE_DBTALENT_URL+`talent/casting/?title_id=${paginate.id}&search=${paginate.search}&order=${paginate.order}`,
        { 
          headers: {
              'Authorization' : `Bearer ${token}`, 
              'Accept'        : 'application/json', 
              'Content-Type'  : 'application/json'
          }
        })
        .then((res) =>{
            setCasting(res.data.data.data);
            setLoader(false);
            setPagination({
                ...pagination,
                prev_page_url: res.data.data.prev_page_url,
                next_page_url: res.data.data.next_page_url,
                from: res.data.data.from,
                to: res.data.data.to,
                last_page: res.data.data.last_page,
                total: res.data.data.total,
            });
        })
        .catch(error =>{
            if (error.response) {
                if(error.response.status===401){
                    window.open(loginUrl,"_parent")
                }
            }
        })
        
    }, [token, paginate]);
    
    useEffect( () => {
        axios.get(process.env.REACT_APP_BE_DBTALENT_URL+`talent/casting/?title_id=${paginate.id}&search=${paginate.search}&order=${paginate.order}&per_page=${pagination.per_page}&page=${pagination.page}`,
        { 
          headers: {
              'Authorization' : `Bearer ${token}`, 
              'Accept'        : 'application/json', 
              'Content-Type'  : 'application/json'
          }
        })
        .then((res) =>{
            setCasting(res.data.data.data);
            setLoader(false);
            setPagination({
                ...pagination,
                prev_page_url: res.data.data.prev_page_url,
                next_page_url: res.data.data.next_page_url,
                from: res.data.data.from,
                to: res.data.data.to,
                last_page: res.data.data.last_page,
                total: res.data.data.total,
            });
        })
        .catch(error =>{
            if (error.response) {
                if(error.response.status===401){
                    window.open(loginUrl,"_parent")
                }
            }
        })
        
    }, [pagination.page]);

    function handleMenuClick(e) {
        if(e.key == 1){
            setPaginate({...paginate,order:'desc'});
            setStatusCasting('Terbaru');
        }else{
            setPaginate({...paginate,order:'asc'});
            setStatusCasting('Terlama');
        }
    }

    const menu = (
        <Menu onClick={handleMenuClick}>
          <Menu.Item key="1" value="desc">
            Terbaru
          </Menu.Item>
          <Menu.Item key="2" value="asc">
            Terlama
          </Menu.Item>
          
        </Menu>
    );

    console.log(casting);
    return(
        <SiteWrapper breadcrumbItems={breadcrumbItems} title={"Casting"} linkBack={"/judul"} title_id={props.match.params.id}>
            <Loader visible={loader} className="loaders" type="ThreeDots" color="#ED1C24" height="100" width="100" />
            {statePermission.detail_judul_menu.casting.active === 1 ?
                <>
                <Card className="border-card mt-5">
                    <Card.Body>
                        <Row className="justify-content-center align-items-center">
                            <Col md="6">
                                <h className="font-weight-bold">Daftar Casting</h>
                            </Col>
                            <Col md="6" className="text-right">
                                {statePermission.permission.casting.buat_casting.active === 1 ?
                                        <BuatCasting id={props.match.params.id} token={token}/>
                                    :
                                        <></>
                                }
                            </Col>
                        </Row>
                        <Row className="justify-content-center align-items-center mt-5">
                            <Col md="6">
                                <Dropdown overlay={menu}>
                                    <BtnAntd>
                                        {statusCasting} <DownOutlined />
                                    </BtnAntd>
                                </Dropdown>
                            </Col>
                            <Col md="6" className="text-right">
                                <Search placeholder="Cari Casting" onSearch={onSearch} style={{ width: 200 }} className="mr-3" value={paginate.search} onChange={evt => setPaginate({...paginate,search:evt.target.value})}/>
                            </Col>
                        </Row>

                        {casting&&casting.length > 0 ?
                            <Row className="mt-5">
                                {casting&&casting.map((item, i) =>(
                                    <Col sm="12">
                                        <Card >
                                            <Card.Body>
                                                <Row>
                                                    <Col sm="6">
                                                        <Row>
                                                            <Col sm="12 mb-2">
                                                                <h5 className="font-weight-bold">{item.nama_peran} <Badge color={item.peran === 'Utama' ? "primary" : "warning"} className="ml-1 p-1">{item.peran}</Badge></h5>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col sm="12" className="d-flex align-items-center">
                                                                <BsFillPersonFill size="14px" className="mr-1"/> <span>{item.total_pemeran} Talent</span>
                                                                <AiFillClockCircle className="ml-5 mr-1"/> Dibuat pada {item.dibuat_pada}
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                    <Col sm="6 text-right">
                                                        <Button variant="outline-danger" as={Link} to={`/detail-casting/${props.match.params.id}/${item.id}`}>Lihat Detail</Button>
                                                    </Col>
                                                </Row>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                ))}
                            </Row>
                        
                        :

                            <Row className="px-4 justify-content-md-center">
                                <Col sm="12 my-5"><Empty image={Empty.PRESENTED_IMAGE_SIMPLE} /></Col> 
                            </Row>

                        }

                    </Card.Body>
                </Card>
                <PaginationCard item={pagination} setPagination={setPagination}></PaginationCard>
                </>
                :
                    <Row>
                        <Col width="12" className="text-center">
                            Anda Tidak Memiliki Akses Casting
                        </Col>
                    </Row>
            }
        </SiteWrapper>
    )
}

export default Casting