import React, { useState, useEffect, useContext } from "react";
import SiteWrapper from "../../Layouts/SiteWrapper";
import { Row, Col, Card, Button } from "react-bootstrap";
import { Tabs, Collapse, Empty } from "antd";
import Loader from "react-loader-spinner";
import {
  Grid,
  Tab,
  TabbedHeader,
  TabbedContainer,
  Badge,
  FormTextInput,
} from "tabler-react";
import DataTable from "react-data-table-component";
import { BsTrashFill } from "react-icons/bs";
import { AiFillEdit } from "react-icons/ai";
import BuatFileCrew from "./Modal/BuatFileCrew";
import TambahAnggotaCrew from "./Modal/TambahAnggotaCrew";
import axios from "axios";
import EditFileCrew from "./Modal/EditFileCrew";
import EditAnggotaCrew from "./Modal/EditAnggotaCrew";
import HapusCrew from "./Modal/HapusCrew";
import { ContextPermission } from "../../Context/Permission";

const { TabPane } = Tabs;

function MasterData() {
  type breadcrumbItem = {|
    +value: string,
    +to?: string,
    +active?: boolean,
  |};

  const breadcrumbItems: Array<breadcrumbItem> = [
    {
      value: "Master Data",
      to: "/master-data",
      active: false,
    },
  ];

  const [loader, setLoader] = useState(true);
  const [selectedTitle, setSelectedTitle] = useState("Crew");
  const token = localStorage.getItem("token");
  const [listFileCrew, setListFileCrew] = useState("");
  const [listMember, setListMember] = useState([]);
  const [idFileCrew, setIdFileCrew] = useState("");
  const [total, setTotal] = useState(0);
  const statePermission = useContext(ContextPermission);
  const [paginate, setPaginate] = useState({
    page: 1,
    perPage: 10,
    selectedColumn: "",
    sortDirection: "",
    search: "",
  });

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_BE_DBTALENT_URL + `talent/crew`, {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        setListFileCrew(res.data.data.list_crew);
        setListMember(res.data.data.list_member.data);
        setIdFileCrew(res.data.data.crew_id);
        setLoader(false);
        setTotal(res.data.data.list_member.total);
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 401) {
            window.open(loginUrl, "_parent");
          }
        }
      });
  }, [token]);

  useEffect(() => {
    axios
      .get(
        process.env.REACT_APP_BE_DBTALENT_URL +
          `talent/crew/${idFileCrew}?page=${paginate.page}&limit=${paginate.perPage}&sortBy=${paginate.selectedColumn}&desc=${paginate.sortDirection}&field_search=name&search=${paginate.search}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        // console.log('paginate', res);
        setListFileCrew(res.data.data.list_crew);
        setListMember(res.data.data.list_member.data);
        setIdFileCrew(res.data.data.crew_id);
        setLoader(false);
        setTotal(res.data.data.list_member.total);
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 401) {
            window.open(loginUrl, "_parent");
          }
        }
      });
  }, [paginate]);

  const pilihCrew = (id) => {
    setIdFileCrew(id);
    axios
      .get(process.env.REACT_APP_BE_DBTALENT_URL + `talent/crew/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        setListFileCrew(res.data.data.list_crew);
        setListMember(res.data.data.list_member.data);
        setIdFileCrew(res.data.data.crew_id);
        setLoader(false);
        setTotal(res.data.data.list_member.total);
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 401) {
            window.open(loginUrl, "_parent");
          }
        }
      });
  };

  function tabClick(params) {
    setSelectedTitle(params);
  }

  const columns = [
    {
      name: "Nama",
      selector: (row) => `${row.nama}`,
      sortable: true,
    },
    {
      name: "Jabatan",
      selector: (row) => `${row.jabatan}`,
      sortable: true,
    },
    {
      name: "Aksi",
      selector: (row) => (
        <>
          <EditAnggotaCrew token={token} id={row.aksi} />{" "}
          <HapusCrew id={row.aksi} name={row.nama} token={token} />{" "}
        </>
      ),
      sortable: true,
      maxWidth: "10%",
      center: true,
      omit:
        statePermission.permission.crew.atur_anggota_crew.active === 1
          ? false
          : true,
    },
  ];

  const data = [];

  listMember &&
    listMember.map((item) => {
      const itemMember = {
        nama: item.member_name,
        jabatan: item.position,
        aksi: item.id,
      };
      data.push(itemMember);
    });

  return (
    <SiteWrapper breadcrumbItems={breadcrumbItems} title={"Master Data"}>
      <Loader
        visible={loader}
        className="loaders"
        type="ThreeDots"
        color="#ED1C24"
        height="100"
        width="100"
      />
      {statePermission.home_menu.crew.active === 1 ? (
        <>
          <Row className="">
            <Col sm="12">
              <Card className="border-card mt-5">
                <Card.Body className="px-4 py-0 d-flex">
                  <TabbedHeader
                    selectedTitle={selectedTitle}
                    stateCallback={tabClick}
                  >
                    <Tab title="Crew"></Tab>
                  </TabbedHeader>
                </Card.Body>
              </Card>
            </Col>
          </Row>

          <Row>
            <TabbedContainer selectedTitle={selectedTitle}>
              <Grid.Row cards={true} title="Crew" className="col-12">
                <Col md="3">
                  <Card className="border-card">
                    <Card.Body>
                      <Row className="mb-5 border-bottom">
                        <Col md="12" className="px-3 mb-5">
                          {statePermission.permission.crew.buat_crew_baru
                            .active === 1 ? (
                            <BuatFileCrew token={token} />
                          ) : (
                            <></>
                          )}
                        </Col>
                      </Row>
                      <Row className="mt-0">
                        <Col sm="12">
                          <div className="mt-0">
                            <div className="ml-0">
                              {listFileCrew &&
                                listFileCrew.map((list, i) => (
                                  <a
                                    className={
                                      (list.selected === 1 ? "active" : "") +
                                      " mini-nav p-2 rounded w-100"
                                    }
                                    onClick={() => pilihCrew(list.id)}
                                    type="button"
                                  >
                                    {list.crew_name}
                                  </a>
                                ))}
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                </Col>
                <Col md="9">
                  <Card className="border-card mt-0">
                    <Card.Body>
                      <Row>
                        <Col md="6">
                          <p>Daftar User</p>
                        </Col>
                        <Col md="6" className="text-right">
                          {/* <Button variant="light" className='mr-3'><AiFillEdit className="mr-2" size={16}/> Edit Crew</Button> */}
                          {statePermission.permission.crew.edit_crew.active ===
                          1 ? (
                            <EditFileCrew token={token} id={idFileCrew} />
                          ) : (
                            <></>
                          )}

                          {statePermission.permission.crew.tambah_anggota
                            .active === 1 ? (
                            <TambahAnggotaCrew token={token} id={idFileCrew} />
                          ) : (
                            <></>
                          )}
                        </Col>
                      </Row>
                      <Row className="mt-5">
                        <Col sm="12" className="mr-5">
                          <div class="row flex-row-reverse ml-1 justify-content-between">
                            <div class="mr-3 d-flex align-items-center">
                              <div class="mdb-datatable-filter flex-row text-left">
                                <FormTextInput
                                  className="form-control form-control-sm ml-0 my-1"
                                  placeholder="Search"
                                  value={paginate.search}
                                  onChange={(evt) =>
                                    setPaginate({
                                      ...paginate,
                                      search: evt.target.value,
                                    })
                                  }
                                />
                              </div>
                            </div>
                          </div>

                          <DataTable
                            className={"table-dropdown"}
                            columns={columns}
                            data={data}
                            highlightOnHover
                            responsive
                            pagination
                            paginationServer
                            paginationTotalRows={total}
                            paginationPerPage={paginate.perPage}
                            onChangeRowsPerPage={(perPage) =>
                              setPaginate({
                                ...paginate,
                                page: 1,
                                perPage: perPage,
                              })
                            }
                            onChangePage={(page) =>
                              setPaginate({ ...paginate, page: page })
                            }
                            // onSort={(selectedColumn, sortDirection) => {setPaginate({...paginate,selectedColumn:selectedColumn.selector,sortDirection:sortDirection==='desc'?true:false})}}
                          />
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                </Col>
              </Grid.Row>
            </TabbedContainer>
          </Row>
        </>
      ) : (
        <Row>
          <Col width="12" className="text-center">
            Anda Tidak Memiliki Akses Master Data
          </Col>
        </Row>
      )}
    </SiteWrapper>
  );
}

export default MasterData;
