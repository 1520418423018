import React, {useState} from "react";
import {Modal, Button, Row, Col, Card, Img} from "react-bootstrap";
import { BsBoxArrowUp } from "react-icons/bs";
import { Radio } from 'antd';
import axios from 'axios';
import AlertCustom from '../../../Components/Modal/AlertCustomComponent';

function ExpoortPdfGrade(props) {
    const [show, setShow]       = useState(false);
    const [disable, setDisable] = useState(false);
    const handleClose           = () => setShow(false);
    const handleShow            = () => setShow(true);
    const [value, setValue]     = React.useState(1);
    const loginUrl              = process.env.REACT_APP_LOGIN_TOKEN_URL;
    const [message, setMessage] = useState({type:'',message:'', show:'d-none'});

    const onChange = e => {
        setValue(e.target.value);
    };

    const exportPdfGrade = () =>{
        setDisable(true);
        axios.get(process.env.REACT_APP_BE_DBTALENT_URL+`talent/report/grade/export/${props.id}?type=${value}`,
        { 
          headers: {
              'Authorization' : `Bearer ${props.token}`, 
              'Accept'        : 'application/json', 
              'Content-Type'  : 'application/json'
          }
        })
        .then((res) =>{
            window.open(res.data.data.link, "_blank")
            setDisable(false);
        })
        .catch(error =>{
          setDisable(false);
          var message = error.message;
          if(typeof error.response != "undefined"){
            message = error.response.data.message;
          }
          setMessage({
            type:'danger',
            message:message,
            show:'d-block'
          });

          if (error.response) {
            if(error.response.status===401){
                window.open(loginUrl,"_parent")
            }
          }
        })
    }
  
    return (
      <>

        <Button variant="outline-danger" onClick={handleShow} disabled={props.disabled}><BsBoxArrowUp className="mr-2" size={16} />Export</Button>
  
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Export Laporan Grade Talent</Modal.Title>
          </Modal.Header>
          <Modal.Body>
                <span>Pilih jenis tampilan card pemain pada laporan yang akan di export</span>
                <Col sm="12 mt-3">
                    <Radio.Group onChange={onChange} value={value} style={{display: "inline"}}>
                        <Row>
                            <Col sm="6">
                                <Radio value={1}>Lengkap</Radio>
                            </Col>
                            <Col sm="6">
                                <Radio value={2}>Sederhana</Radio>
                               
                            </Col>
                        </Row>
                    </Radio.Group>
                    <Row className="mt-3">
                        <Col sm="6">
                            <Card style={{ width: '13rem' }}>
                                <Row>
                                    <Col sm="5">
                                        <Card.Img className="ml-2 mt-2" variant="top" src={process.env.REACT_APP_FRONTEND_URL+`images/img_export.png`} style={{borderRadius: "100%", width: "65px"}}/>
                                    </Col>
                                    <Col sm="6 pt-3">
                                        <span style={{fontSize: "12px", fontWeight:"bold"}}> Emery Bergson </span>
                                        <span style={{fontSize: "11px"}}> 25 Tahun </span>
                                    </Col>
                                </Row>
                                <Card.Body style={{padding:"unset"}}>
                                    <Row className="mt-3">
                                        <Col sm="5 pl-5" style={{fontSize: "11px"}}>Karakter</Col>
                                        <Col sm="7" style={{fontSize: "11px", fontWeight:"bold"}}>Protagonis & Antagosnis</Col>
                                    </Row>
                                    <Row>
                                        <Col sm="5 pl-5" style={{fontSize: "11px"}}>Status</Col>
                                        <Col sm="7" style={{fontSize: "11px", fontWeight:"bold"}}>Single</Col>
                                    </Row>
                                    <Row>
                                        <Col sm="5 pl-5" style={{fontSize: "11px"}}>Grouping</Col>
                                        <Col sm="7" style={{fontSize: "11px", fontWeight:"bold"}}>Webseries, Movie</Col>
                                    </Row>
                                    <Row className="mb-3">
                                        <Col sm="5 pl-5" style={{fontSize: "11px"}}>Remark</Col>
                                        <Col sm="7" style={{fontSize: "11px", fontWeight:"bold"}}>Series Visinema sampai akhir desember. Belum mau scripping untuk series akan di pertimbangkan</Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col sm="6">
                            <Card style={{ width: '13rem' }}>
                                <Card.Img variant="top" src={process.env.REACT_APP_FRONTEND_URL+`images/img_export.png`} style={{width: "100%", height:"170px"}}/>
                                <Card.Body className="text-center" style={{padding:"unset"}}>
                                    <span className="mt-3" style={{fontSize: "14px", fontWeight:"bold", display:"block"}}>Emery Bergson</span>
                                    <span>17-02-1996</span><br/>
                                    <span>170 cm / 63 kg</span><br/>
                                    <span>Kristen</span>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>

                </Col>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="outline-danger" onClick={handleClose}>
                Batal
            </Button>
            <Button variant="danger" onClick={exportPdfGrade} disabled={disable}>
                Export
            </Button>
        </Modal.Footer>
        </Modal>
      </>
    );
  }
  
  export default ExpoortPdfGrade