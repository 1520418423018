import React, { useContext, useEffect, useState } from "react";
import { Modal, Button, Dropdown } from "react-bootstrap";
import { FormTextInput, Form } from "tabler-react";
import axios from "axios";
import { ContextAlert } from "../../../Context/Alert";

function FinalBagan(prop) {
  const [show, setShow] = useState(false);
  const [message, setMessage] = useContext(ContextAlert);
  const urlMicroservice = process.env.REACT_APP_BE_DBTALENT_URL;
  const token = localStorage.getItem("token");

  const handleClose = () => {
    setShow(false);
  };
  const handleShow = () => setShow(true);

  function onSubmit() {
    axios
      .post(
        `${urlMicroservice}talent/bagan/finalization/${prop.id}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setMessage({
          type: "success",
          message: "Berhasil Menandai Bagan Sebagai Final",
          show: "d-block",
        });
        setTimeout(() => {
          window.location.reload();
        }, 500);
      })
      .catch(function (e) {
        var message = e.response.data.message;
        if (!message) {
          message = e.message;
        }
        setMessage({
          type: "danger",
          message: JSON.stringify(message),
          show: "d-block",
        });
      });
  }

  return (
    <>
      <Button variant="danger" onClick={handleShow}>
        <i className="bx bxs-check-circle"></i> Tandai sebagai Final{" "}
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Tandai sebagai Final</Modal.Title>
        </Modal.Header>
        <Modal.Body className="my-2">
          Apakah Anda yakin ingin menandai bagan ini sebagai Final?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-danger" type="button" onClick={handleClose}>
            Batal
          </Button>
          <Button variant="danger" type="button" onClick={() => onSubmit()}>
            Yakin
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default FinalBagan;
