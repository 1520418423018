import React, { useState, useEffect, useContext } from "react";
import { Card, Grid, Badge } from "tabler-react";
import { Link } from "react-router-dom";
import { Button, Dropdown } from "react-bootstrap";
import axios from "axios";
import Loader from "react-loader-spinner";

import SiteWrapper from "../../../../Layouts/LayoutManajemen/SiteWrapper";
import BaganCard from "../../../../Components/Bagan/BaganCardComponent";
import TambahBagan from "../../../../Components/Bagan/TambahBaganComponent";
import { ContextPermission } from "../../../../Context/Permission";
import { ContextAlert } from "../../../../Context/Alert";

function Bagan(prop) {
  type breadcrumbItem = {|
    +value: string,
    +to?: string,
    +active?: boolean,
  |};

  const breadcrumbItems: Array<breadcrumbItem> = [
    {
      value: "Bagan",
      to: `/bagan/${prop.match.params.id}`,
      active: false,
    },
  ];

  const urlMicroservice = process.env.REACT_APP_BE_DBTALENT_URL;
  const token = localStorage.getItem("token");
  const [dataValue, setDataValue] = useState([]);
  const [loader, setLoader] = useState(true);
  const [reload, setReload] = useState(false);
  const statePermission = useContext(ContextPermission);
  const [message, setMessage] = useContext(ContextAlert);

  useEffect(() => {
    axios
      .get(`${urlMicroservice}talent/bagan/${prop.match.params.id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        setDataValue(res.data.data);
        setLoader(false);
      })
      .catch((e) => {
        var message = e.response.data.message;
        if (!message) {
          message = e.message;
        }
        setMessage({
          type: "danger",
          message: JSON.stringify(message),
          show: "d-block",
        });
      });
  }, [token, reload]);

  return (
    <SiteWrapper
      breadcrumbItems={breadcrumbItems}
      title_id={prop.match.params.id}
      title={"Bagan"}
      linkBack={"/judul"}
    >
      <Loader
        visible={loader}
        className="loaders"
        type="ThreeDots"
        color="#ED1C24"
        height="100"
        width="100"
      />
      <Card className="border-card mt-5">
        <Card.Body>
          <Grid.Row className="justify-content-center align-items-center">
            <Grid.Col width="6">
              <h4 className="font-weight-bold">Daftar Bagan</h4>
            </Grid.Col>
            <Grid.Col width="6" className="text-right">
              {statePermission.permission.bagan.buat_bagan.active === 1 ? (
                <TambahBagan id={prop.match.params.id}></TambahBagan>
              ) : (
                ""
              )}
            </Grid.Col>
          </Grid.Row>
          <Grid.Row className="align-items-center mt-4">
            <Grid.Col width="12">
              <div className="text-grey font-16">Final</div>
            </Grid.Col>
            <Grid.Col width="12">
              <Grid.Row className="align-items-center mt-4">
                {dataValue &&
                  dataValue.map((item) => {
                    if (item.status_bagan === "Final") {
                      return (
                        <Grid.Col width="3">
                          <BaganCard
                            setReload={setReload}
                            key={item}
                            status={item.status_bagan}
                            color="outline-success"
                            id={item.id}
                            judul={item.bagan_name}
                            jumlah={item.total_cast}
                            deskripsi={item.description}
                          ></BaganCard>
                        </Grid.Col>
                      );
                    }
                  })}
              </Grid.Row>
            </Grid.Col>
          </Grid.Row>
          <Grid.Row className="align-items-center mt-2">
            <Grid.Col width="12">
              <div className="text-grey font-16">Highlight</div>
            </Grid.Col>
            <Grid.Col width="12">
              <Grid.Row className="align-items-center mt-4">
                {dataValue &&
                  dataValue.map((item) => {
                    if (item.status_bagan === "Highlight") {
                      return (
                        <Grid.Col width="3">
                          <BaganCard
                            setReload={setReload}
                            key={item}
                            status={item.status_bagan}
                            color="outline-primary"
                            id={item.id}
                            judul={item.bagan_name}
                            jumlah={item.total_cast}
                            deskripsi={item.description}
                          ></BaganCard>
                        </Grid.Col>
                      );
                    }
                  })}
              </Grid.Row>
            </Grid.Col>
          </Grid.Row>
          <hr className="my-2"></hr>
          <Grid.Row className="align-items-center">
            <Grid.Col width="12">
              <div className="text-grey font-16">Draft</div>
            </Grid.Col>
            <Grid.Col width="12">
              <Grid.Row className="align-items-center mt-4">
                {dataValue &&
                  dataValue.map((item) => {
                    if (item.status_bagan === "Draft") {
                      return (
                        <Grid.Col width="3">
                          <BaganCard
                            setReload={setReload}
                            key={item}
                            status={item.status_bagan}
                            color="light"
                            id={item.id}
                            judul={item.bagan_name}
                            jumlah={item.total_cast}
                            deskripsi={item.description}
                          ></BaganCard>
                        </Grid.Col>
                      );
                    }
                  })}
              </Grid.Row>
            </Grid.Col>
          </Grid.Row>
        </Card.Body>
      </Card>
    </SiteWrapper>
  );
}

export default Bagan;
