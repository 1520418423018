import React, {useState, useEffect, useContext} from "react"
import SiteWrapper from '../../Layouts/SiteWrapper';
import {Row, Col, Form, Button, InputGroup, FormControl, Card, CardGroup } from 'react-bootstrap';
import ArtisCard from "../../Components/Bookmark/ArtisCardComponent";
import ArtisCardNot from "../../Components/Bookmark/ArtisCardComponentNot";
import { AiFillEdit } from "react-icons/ai";
import { BsTrashFill } from "react-icons/bs";
import { Tabs, Empty } from 'antd';
import BuatFolder from "./Modal/BuatFolder";
import axios from 'axios';
import Loader from 'react-loader-spinner';
import EditFolder from "./Modal/EditFolder";
import HapusFolder from "./Modal/HapusFolder";
import AlertCustom from "../../Components/Modal/AlertCustomComponent";
import { ContextPermission } from "../../Context/Permission";
import PaginationCard from "../../Components/Artis/PaginationCardComponent";
import PaginationSelectedCard from "../../Components/Artis/PaginationSelectedCardComponent";

const { TabPane } = Tabs;

function Bookmark(){
    type breadcrumbItem = {|
        +value: string,
        +to?: string,
        +active?: boolean,
    |};

    const breadcrumbItems: Array<breadcrumbItem> = [
      {
        value: "Bookmark",
        to: "/bookmark",
        active: false,
      }
    ];

    const [loader, setLoader]             = useState(true);
    const token                           = localStorage.getItem('token');
    const [listBookmark, setListBookmark] = useState('');
    const [listArtis, setListArtis]       = useState('');
    const [bookmarkName, setBookmarkName] = useState('Bookmark #1');
    const [idFolder, setIdFolder]         = useState('');
    const [disable, setDisable]           = useState(false);
    const [message, setMessage]           = useState({type:'',message:'', show:'d-none'});
    const statePermission                 = useContext(ContextPermission);
    const loginUrl                        = process.env.REACT_APP_LOGIN_TOKEN_URL;
    const [pagination, setPagination]     = useState({
        page:1,
        per_page:8,
        prev_page_url:'',
        next_page_url:'',
        from:'',
        to:'',
        last_page: '',
        total: '',
        tampil: '',
    });

    useEffect( () => {
        axios.get(process.env.REACT_APP_BE_DBTALENT_URL+`talent/bookmark/?per_page=${pagination.per_page}&page=${pagination.page}`,
        { 
          headers: {
              'Authorization' : `Bearer ${token}`, 
              'Accept'        : 'application/json', 
              'Content-Type'  : 'application/json'
          }
        })
        .then((res) =>{
            setLoader(false);
            setListBookmark(res.data.data.list_bookmark);
            setListArtis(res.data.data.list_artist.data);
            setBookmarkName(res.data.data.bookmark_name);
            setIdFolder(res.data.data.bookmark_id);
            setPagination({
                ...pagination,
                prev_page_url: res.data.data.list_artist.prev_page_url,
                next_page_url: res.data.data.list_artist.next_page_url,
                from: res.data.data.list_artist.from,
                to: res.data.data.list_artist.to,
                last_page: res.data.data.list_artist.last_page,
                total: res.data.data.list_artist.total,
            });
        })
        .catch(error =>{
            // var message = error.message;
            // if (typeof error.response != "undefined") {
            //     message = error.response.data.message;
            // }
            if (error.response) {
                if(error.response.status===401){
                    window.open(loginUrl,"_parent")
                }
            }
        })
        
    }, [token]);

    const pilihBookmark = (id) => {
        setIdFolder(id);
        axios.get(process.env.REACT_APP_BE_DBTALENT_URL+`talent/bookmark/${id}?per_page=${pagination.per_page}&page=${pagination.page}`,
        { 
          headers: {
              'Authorization' : `Bearer ${token}`, 
              'Accept'        : 'application/json', 
              'Content-Type'  : 'application/json'
          }
        })
        .then((res) =>{
            setLoader(false);
            // console.log(res);
            setListBookmark(res.data.data.list_bookmark);
            setListArtis(res.data.data.list_artist.data);
            setBookmarkName(res.data.data.bookmark_name);
            setPagination({
                ...pagination,
                prev_page_url: res.data.data.list_artist.prev_page_url,
                next_page_url: res.data.data.list_artist.next_page_url,
                from: res.data.data.list_artist.from,
                to: res.data.data.list_artist.to,
                last_page: res.data.data.list_artist.last_page,
                total: res.data.data.list_artist.total,
            });
        })
        .catch(error =>{
            if (error.response) {
                if(error.response.status===401){
                    window.open(loginUrl,"_parent")
                }
            }
        })
    }
    
    useEffect( () => {
        axios.get(process.env.REACT_APP_BE_DBTALENT_URL+`talent/bookmark/${idFolder}?per_page=${pagination.per_page}&page=${pagination.page}`,
        { 
          headers: {
              'Authorization' : `Bearer ${token}`, 
              'Accept'        : 'application/json', 
              'Content-Type'  : 'application/json'
          }
        })
        .then((res) =>{
            setLoader(false);
            setListBookmark(res.data.data.list_bookmark);
            setListArtis(res.data.data.list_artist.data);
            setBookmarkName(res.data.data.bookmark_name);
            setPagination({
                ...pagination,
                prev_page_url: res.data.data.list_artist.prev_page_url,
                next_page_url: res.data.data.list_artist.next_page_url,
                from: res.data.data.list_artist.from,
                to: res.data.data.list_artist.to,
                last_page: res.data.data.list_artist.last_page,
                total: res.data.data.list_artist.total,
            });
        })
        .catch(error =>{
            if (error.response) {
                if(error.response.status===401){
                    window.open(loginUrl,"_parent")
                }
            }
        })
    }, [pagination.page]);

    const hapusArtisBookmark = (idArtis, idFolder) =>{
        setDisable(true);
        axios.delete(process.env.REACT_APP_BE_DBTALENT_URL+`talent/bookmark/delete-bookmark/`+idFolder+`/artist/`+idArtis,
        { 
            headers: {
                'Authorization' : `Bearer ${token}`, 
                'Accept'        : 'application/json', 
                'Content-Type'  : 'application/json'
            }
        })
        .then((res) =>{
            setMessage({
            type:'success',
            message:"Artis berhasil dihapus dari bookmark",
            show:'d-block'
            });
            setTimeout(() => {
            window.location.reload();
            }, 1000);
            console.log('return hapus artis', res);
        })
        .catch(error =>{
            setDisable(false);
            var message = error.message;
            if(typeof error.response != "undefined"){
                message = error.response.data.message;
            }
            setMessage({
                type:'danger',
                message:message,
                show:'d-block'
            });

            if (error.response) {
                if(error.response.status===401){
                    window.open(loginUrl,"_parent")
                }
            }
        })
    }

    
    return(
        <SiteWrapper breadcrumbItems={breadcrumbItems} title={"Bookmark"}>
            <Loader visible={loader} className="loaders" type="ThreeDots" color="#ED1C24" height="100" width="100" />
            {statePermission.home_menu.bookmark.active === 1 ?
                <>
                    <AlertCustom 
                        setMessage={setMessage}
                        showError={message.show}
                        message={message.message}
                        type={message.type}
                    ></AlertCustom>
                    <Row>
                        <Col md="3">
                            <Card className="border-card">
                                <Card.Body>
                                    <Row className="mb-5 border-bottom">
                                        <Col md="12" className="px-3 mb-5">
                                            {statePermission.permission.artis.tambah_bookmark.active === 1 ?
                                                <BuatFolder token={token}/>
                                                :
                                                <></>
                                            }
                                        </Col>
                                    </Row>
                                    <Row className="mt-0">
                                        <Col sm="12">
                                            <div className="mt-0">
                                                <div className="ml-0">
                                                    {listBookmark&&listBookmark.map((list, i) => (
                                                        <a className={(list.selected === 1 ?'active':'')+' mini-nav p-2 rounded w-100'} onClick={() => pilihBookmark(list.id)} type="button">{list.bookmark_name}</a>
                                                    ))}
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </Card.Body>
                                
                            </Card>
                        </Col>
                        <Col md="9">
                            <Card className="border-card">
                                {/* <PaginationSelectedCard item={pagination}></PaginationSelectedCard> */}
                                <Row className="p-4 justify-content-center align-items-center">
                                    <Col sm="6">
                                        <h4 className="font-weight-bold">{bookmarkName}</h4>
                                    </Col>
                                    <Col sm="6" className="text-right">
                                        <HapusFolder token={token} nameFolder={bookmarkName} id={idFolder}/>
                                        <EditFolder token={token} id={idFolder}/>
                                    </Col>
                                </Row>
                                {listArtis&&listArtis.length > 0 ?
                                    <Row className="px-4">
                                        <CardGroup className="col-12">
                                            {listArtis&&listArtis.map((artis, i) => (
                                                <div className="d-flex align-items-stretch col-md-3">
                                                    <ArtisCard dataArtis={artis} onClick={hapusArtisBookmark} idFolder={idFolder}></ArtisCard>
                                                </div>
                                            ))}
                                        </CardGroup>
                                    </Row> 
                                    :
                                    <Row className="px-4 justify-content-md-center">
                                        <Col sm="12 my-5"><Empty image={Empty.PRESENTED_IMAGE_SIMPLE} /></Col> 
                                    </Row>
                                }

                            </Card>
                            <PaginationCard item={pagination} setPagination={setPagination} onClick={() => pilihBookmark(idFolder)}></PaginationCard>
                        </Col>
                    </Row>
                </>
                :
                    <Row>
                        <Col width="12" className="text-center">
                            Anda Tidak Memiliki Akses Bookmark
                        </Col>
                    </Row>
            }
        </SiteWrapper>
    )

}

export default Bookmark;