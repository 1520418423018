import React, {useState, useEffect} from "react";
import {Modal, Button, Form, InputGroup, Image} from "react-bootstrap";
import "./TambahUser.css";
import {BsFillEyeFill, BsFillEyeSlashFill} from "react-icons/bs";
import axios from 'axios';
import AlertCustom from "../../../Components/Modal/AlertCustomComponent";

function TambahUser(props) {
  const [show, setShow]                   = useState(false);
  const handleClose                       = () => setShow(false);
  const handleShow                        = () => setShow(true);
  const [typePassword, setTypePassword]   = useState('password');
  const [urlImage, setUrlImage]           = useState(process.env.REACT_APP_FRONTEND_URL+"images/avatar.png");
  const [fileImage, setFileImage]         = useState('');
  const [nama, setNama]                   = useState('');
  const [username, setUsername]           = useState('');
  const [email, setEmail]                 = useState('');
  const [role, setRole]                   = useState('');
  const [password, setPassword]           = useState('');
  const [message, setMessage]             = useState({type:'',message:'', show:'d-none'});
  const [disable, setDisable]             = useState(false);
  const hiddenFileInput                   = React.useRef(null);
  const loginUrl                          = process.env.REACT_APP_LOGIN_TOKEN_URL;
  const [errorNama, setErrorNama]         = useState('none');
  const [errorEmail, setErrorEmail]       = useState('none');
  const [errorRole, setErrorRole]         = useState('none');
  const [errorPassword, setErrorPassword] = useState('none');
  const [errorImage, setErrorImage]       = useState('none');

  const submitTambahUser = () =>{

    const data = new FormData();
    data.append('name', nama);
    data.append('email', email);
    data.append('password', password);
    data.append('role', role);
    data.append('image', fileImage);

    if(fileImage === ''){
      setErrorImage('inline')
    }else if(nama === ''){
      setErrorNama('inline')
    }else if(email === ''){
      setErrorEmail('inline')
    }else if(role === ''){
      setErrorRole('inline')
    }else if(password === ''){
      setErrorPassword('inline')
    }else{
      setDisable(true);
      axios.post(process.env.REACT_APP_BE_DBTALENT_URL+`user/users/store`, data,
      { 
        headers: {
            'Authorization' : `Bearer ${props.token}`, 
            'Accept'        : 'application/json', 
            'Content-Type'  : 'application/json'
        }
      })
      .then((res) =>{
        setMessage({
          type:'success',
          message:"Berhasil Menambahkan Data User",
          show:'d-block'
        });
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch(error =>{
        setDisable(false);
        var message = error.message;
        if(typeof error.response != "undefined"){
          message = error.response.data.message;
        }
        setMessage({
          type:'danger',
          message:message,
          show:'d-block'
        });
  
        if (error.response) {
          if(error.response.status===401){
              window.open(loginUrl,"_parent")
          }
        }
      })

    }
  }

  const changeTypePassword = () =>{
      setTypePassword(typePassword === "password" ? "text" : "password");
  }

  const changeNama = (e) => {
    let val = e.target.value;
    setNama(val);
    if(val !== ''){
      setErrorNama('none')
    }
  }

  const changeUsername = (e) => {
    let val = e.target.value;
    setUsername(val);
  }
  const changeEmail = (e) => {
    let val = e.target.value;
    setEmail(val);
    if(val !== ''){
      setErrorEmail('none')
    }
  }
  const changeRole = (e) => {
    let val = e.target.value;
    setRole(val);
    if(val !== ''){
      setErrorRole('none')
    }
  }
  const changePassword = (e) => {
    let val = e.target.value;
    setPassword(val);
    if(val !== ''){
      setErrorPassword('none')
    }
  }
  const klikAmbilFoto = (e) =>{
    hiddenFileInput.current.click();
  }
  const ambilFoto = (e) =>{
    setUrlImage(URL.createObjectURL(e.target.files[0]));
    if(e.target.files[0]){
      setFileImage(e.target.files[0]);
      setErrorImage('none')
    }
  }

  return (
    <>
      <Button variant="danger" onClick={handleShow}>+ Buat User Baru</Button>

      <Modal show={show} onHide={handleClose}>
        <AlertCustom 
          setMessage={setMessage}
          showError={message.show}
          message={message.message}
          type={message.type}
        ></AlertCustom>
        <Modal.Header closeButton className="mx-4">
          <Modal.Title>Buat User Baru</Modal.Title>
        </Modal.Header>
        <Modal.Body className="mx-4">
            <Form>
              <Form.Group className="mb-4" controlId="nama">
                  <Form.Label>Foto Profil</Form.Label>
                  <Image src={urlImage} roundedCircle style={{height: "85px", width: "85px"}}/>
                  <Form.Control 
                      type="file" 
                      style={{display: "none"}}
                      ref={hiddenFileInput}
                      onChange={ambilFoto} 
                  />
                  <Button variant="outline-danger" className="ml-3" onClick={() => klikAmbilFoto(0)}>Pilih Foto</Button>
                  <br/><span className="text-danger" style={{display : errorImage}}>* foto tidak boleh kosong</span>
              </Form.Group>
              <Form.Group className="mb-4" controlId="nama">
                  <Form.Label>Nama</Form.Label>
                  <Form.Control type="text" placeholder="Masukan Nama Lengkap" onChange={changeNama}/>
                  <span className="text-danger" style={{display : errorNama}}>* nama tidak boleh kosong</span>
              </Form.Group>
              {/* <Form.Group className="mb-4" controlId="username">
                  <Form.Label>Username</Form.Label>
                  <Form.Control type="text" placeholder="Masukan Username" onChange={changeUsername}/>
              </Form.Group> */}
              <Form.Group className="mb-4" controlId="email">
                  <Form.Label>Email</Form.Label>
                  <Form.Control type="email" placeholder="Masukan Email Lengkap" onChange={changeEmail}/>
                  <span className="text-danger" style={{display : errorEmail}}>* email tidak boleh kosong</span>
              </Form.Group>
              <Form.Group className="mb-4" controlId="role">
                  <Form.Label>Role</Form.Label>
                  <Form.Control as="select" onChange={changeRole}>
                      <option value='' >-Pilih Role-</option>
                      {props.dataRole&&props.dataRole.map((item, i) =>(
                        <option key={item.id} value={item.name}>{item.name}</option>
                      ))}
                  </Form.Control>
                  <span className="text-danger" style={{display : errorRole}}>* role tidak boleh kosong</span>
              </Form.Group>
              <Form.Group className="mb-4" controlId="password">
                  <Form.Label>Password</Form.Label>
              </Form.Group>
              <InputGroup hasValidation>
                  <Form.Control type={typePassword}  placeholder="Password" onChange={changePassword}/>
                  <InputGroup.Text onClick={changeTypePassword}>{typePassword === "password" ?   <BsFillEyeSlashFill/> : <BsFillEyeFill/>}</InputGroup.Text>
              </InputGroup>
                  <span className="text-danger" style={{display : errorPassword}}>* password tidak boleh kosong</span>
            </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-danger" onClick={handleClose}>
            Batal
          </Button>
          <Button variant="danger" disabled={disable} onClick={submitTambahUser}>
            Buat
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
  
export default TambahUser;