import React, { useState, useEffect } from "react";
import Breadcrumbs from "./Breadcrumbs";
import { Layout } from "../LayoutManajemenTalent/ui.layout";
import { useLocation } from "react-router-dom";

// import UbahKataSandi from '../Components/Header/UbahKataSandiComponent.react';
// import Notifikasi from '../Components/Card/NotifikasiComponent.react';

function LayoutApp(props) {
  const location = useLocation();

  const [collapse, setCollapse] = useState(true);
  const [akses, setAkses] = useState("");
  const loginUrl = process.env.REACT_APP_LOGIN_TOKEN_URL;
  const logoutPM = process.env.REACT_APP_FRONTEND_URL;

  // const tampilPassword = () => {
  //   if(password===false) return '';
  //   return (<UbahKataSandi show={password} setShow={setPassword}></UbahKataSandi>);
  // }

  // useEffect(() => {
  //   if(localStorage.getItem('role')==='Super Admin'){
  //     setAkses('admin');
  //   }else if(localStorage.getItem('role')==='Admin Produksi'){
  //     setAkses('admin-produksi');
  //   }else if(localStorage.getItem('role')==='Produser Pelaksana'){
  //     setAkses('produser-pelaksana');
  //   }
  // }, [])

  const account = {
    name: localStorage.getItem("username"),
    access_level: localStorage.getItem("role"),
    image: localStorage.getItem("image"),
    token: localStorage.getItem("token"),
    url: process.env.REACT_APP_FRONTEND_URL,
  };

  const navBarItems = props.navBarItems;
  const breadcrumbItems = props.breadcrumbItems;

  return (
    <Layout
      itemsObjects={navBarItems}
      activePath={location.pathname}
      subHeader="true"
      subHeaderLink={props.linkBack}
      subHeaderText={props.title}
      urlLogo={account.url + "artis"}
      userName={account.name}
      accessLevel={account.access_level}
      fotoAvatar={account.image}
      logoutLink={logoutPM + "logout-db"}
      backendUrl={process.env.REACT_APP_GATEWAY_URL}
      accessToken={account.token}
      dbTalentUrl={process.env.REACT_APP_FRONTEND_URL}
    >
      <Breadcrumbs
        itemsObjects={breadcrumbItems}
        title={props.title}
        rightBreadcrumb={props.rightBreadcrumb}
      ></Breadcrumbs>
      {props.children}
    </Layout>
  );
}

export default LayoutApp;
