import React, { useEffect, useState, useContext } from "react";
import SiteWrapper from "../../Layouts/SiteWrapper";
import { Card } from "tabler-react";
import { Row, Col, Button } from "react-bootstrap";
import { Input } from "antd";
import { Tabs, Collapse, Empty } from "antd";
import "./Judul.css";
import CardJudul from "../../Components/Judul/JudulCardComponent";
import TambahJudul from "./Modal/TambahJudul";
import { Link } from "react-router-dom";
import { RightCircleFilled } from "@ant-design/icons";
import axios from "axios";
import Loader from "react-loader-spinner";
import { ContextPermission } from "../../Context/Permission";

const { TabPane } = Tabs;
const { Search } = Input;
const { Panel } = Collapse;

function Judul() {
  type breadcrumbItem = {|
    +value: string,
    +to?: string,
    +active?: boolean,
  |};

  const breadcrumbItems: Array<breadcrumbItem> = [
    {
      value: "Judul",
      to: "/judul",
      active: false,
    },
  ];

  const [loader, setLoader] = useState(true);
  const token = localStorage.getItem("token");
  const [judul, setJudul] = useState("");
  const [paginate, setPaginate] = useState({
    search: "",
  });
  const [title, setTitle] = useState("");
  const [dataProduser, setDataProduser] = useState("");
  const [dataCastingTeam, setDataCastingTeam] = useState("");
  const [dataSutradara, setDataSutradara] = useState("");
  const [dataSkenario, setDataSkenario] = useState("");
  const [link, setLink] = useState({ url: "", path: "" });
  const statePermission = useContext(ContextPermission);
  const loginUrl = process.env.REACT_APP_LOGIN_TOKEN_URL;

  useEffect(() => {
    axios
      .get(
        process.env.REACT_APP_BE_DBTALENT_URL +
          `talent/title?search=${paginate.search}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        setJudul(res.data.data);
        setLoader(false);
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 401) {
            window.open(loginUrl, "_parent");
          }
        }
      });
  }, [token, paginate]);

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_BE_DBTALENT_URL + `talent/title/create`, {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        setTitle(res.data.data);
        setLoader(false);
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 401) {
            window.open(loginUrl, "_parent");
          }
        }
      });

    axios
      .get(
        process.env.REACT_APP_BE_DBTALENT_URL +
          `user/users/by_role?role=Produser`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        setDataProduser(res.data.data);
        setLoader(false);
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 401) {
            window.open(loginUrl, "_parent");
          }
        }
      });

    axios
      .get(
        process.env.REACT_APP_BE_DBTALENT_URL +
          `user/users/by_role?role=CASTING%20DIRECTOR`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        setDataCastingTeam(res.data.data);
        setLoader(false);
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 401) {
            window.open(loginUrl, "_parent");
          }
        }
      });

    axios
      .get(
        process.env.REACT_APP_BE_DBTALENT_URL +
          `user/users/by_role?role=Sutradara`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        setDataSutradara(res.data.data);
        setLoader(false);
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 401) {
            window.open(loginUrl, "_parent");
          }
        }
      });

    axios
      .get(
        process.env.REACT_APP_BE_DBTALENT_URL +
          `user/users/by_role?role=Penulis`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        setDataSkenario(res.data.data);
        setLoader(false);
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 401) {
            window.open(loginUrl, "_parent");
          }
        }
      });
  }, [token]);

  useEffect(() => {
    let link = "";
    let url = "pm";
    if (statePermission.first_access.detail_judul === "Overview") {
      link = "bagan";
    } else if (statePermission.first_access.detail_judul === "Time Table") {
      link = "timetable";
    } else if (statePermission.first_access.detail_judul === "Budgeting") {
      link = "budgeting";
    } else if (statePermission.first_access.detail_judul === "Casting") {
      link = "casting";
      url = "db-talent";
    } else if (statePermission.first_access.detail_judul === "Bagan") {
      link = "bagan";
      url = "db-talent";
    } else if (
      statePermission.first_access.detail_judul === "Plan Shooting Harian"
    ) {
      link = "daily-shooting-plan";
    } else if (
      statePermission.first_access.detail_judul === "Project Management"
    ) {
      link = "project-management";
    } else if (statePermission.first_access.detail_judul === "Laporan") {
      link = "report";
    }
    setLink({ url: url, path: link });
  }, []);

  const onSearch = (value) => console.log(value);

  function callback(key) {
    // console.log(key);
  }

  return (
    <SiteWrapper breadcrumbItems={breadcrumbItems} title={"Judul"}>
      <Loader
        visible={loader}
        className="loaders"
        type="ThreeDots"
        color="#ED1C24"
        height="100"
        width="100"
      />
      {statePermission.home_menu.judul.active === 1 ? (
        <>
          <Card className="border-card mt-5">
            <Card.Body>
              <Row className="justify-content-center align-items-center">
                <Col md="6">
                  <h4 className="font-weight-bold">Daftar Judul</h4>
                </Col>
                <Col md="6" className="text-right">
                  <Search
                    placeholder="Cari Judul"
                    onSearch={onSearch}
                    style={{ width: 200 }}
                    className="mr-3"
                    value={paginate.search}
                    onChange={(evt) =>
                      setPaginate({ ...paginate, search: evt.target.value })
                    }
                  />
                  {statePermission.permission.judul.buat_manajemen_grup
                    .active === 1 ? (
                    <Button
                      variant="light"
                      className="mr-3"
                      as={Link}
                      to={"/manajemen-group"}
                    >
                      Manajemen Group
                    </Button>
                  ) : (
                    <></>
                  )}
                  {statePermission.permission.judul.buat_judul.active === 1 ? (
                    <TambahJudul
                      token={token}
                      title={title}
                      sutradara={dataSutradara}
                      produser={dataProduser}
                      skenario={dataSkenario}
                      castingTeam={dataCastingTeam}
                    />
                  ) : (
                    <></>
                  )}
                </Col>
              </Row>
              <Row>
                <Col md="12 mt-3">
                  <Tabs defaultActiveKey="1" onChange={callback}>
                    <TabPane tab="Semua" key="1" className="mt-3">
                      <Collapse
                        expandIconPosition={"left"}
                        className="site-collapse-custom-collapse"
                        expandIcon={({ isActive, key }) => (
                          <RightCircleFilled
                            style={{ fontSize: "18px", color: "#73767A" }}
                            rotate={isActive ? 90 : 0}
                          />
                        )}
                      >
                        {judul &&
                          judul.map((item, i) => (
                            <Panel
                              header={
                                <span className="font-weight-bold">
                                  {item.group_name}
                                </span>
                              }
                              key={i}
                              className="site-collapse-custom-panel collapse-hot"
                              style={{ borderRadius: "10px" }}
                            >
                              <Row className="px-0">
                                {item.perencanaan.length > 0
                                  ? item.perencanaan.map((val, i) => (
                                      <Col md="4">
                                        <CardJudul
                                          link={link}
                                          dataJudul={val}
                                          title={title}
                                          sutradara={dataSutradara}
                                          produser={dataProduser}
                                          skenario={dataSkenario}
                                          castingTeam={dataCastingTeam}
                                        ></CardJudul>
                                      </Col>
                                    ))
                                  : ""}
                                {item.persiapan.length > 0
                                  ? item.persiapan.map((val, i) => (
                                      <Col md="4">
                                        <CardJudul
                                          link={link}
                                          dataJudul={val}
                                          title={title}
                                          sutradara={dataSutradara}
                                          produser={dataProduser}
                                          skenario={dataSkenario}
                                          castingTeam={dataCastingTeam}
                                        ></CardJudul>
                                      </Col>
                                    ))
                                  : ""}
                                {item.sedang_berjalan.length > 0
                                  ? item.sedang_berjalan.map((val, i) => (
                                      <Col md="4">
                                        <CardJudul
                                          link={link}
                                          dataJudul={val}
                                          title={title}
                                          sutradara={dataSutradara}
                                          produser={dataProduser}
                                          skenario={dataSkenario}
                                          castingTeam={dataCastingTeam}
                                        ></CardJudul>
                                      </Col>
                                    ))
                                  : ""}
                                {item.selesai.length > 0
                                  ? item.selesai.map((val, i) => (
                                      <Col md="4">
                                        <CardJudul
                                          link={link}
                                          dataJudul={val}
                                          title={title}
                                          sutradara={dataSutradara}
                                          produser={dataProduser}
                                          skenario={dataSkenario}
                                          castingTeam={dataCastingTeam}
                                        ></CardJudul>
                                      </Col>
                                    ))
                                  : ""}
                              </Row>
                            </Panel>
                          ))}
                      </Collapse>
                    </TabPane>
                    <TabPane tab="Perencanaan" key="2" className="mt-3">
                      <Collapse
                        expandIconPosition={"left"}
                        className="site-collapse-custom-collapse"
                        expandIcon={({ isActive, key }) => (
                          <RightCircleFilled
                            style={{ fontSize: "18px", color: "#73767A" }}
                            rotate={isActive ? 90 : 0}
                          />
                        )}
                      >
                        {judul &&
                          judul.map((item, i) =>
                            item.perencanaan.length > 0 ? (
                              <Panel
                                header={
                                  <span className="font-weight-bold">
                                    {item.group_name}
                                  </span>
                                }
                                key={i}
                                className="site-collapse-custom-panel"
                                style={{ borderRadius: "10px" }}
                              >
                                <Row className="px-0">
                                  {item.perencanaan.map((val, i) => (
                                    <Col md="4">
                                      <CardJudul
                                        link={link}
                                        dataJudul={val}
                                        title={title}
                                        sutradara={dataSutradara}
                                        produser={dataProduser}
                                        skenario={dataSkenario}
                                        castingTeam={dataCastingTeam}
                                      ></CardJudul>
                                    </Col>
                                  ))}
                                </Row>
                              </Panel>
                            ) : (
                              ""
                            )
                          )}
                      </Collapse>
                    </TabPane>
                    <TabPane tab="Persiapan" key="3" className="mt-3">
                      <Collapse
                        expandIconPosition={"left"}
                        className="site-collapse-custom-collapse"
                        expandIcon={({ isActive, key }) => (
                          <RightCircleFilled
                            style={{ fontSize: "18px", color: "#73767A" }}
                            rotate={isActive ? 90 : 0}
                          />
                        )}
                      >
                        {judul &&
                          judul.map((item, i) =>
                            item.persiapan.length > 0 ? (
                              <Panel
                                header={
                                  <span className="font-weight-bold">
                                    {item.group_name}
                                  </span>
                                }
                                key={i}
                                className="site-collapse-custom-panel"
                                style={{ borderRadius: "10px" }}
                              >
                                <Row className="px-0">
                                  {item.persiapan.map((val, i) => (
                                    <Col md="4">
                                      <CardJudul
                                        link={link}
                                        dataJudul={val}
                                        title={title}
                                        sutradara={dataSutradara}
                                        produser={dataProduser}
                                        skenario={dataSkenario}
                                        castingTeam={dataCastingTeam}
                                      ></CardJudul>
                                    </Col>
                                  ))}
                                </Row>
                              </Panel>
                            ) : (
                              ""
                            )
                          )}
                      </Collapse>
                    </TabPane>
                    <TabPane tab="Sedang Berjalan" key="4" className="mt-3">
                      <Collapse
                        expandIconPosition={"left"}
                        className="site-collapse-custom-collapse"
                        expandIcon={({ isActive, key }) => (
                          <RightCircleFilled
                            style={{ fontSize: "18px", color: "#73767A" }}
                            rotate={isActive ? 90 : 0}
                          />
                        )}
                      >
                        {judul &&
                          judul.map((item, i) =>
                            item.sedang_berjalan.length > 0 ? (
                              <Panel
                                header={
                                  <span className="font-weight-bold">
                                    {item.group_name}
                                  </span>
                                }
                                key={i}
                                className="site-collapse-custom-panel collapse-hot"
                                style={{ borderRadius: "10px" }}
                              >
                                <Row className="px-0">
                                  {item.sedang_berjalan.map((val, i) => (
                                    <Col md="4">
                                      <CardJudul
                                        link={link}
                                        dataJudul={val}
                                        title={title}
                                        sutradara={dataSutradara}
                                        produser={dataProduser}
                                        skenario={dataSkenario}
                                        castingTeam={dataCastingTeam}
                                      ></CardJudul>
                                    </Col>
                                  ))}
                                </Row>
                              </Panel>
                            ) : (
                              ""
                            )
                          )}
                      </Collapse>
                    </TabPane>
                    <TabPane tab="Selesai" key="5" className="mt-3">
                      <Collapse
                        expandIconPosition={"left"}
                        className="site-collapse-custom-collapse"
                        expandIcon={({ isActive, key }) => (
                          <RightCircleFilled
                            style={{ fontSize: "18px", color: "#73767A" }}
                            rotate={isActive ? 90 : 0}
                          />
                        )}
                      >
                        {judul &&
                          judul.map((item, i) =>
                            item.selesai.length > 0 ? (
                              <Panel
                                header={
                                  <span className="font-weight-bold">
                                    {item.group_name}
                                  </span>
                                }
                                key={i}
                                className="site-collapse-custom-panel collapse-hot"
                                style={{ borderRadius: "10px" }}
                              >
                                <Row className="px-0">
                                  {item.selesai.map((val, i) => (
                                    <Col md="4">
                                      <CardJudul
                                        link={link}
                                        dataJudul={val}
                                        title={title}
                                        sutradara={dataSutradara}
                                        produser={dataProduser}
                                        skenario={dataSkenario}
                                        castingTeam={dataCastingTeam}
                                      ></CardJudul>
                                    </Col>
                                  ))}
                                </Row>
                              </Panel>
                            ) : (
                              ""
                            )
                          )}
                      </Collapse>
                    </TabPane>
                  </Tabs>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </>
      ) : (
        <Row>
          <Col width="12" className="text-center">
            Anda Tidak Memiliki Akses Judul
          </Col>
        </Row>
      )}
    </SiteWrapper>
  );
}

export default Judul;
