import React, { useContext } from "react";

import {
  Button,
  Image,
  OverlayTrigger,
  Tooltip,
  Dropdown,
} from "react-bootstrap";
import { Card, Badge, Grid } from "tabler-react";
import { Link } from "react-router-dom";
import { ContextPermission } from "../../Context/Permission";
import TambahBookmark from "../Artis/TambahBookmarkCardComponent";
import TambahCasting from "../Artis/TambahCastingCardComponent";
import TambahReport from "../Artis/TambahReportCardComponent";
import EditNPWP from "../Artis/EditNpwpCardComponent";

function KandidatArtis(prop) {
  const statePermission = useContext(ContextPermission);

  const shouldShowTooltip =
    prop.item.catatan &&
    prop.item.catatan !== "-" &&
    prop.item.catatan.length > 20;

  return (
    <Card className="card-rounded">
      <Card.Body className="p-0 ">
        <Link
          to={
            prop.item.status == 0
              ? "/kandidat-artis/detail/" + prop.item.id
              : "/artis/detail/" + prop.item.id
          }
        >
          <Image src={prop.item.foto} className="img-rounded img-list-artis" />
          {/* <div className="img-badge">
            <Image src="/images/icon/badge.png" />
            <span>{prop.item.nilai_casting}</span>
          </div> */}
        </Link>
        <div className="p-3">
          <Link
            to={
              prop.item.status == 0
                ? "/kandidat-artis/detail/" + prop.item.id
                : "/artis/detail/" + prop.item.id
            }
          >
            <OverlayTrigger
              key={"top"}
              placement={"top-start"}
              overlay={
                <Tooltip id={"tooltip-name"}>{prop.item.nama_artis}.</Tooltip>
              }
            >
              <h4 className="font-weight-bold text-truncate">
                {prop.item.nama_artis}
              </h4>
            </OverlayTrigger>
            <div>
              {prop.item.usia} &#183;{" "}
              {prop.item.gender === 1 ? "Laki-Laki" : "Perempuan"}&#183;{" "}
            </div>
            <div className="mt-3">
              {prop.item.talent_prospects &&
                prop.item.talent_prospects.map((subItem, index) => {
                  if (index < 2) {
                    return (
                      <Badge color="outline-primary" className="mr-1 p-2">
                        {subItem.nama}
                      </Badge>
                    );
                  }
                })}
              {prop.item.talent_prospects.length > 2 ? (
                <OverlayTrigger
                  key={"top1"}
                  placement={"top"}
                  overlay={
                    <Tooltip id={"tooltip-talent-prospect"}>
                      {prop.item.talent_prospects &&
                        prop.item.talent_prospects.map((subItem, index) => {
                          if (index >= 2) {
                            return (
                              <Badge
                                color="outline-primary"
                                className="m-1 p-2"
                              >
                                {subItem.nama}
                              </Badge>
                            );
                          }
                        })}
                    </Tooltip>
                  }
                >
                  <div className="d-inline-block badge-primary text-white mr-1 px-1 rounded">
                    {prop.item.talent_prospects.length - 2}+
                  </div>
                </OverlayTrigger>
              ) : (
                <Badge color="outline-primary" className="p-2 opacity">
                  test
                </Badge>
              )}
            </div>
            <div className="mt-3">
              {prop.item?.status == 1 && (
                <Badge color="secondary" className="mr-1 p-2">
                  Artis
                </Badge>
              )}

              {prop.item?.status == 0 && (
                <Badge color="light" className="mr-1 p-2 text-dark">
                  Kandidat Artis
                </Badge>
              )}

              {prop?.item?.availability === 1 ? (
                <Badge color="success" className="mr-1 p-2">
                  Available
                </Badge>
              ) : (
                <Badge color="danger" className="mr-1 p-2">
                  Not Available
                </Badge>
              )}
            </div>
            <div className="mt-4">
              {/* <div>
                  <i className="bx bxs-camera-movie text-danger"></i>{" "}
                  {prop.item.artis_sinemart === 1
                    ? "Sinemart"
                    : "Luar Sinemart"}
                </div> */}
              <div className="mt-2">
                <i className="bx bxs-camera-movie text-danger"></i>{" "}
                {prop.item.is_exclusive == 1 ? "Eksklusif" : "Non Eksklusif"}
              </div>
            </div>
            <div>
              {/* <div>
                  <i className="bx bxs-movie text-dark"></i>{" "}
                  {prop.item.talent_team_name ?? "-"}
                </div> */}
              <div className="mt-2">
                <i className="bx bxs-buildings text-warning"></i>{" "}
                {prop.item.manajemen ?? "-"}
              </div>
            </div>

            <div>
              {shouldShowTooltip ? (
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip id="tooltip-top">{prop.item.catatan}</Tooltip>
                  }
                >
                  <p className="ellipsis-text">
                    <i className="bx bxs-notepad text-dark"></i>{" "}
                    {prop.item.catatan}
                  </p>
                </OverlayTrigger>
              ) : (
                <p className="ellipsis-text">
                  <i className="bx bxs-notepad text-dark"></i>{" "}
                  {prop.item.catatan}
                </p>
              )}
            </div>
          </Link>
          {prop.button ? (
            <Dropdown className="w-100 artis mt-3">
              <Dropdown.Toggle bsPrefix=" ">
                <i className="fa fa-ellipsis-h"></i>
              </Dropdown.Toggle>
              <Dropdown.Menu className="w-100 artis">
                {statePermission.permission.artis.tambah_casting.active ===
                1 ? (
                  <TambahCasting data={prop.item}></TambahCasting>
                ) : (
                  ""
                )}
              </Dropdown.Menu>
            </Dropdown>
          ) : (
            ""
          )}
        </div>
      </Card.Body>
    </Card>
  );
}
export default KandidatArtis;
