import React, { useState, useEffect } from "react";
import {Container, Row, Col, Card, Button, Form, Navbar, Accordion } from 'react-bootstrap'
import { Switch, Tabs, Checkbox, Empty } from 'antd';
import { BsXLg } from "react-icons/bs";
import { Link } from "react-router-dom";
import axios from 'axios';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import Loader from 'react-loader-spinner';
import Fade from 'react-bootstrap/Fade'
import Collapse from 'react-bootstrap/Collapse'
import AlertCustom from "../../Components/Modal/AlertCustomComponent";
import {useHistory} from "react-router-dom";

function TambahRole (){
    let history                                   = useHistory();
    const [statusRole, setStatusRole]             = useState('Aktif');
    const token                                   = localStorage.getItem('token');
    const [izinAkses, setIzinAkses]               = useState('');
    const [loader, setLoader]                     = useState(true);
    const [disable, setDisable]                   = useState(false);
    const [message, setMessage]                   = useState({type:'',message:'', show:'d-none'});
    const [inputNamaRole, setInputNamaRole]       = useState('');
    const [inputStatus, setInputStatus]           = useState(1);
    const loginUrl                                = process.env.REACT_APP_LOGIN_TOKEN_URL;

    // s.data
    const [dataIzinArtis, setDataIzinArtis]       = useState([]);
    const [dataIzinKandidat, setDataIzinKandidat] = useState([]);
    const [dataIzinBookmark, setDataIzinBookmark] = useState([]);
    const [dataIzinJudul, setDataIzinJudul]       = useState([]);
    const [dataIzinUser, setDataIzinUser]         = useState([]);
    const [dataIzinRole, setDataIzinRole]         = useState([]);
    const [dataIzinReport, setDataIzinReport]     = useState([]);
    const [dataIzinCrew, setDataIzinCrew]     = useState([]);

    const [aksesArtis, setAksesArtis]             = useState('');
    const [aksesKandidat, setAksesKandidat]       = useState('');
    const [aksesBookmark, setAksesBookmark]       = useState('');
    const [aksesJudul, setAksesJudul]             = useState('');
    const [aksesUser, setAksesUser]               = useState('');
    const [aksesRole, setAksesRole]               = useState('');
    const [aksesReport, setAksesReport]           = useState('');
    const [aksesCrew, setAksesCrew]           = useState('');

    const [openArtis, setOpenArtis]               = useState(false);    
    const [openKandidat, setOpenKandidat]         = useState(false);    
    const [openBookmark, setOpenBookmark]         = useState(false);    
    const [openJudul, setOpenJudul]               = useState(false);    
    const [openUser, setOpenUser]                 = useState(false);    
    const [openRole, setOpenRole]                 = useState(false);    
    const [openReport, setOpenReport]             = useState(false);
    const [openCrew, setOpenCrew]             = useState(false);
    
    // e.data 

    // s.data parent
    const [dataParentJudulOverview, setDataParentJudulOverview]                        = useState([]);
    const [dataParentJudulTimeTable, setDataParentJudulTimeTable]                      = useState([]);
    const [dataParentJudulBudgeting, setDataParentJudulBudgeting]                      = useState([]);
    const [dataParentJudulCasting, setDataParentJudulCasting]                          = useState([]);
    const [dataParentJudulBagan, setDataParentJudulBagan]                              = useState([]);
    const [dataParentJudulScenario, setDataParentJudulScenario]                        = useState([]);
    const [dataParentJudulPlanShootingHarian, setDataParentJudulPlanShootingHarian]    = useState([]);
    const [dataParentJudulProjectManagement, setDataParentJudulProjectManagement]      = useState([]);
    const [dataParentJudulLaporan, setDataParentJudulLaporan]                          = useState([]);

    const [aksesParentJudulOverview, setAksesParentJudulOverview]                      = useState('');
    const [aksesParentJudulTimeTable, setAksesParentJudulTimeTable]                    = useState('');
    const [aksesParentJudulBudgeting, setAksesParentJudulBudgeting]                    = useState('');
    const [aksesParentJudulCasting, setAksesParentJudulCasting]                        = useState('');
    const [aksesParentJudulBagan, setAksesParentJudulBagan]                            = useState('');
    const [aksesParentJudulScenario, setAksesParentJudulScenario]                      = useState('');
    const [aksesParentJudulPlanShootingHarian, setAksesParentJudulPlanShootingHarian]  = useState('');
    const [aksesParentJudulProjectManagement, setAksesParentJudulProjectManagement]    = useState('');
    const [aksesParentJudulLaporan, setAksesParentJudulLaporan]                        = useState('');

    const [openParentJudulOverview, setOpenParentJudulOverview]                        = useState(false);
    const [openParentJudulTimeTable, setOpenParentJudulTimeTable]                      = useState(false);
    const [openParentJudulBudgeting, setOpenParentJudulBudgeting]                      = useState(false);
    const [openParentJudulCasting, setOpenParentJudulCasting]                          = useState(false);
    const [openParentJudulBagan, setOpenParentJudulBagan]                              = useState(false);
    const [openParentJudulScenario, setOpenParentJudulScenario]                        = useState(false);
    const [openParentJudulPlanShootingHarian, setOpenParentJudulPlanShootingHarian]    = useState(false);
    const [openParentJudulProjectManagement, setOpenParentJudulProjectManagement]      = useState(false);
    const [openParentJudulLaporan, setOpenParentJudulLaporan]                          = useState(false);
    // e.data parent 

    useEffect(() => {
        axios.get(process.env.REACT_APP_BE_DBTALENT_URL+`user/roles/create`,
        { 
        headers: {
            'Authorization' : `Bearer ${token}`, 
            'Accept'        : 'application/json', 
            'Content-Type'  : 'application/json'
        }
        })
        .then((res) =>{
            // console.log(res);
            setIzinAkses(res.data.data.permissions);

            // s.set data
            setAksesArtis(res.data.data.permissions[0]);
            setAksesKandidat(res.data.data.permissions[1]);
            setAksesBookmark(res.data.data.permissions[2]);
            setAksesJudul(res.data.data.permissions[3]);
            setAksesUser(res.data.data.permissions[4]);
            setAksesRole(res.data.data.permissions[5]);
            setAksesReport(res.data.data.permissions[6]);
            setAksesCrew(res.data.data.permissions[7]);
            // e.set data

            // s.set data parent
            setAksesParentJudulOverview(res.data.data.permissions[3].step_parent[0]);
            setAksesParentJudulTimeTable(res.data.data.permissions[3].step_parent[1]);
            setAksesParentJudulBudgeting(res.data.data.permissions[3].step_parent[2]);
            setAksesParentJudulCasting(res.data.data.permissions[3].step_parent[3]);
            setAksesParentJudulBagan(res.data.data.permissions[3].step_parent[4]);
            setAksesParentJudulScenario(res.data.data.permissions[3].step_parent[5]);
            setAksesParentJudulPlanShootingHarian(res.data.data.permissions[3].step_parent[6]);
            setAksesParentJudulProjectManagement(res.data.data.permissions[3].step_parent[7]);
            setAksesParentJudulLaporan(res.data.data.permissions[3].step_parent[8]);
            // e.set data parent

            setLoader(false)

            // console.log('tambahrole', res.data.data);
        })
        .catch(error =>{
            if (error.response) {
                if(error.response.status===401){
                    window.open(loginUrl,"_parent")
                }
            }
        })
    }, [token]);

    const submitTambahRole = () =>{
        
        let permission = [];

        for(let i of dataIzinArtis){
            permission.push(i);
        }
        for(let i of dataIzinKandidat){
            permission.push(i);
        }
        for(let i of dataIzinBookmark){
            permission.push(i);
        }
        for(let i of dataIzinJudul){
            permission.push(i);
        }
        for(let i of dataIzinUser){
            permission.push(i);
        }
        for(let i of dataIzinRole){
            permission.push(i);
        }
        for(let i of dataIzinReport){
            permission.push(i);
        }
        
        for(let i of dataParentJudulOverview){
            permission.push(i);
        }
        for(let i of dataParentJudulTimeTable){
            permission.push(i);
        }
        for(let i of dataParentJudulBudgeting){
            permission.push(i);
        }
        for(let i of dataParentJudulCasting){
            permission.push(i);
        }
        for(let i of dataParentJudulBagan){
            permission.push(i);
        }
        for(let i of dataParentJudulScenario){
            permission.push(i);
        }
        for(let i of dataParentJudulPlanShootingHarian){
            permission.push(i);
        }
        for(let i of dataParentJudulProjectManagement){
            permission.push(i);
        }
        for(let i of dataParentJudulLaporan){
            permission.push(i);
        }
        
        let data = {
            permission,
            name : inputNamaRole,
            status : inputStatus,
        }

        if(inputNamaRole === ''){
            var message = 'Input Nama Role Tidak Boleh Kosong';
            setMessage({
                type:'danger',
                message:message,
                show:'d-block'
            });
        }else{
            setDisable(true);
            axios.post(process.env.REACT_APP_BE_DBTALENT_URL+`user/roles/store`, data,
            { 
            headers: {
                'Authorization' : `Bearer ${token}`, 
                'Accept'        : 'application/json', 
                'Content-Type'  : 'application/json'
            }
            })
            .then((res) =>{
                setMessage({
                    type:'success',
                    message:"Berhasil Menambahkan Role",
                    show:'d-block'
                });
                setTimeout(() => {
                    history.push('/manajemen-role');
                }, 1000);
            })
            .catch(error =>{
                setDisable(false);
                var message = error.message;
                if(typeof error.response != "undefined"){
                    message = error.response.data.message;
                }
                setMessage({
                    type:'danger',
                    message:message,
                    show:'d-block'
                });

                if (error.response) {
                    if(error.response.status===401){
                        window.open(loginUrl,"_parent")
                    }
                }
            })
        }

    }

    const changeStatusRole = (checked) =>{
        if(checked === true){
            setStatusRole('Aktif')
            setInputStatus(1);
        }else{
            setInputStatus(0);
            setStatusRole('Non-Aktif')
        }
    }

    const changeInputNamaRole = (e) =>{
        let val = e.target.value;
        setInputNamaRole(val);
    }

    // s.artis
    const changeSwitchArtis = (valSwitch, eSwitch) =>{
        if(eSwitch === true){
            setDataIzinArtis(
                [...dataIzinArtis, valSwitch],
            );
        }else{
            var array = [...dataIzinArtis];
            var index = array.indexOf(valSwitch);
            if (index !== -1) {
                array.splice(index, 1);
                setDataIzinArtis(
                    array,
                );
            }
        }
    }

    const changeCheckArtis = (e) =>{

        if(e.target.checked === true){
            var val = e.target.value;
            setDataIzinArtis(
                [...dataIzinArtis, val],
            );
        }else{
            var array = [...dataIzinArtis];
            var index = array.indexOf(e.target.value);
            if (index !== -1) {
                array.splice(index, 1);
                setDataIzinArtis(
                    array,
                );
            }
        }

    }

    // e.artis
    
    // s.kandidat
    const changeSwitchKandidat = (valSwitch, eSwitch) =>{
        if(eSwitch === true){
            setDataIzinKandidat(
                [...dataIzinKandidat, valSwitch],
            );
        }else{
            var array = [...dataIzinKandidat];
            var index = array.indexOf(valSwitch);
            if (index !== -1) {
                array.splice(index, 1);
                setDataIzinKandidat(
                    array,
                );
            }
        }
    }

    const changeCheckKandidat = (e) =>{

        if(e.target.checked === true){
            var val = e.target.value;
            setDataIzinKandidat(
                [...dataIzinKandidat, val],
            );
        }else{
            var array = [...dataIzinKandidat];
            var index = array.indexOf(e.target.value);
            if (index !== -1) {
                array.splice(index, 1);
                setDataIzinKandidat(
                    array,
                );
            }
        }

    }
    // e.kandidat
    
    // s.bookmark
    const changeSwitchBookmark = (valSwitch, eSwitch) =>{
        if(eSwitch === true){
            setDataIzinBookmark(
                [...dataIzinBookmark, valSwitch],
            );
        }else{
            var array = [...dataIzinBookmark];
            var index = array.indexOf(valSwitch);
            if (index !== -1) {
                array.splice(index, 1);
                setDataIzinBookmark(
                    array,
                );
            }
        }
    }

    const changeCheckBookmark = (e) =>{

        if(e.target.checked === true){
            var val = e.target.value;
            setDataIzinBookmark(
                [...dataIzinBookmark, val],
            );
        }else{
            var array = [...dataIzinBookmark];
            var index = array.indexOf(e.target.value);
            if (index !== -1) {
                array.splice(index, 1);
                setDataIzinBookmark(
                    array,
                );
            }
        }

    }
    // e.bookmark
    
    // s.judul
    const changeSwitchJudul = (valSwitch, eSwitch) =>{
        if(eSwitch === true){
            setDataIzinJudul(
                [...dataIzinJudul, valSwitch],
            );
        }else{
            var array = [...dataIzinJudul];
            var index = array.indexOf(valSwitch);
            if (index !== -1) {
                array.splice(index, 1);
                setDataIzinJudul(
                    array,
                );
            }
        }
    }

    const changeCheckJudul = (e) =>{

        if(e.target.checked === true){
            var val = e.target.value;
            setDataIzinJudul(
                [...dataIzinJudul, val],
            );
        }else{
            var array = [...dataIzinJudul];
            var index = array.indexOf(e.target.value);
            if (index !== -1) {
                array.splice(index, 1);
                setDataIzinJudul(
                    array,
                );
            }
        }

    }
    // e.judul
    
    // s.user
    const changeSwitchUser = (valSwitch, eSwitch) =>{
        if(eSwitch === true){
            setDataIzinUser(
                [...dataIzinUser, valSwitch],
            );
        }else{
            var array = [...dataIzinUser];
            var index = array.indexOf(valSwitch);
            if (index !== -1) {
                array.splice(index, 1);
                setDataIzinUser(
                    array,
                );
            }
        }
    }

    const changeCheckUser = (e) =>{

        if(e.target.checked === true){
            var val = e.target.value;
            setDataIzinUser(
                [...dataIzinUser, val],
            );
        }else{
            var array = [...dataIzinUser];
            var index = array.indexOf(e.target.value);
            if (index !== -1) {
                array.splice(index, 1);
                setDataIzinUser(
                    array,
                );
            }
        }

    }
    // e.user
    
    // s.role
    const changeSwitchRole = (valSwitch, eSwitch) =>{
        if(eSwitch === true){
            setDataIzinRole(
                [...dataIzinRole, valSwitch],
            );
        }else{
            var array = [...dataIzinRole];
            var index = array.indexOf(valSwitch);
            if (index !== -1) {
                array.splice(index, 1);
                setDataIzinRole(
                    array,
                );
            }
        }
    }

    const changeCheckRole = (e) =>{

        if(e.target.checked === true){
            var val = e.target.value;
            setDataIzinRole(
                [...dataIzinRole, val],
            );
        }else{
            var array = [...dataIzinRole];
            var index = array.indexOf(e.target.value);
            if (index !== -1) {
                array.splice(index, 1);
                setDataIzinRole(
                    array,
                );
            }
        }

    }
    // e.role
    
    // s.report
    const changeSwitchReport = (valSwitch, eSwitch) =>{
        if(eSwitch === true){
            setDataIzinReport(
                [...dataIzinReport, valSwitch],
            );
        }else{
            var array = [...dataIzinReport];
            var index = array.indexOf(valSwitch);
            if (index !== -1) {
                array.splice(index, 1);
                setDataIzinReport(
                    array,
                );
            }
        }
    }

    const changeCheckReport = (e) =>{

        if(e.target.checked === true){
            var val = e.target.value;
            setDataIzinReport(
                [...dataIzinReport, val],
            );
        }else{
            var array = [...dataIzinReport];
            var index = array.indexOf(e.target.value);
            if (index !== -1) {
                array.splice(index, 1);
                setDataIzinReport(
                    array,
                );
            }
        }

    }
    // e.report
    
    // s.crew
    const changeSwitchCrew = (valSwitch, eSwitch) =>{
        if(eSwitch === true){
            setDataIzinCrew(
                [...dataIzinCrew, valSwitch],
            );
        }else{
            var array = [...dataIzinCrew];
            var index = array.indexOf(valSwitch);
            if (index !== -1) {
                array.splice(index, 1);
                setDataIzinCrew(
                    array,
                );
            }
        }
    }

    const changeCheckCrew = (e) =>{

        if(e.target.checked === true){
            var val = e.target.value;
            setDataIzinCrew(
                [...dataIzinCrew, val],
            );
        }else{
            var array = [...dataIzinCrew];
            var index = array.indexOf(e.target.value);
            if (index !== -1) {
                array.splice(index, 1);
                setDataIzinCrew(
                    array,
                );
            }
        }

    }
    // e.crew
    
    
    // s.parentJudul overview
    const changeSwitchParentJudulOverview = (valSwitch, eSwitch) =>{
        if(eSwitch === true){
            setDataParentJudulOverview(
                [...dataParentJudulOverview, valSwitch],
            );
        }else{
            var array = [...dataParentJudulOverview];
            var index = array.indexOf(valSwitch);
            if (index !== -1) {
                array.splice(index, 1);
                setDataParentJudulOverview(
                    array,
                );
            }
        }
    }

    const changeCheckParentJudulOverview = (e) =>{

        if(e.target.checked === true){
            var val = e.target.value;
            setDataParentJudulOverview(
                [...dataParentJudulOverview, val],
            );
        }else{
            var array = [...dataParentJudulOverview];
            var index = array.indexOf(e.target.value);
            if (index !== -1) {
                array.splice(index, 1);
                setDataParentJudulOverview(
                    array,
                );
            }
        }

    }
    // e.parentJudul overview
    
    // s.parentJudul time table
    const changeSwitchParentJudulTimeTable = (valSwitch, eSwitch) =>{
        if(eSwitch === true){
            setDataParentJudulTimeTable(
                [...dataParentJudulTimeTable, valSwitch],
            );
        }else{
            var array = [...dataParentJudulTimeTable];
            var index = array.indexOf(valSwitch);
            if (index !== -1) {
                array.splice(index, 1);
                setDataParentJudulTimeTable(
                    array,
                );
            }
        }
    }

    const changeCheckParentJudulTimeTable = (e) =>{

        if(e.target.checked === true){
            var val = e.target.value;
            setDataParentJudulTimeTable(
                [...dataParentJudulTimeTable, val],
            );
        }else{
            var array = [...dataParentJudulTimeTable];
            var index = array.indexOf(e.target.value);
            if (index !== -1) {
                array.splice(index, 1);
                setDataParentJudulTimeTable(
                    array,
                );
            }
        }

    }
    // e.parentJudul time table
    
    // s.parentJudul bugeting
    const changeSwitchParentJudulBudgeting = (valSwitch, eSwitch) =>{
        if(eSwitch === true){
            setDataParentJudulBudgeting(
                [...dataParentJudulBudgeting, valSwitch],
            );
        }else{
            var array = [...dataParentJudulBudgeting];
            var index = array.indexOf(valSwitch);
            if (index !== -1) {
                array.splice(index, 1);
                setDataParentJudulBudgeting(
                    array,
                );
            }
        }
    }

    const changeCheckParentJudulBudgeting = (e) =>{

        if(e.target.checked === true){
            var val = e.target.value;
            setDataParentJudulBudgeting(
                [...dataParentJudulBudgeting, val],
            );
        }else{
            var array = [...dataParentJudulBudgeting];
            var index = array.indexOf(e.target.value);
            if (index !== -1) {
                array.splice(index, 1);
                setDataParentJudulBudgeting(
                    array,
                );
            }
        }

    }
    // e.parentJudul bugeting
    
    // s.parentJudul casting
    const changeSwitchParentJudulCasting = (valSwitch, eSwitch) =>{
        if(eSwitch === true){
            setDataParentJudulCasting(
                [...dataParentJudulCasting, valSwitch],
            );
        }else{
            var array = [...dataParentJudulCasting];
            var index = array.indexOf(valSwitch);
            if (index !== -1) {
                array.splice(index, 1);
                setDataParentJudulCasting(
                    array,
                );
            }
        }
    }

    const changeCheckParentJudulCasting = (e) =>{

        if(e.target.checked === true){
            var val = e.target.value;
            setDataParentJudulCasting(
                [...dataParentJudulCasting, val],
            );
        }else{
            var array = [...dataParentJudulCasting];
            var index = array.indexOf(e.target.value);
            if (index !== -1) {
                array.splice(index, 1);
                setDataParentJudulCasting(
                    array,
                );
            }
        }

    }
    // e.parentJudul casting
    
    // s.parentJudul bagan
    const changeSwitchParentJudulBagan = (valSwitch, eSwitch) =>{
        if(eSwitch === true){
            setDataParentJudulBagan(
                [...dataParentJudulBagan, valSwitch],
            );
        }else{
            var array = [...dataParentJudulBagan];
            var index = array.indexOf(valSwitch);
            if (index !== -1) {
                array.splice(index, 1);
                setDataParentJudulBagan(
                    array,
                );
            }
        }
    }

    const changeCheckParentJudulBagan = (e) =>{

        if(e.target.checked === true){
            var val = e.target.value;
            setDataParentJudulBagan(
                [...dataParentJudulBagan, val],
            );
        }else{
            var array = [...dataParentJudulBagan];
            var index = array.indexOf(e.target.value);
            if (index !== -1) {
                array.splice(index, 1);
                setDataParentJudulBagan(
                    array,
                );
            }
        }

    }
    // e.parentJudul bagan
    
    // s.parentJudul skenario
    const changeSwitchParentJudulScenario = (valSwitch, eSwitch) =>{
        if(eSwitch === true){
            setDataParentJudulScenario(
                [...dataParentJudulScenario, valSwitch],
            );
        }else{
            var array = [...dataParentJudulScenario];
            var index = array.indexOf(valSwitch);
            if (index !== -1) {
                array.splice(index, 1);
                setDataParentJudulScenario(
                    array,
                );
            }
        }
    }

    const changeCheckParentJudulScenario = (e) =>{

        if(e.target.checked === true){
            var val = e.target.value;
            setDataParentJudulScenario(
                [...dataParentJudulScenario, val],
            );
        }else{
            var array = [...dataParentJudulScenario];
            var index = array.indexOf(e.target.value);
            if (index !== -1) {
                array.splice(index, 1);
                setDataParentJudulScenario(
                    array,
                );
            }
        }

    }
    // e.parentJudul skenario
    
    // s.parentJudul skenario
    const changeSwitchParentJudulPlanShootingHarian = (valSwitch, eSwitch) =>{
        if(eSwitch === true){
            setDataParentJudulPlanShootingHarian(
                [...dataParentJudulPlanShootingHarian, valSwitch],
            );
        }else{
            var array = [...dataParentJudulPlanShootingHarian];
            var index = array.indexOf(valSwitch);
            if (index !== -1) {
                array.splice(index, 1);
                setDataParentJudulPlanShootingHarian(
                    array,
                );
            }
        }
    }

    const changeCheckParentJudulPlanShootingHarian = (e) =>{

        if(e.target.checked === true){
            var val = e.target.value;
            setDataParentJudulPlanShootingHarian(
                [...dataParentJudulPlanShootingHarian, val],
            );
        }else{
            var array = [...dataParentJudulPlanShootingHarian];
            var index = array.indexOf(e.target.value);
            if (index !== -1) {
                array.splice(index, 1);
                setDataParentJudulPlanShootingHarian(
                    array,
                );
            }
        }

    }
    // e.parentJudul skenario
    
    // s.parentJudul pm
    const changeSwitchParentJudulProjectManagement = (valSwitch, eSwitch) =>{
        if(eSwitch === true){
            setDataParentJudulProjectManagement(
                [...dataParentJudulProjectManagement, valSwitch],
            );
        }else{
            var array = [...dataParentJudulProjectManagement];
            var index = array.indexOf(valSwitch);
            if (index !== -1) {
                array.splice(index, 1);
                setDataParentJudulProjectManagement(
                    array,
                );
            }
        }
    }

    const changeCheckParentJudulProjectManagement = (e) =>{

        if(e.target.checked === true){
            var val = e.target.value;
            setDataParentJudulProjectManagement(
                [...dataParentJudulProjectManagement, val],
            );
        }else{
            var array = [...dataParentJudulProjectManagement];
            var index = array.indexOf(e.target.value);
            if (index !== -1) {
                array.splice(index, 1);
                setDataParentJudulProjectManagement(
                    array,
                );
            }
        }

    }
    // e.parentJudul pm
    
    // s.parentJudul laporan
    const changeSwitchParentJudulLaporan = (valSwitch, eSwitch) =>{
        if(eSwitch === true){
            setDataParentJudulLaporan(
                [...dataParentJudulLaporan, valSwitch],
            );
        }else{
            var array = [...dataParentJudulLaporan];
            var index = array.indexOf(valSwitch);
            if (index !== -1) {
                array.splice(index, 1);
                setDataParentJudulLaporan(
                    array,
                );
            }
        }
    }

    const changeCheckParentJudulLaporan = (e) =>{

        if(e.target.checked === true){
            var val = e.target.value;
            setDataParentJudulLaporan(
                [...dataParentJudulLaporan, val],
            );
        }else{
            var array = [...dataParentJudulLaporan];
            var index = array.indexOf(e.target.value);
            if (index !== -1) {
                array.splice(index, 1);
                setDataParentJudulLaporan(
                    array,
                );
            }
        }

    }
    // e.parentJudul laporan
   
    return(
        <>
            <Container fluid>
                <Loader visible={loader} className="loaders" type="ThreeDots" color="#ED1C24" height="100" width="100" />
                <AlertCustom 
                    setMessage={setMessage}
                    showError={message.show}
                    message={message.message}
                    type={message.type}
                ></AlertCustom>
                <Row>
                    <Col sm="12">
                        <Navbar expand="lg" variant="light" bg="white">
                            <Container>
                                <Navbar.Brand as={Link} to={"/manajemen-role"}><BsXLg size="15px" /> <span className="ml-3">Buat Role Baru</span></Navbar.Brand>
                                <Navbar.Toggle />
                                <Navbar.Collapse className="justify-content-end">
                                <Navbar.Text>
                                    <Button variant="danger" type="submit" onClick={submitTambahRole} disabled={disable}>Buat Role</Button>
                                </Navbar.Text>
                                </Navbar.Collapse>
                            </Container>
                        </Navbar>
                    </Col>
                </Row>
                <Row className="mt-5 justify-content-md-center">
                    <Col sm="6">
                        <Card className="border-card">
                            <Card.Body>
                                <Row>
                                    <Col sm="12">
                                        <h4 className="font-weight-bold">Informasi Role</h4>
                                    </Col>
                                    <Col sm="12">
                                        <Form>
                                            <Form.Group className="mb-4" controlId="nama">
                                                <Form.Label>Nama Role</Form.Label>
                                                    <Form.Control type="text" placeholder="Masukan Nama Role" onChange={changeInputNamaRole}/>
                                            </Form.Group>
                                        </Form>
                                    </Col>
                                    <Col sm="12">
                                        <p>Status</p>
                                        <Switch defaultChecked onChange={changeStatusRole}/> <span>{statusRole}</span>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row className="mt-3 justify-content-md-center">
                    <Col sm="6">
                        <Card className="border-card">
                            <Card.Body>
                                <Row>
                                    <Col sm="12">
                                        <p>Izin Akses</p>
                                    </Col>
                                </Row>

                                <Row>
                                    {/* s.artis */}
                                    <Card style={{border: 'unset', marginBottom: 'unset'}} className="border-bottom mt-5" >
                                        <Col sm="12" className=" pb-4 ml-3">
                                            <Switch 
                                                onClick={() => setOpenArtis(!openArtis)}
                                                aria-controls="example-collapse-text"
                                                aria-expanded={openArtis}
                                                onChange={e => {changeSwitchArtis(aksesArtis&&aksesArtis.name, e)}}
                                            /> <span className="ml-3">{aksesArtis&&aksesArtis.name}</span>
                                            <Collapse in={openArtis}>
                                                <Col id="example-collapse-text" className="mt-3">
                                                    {aksesArtis&&aksesArtis.child.length > 0 ?
                                                        <Row className="ml-3">
                                                            {aksesArtis&&aksesArtis.child.map((child, i) => (
                                                                <Col sm="4 my-1" key={i}><Checkbox onChange={name => {changeCheckArtis(name)}} value={child.name}>{child.name}</Checkbox></Col>
                                                            ))}
                                                        </Row>
                                                    :
                                                        <Row className="ml-3">
                                                            <Col sm="12"><Empty image={Empty.PRESENTED_IMAGE_SIMPLE} /></Col> 
                                                        </Row>
                                                    }
                                                </Col>
                                            </Collapse>
                                        </Col>
                                    </Card>
                                    {/* e.artis */}
                                    
                                    {/* s.kandidat */}
                                    <Card style={{border: 'unset', marginBottom: 'unset'}} className="border-bottom mt-5">
                                        <Col sm="12" className=" pb-4 ml-3">
                                            <Switch 
                                                onClick={() => setOpenKandidat(!openKandidat)}
                                                aria-controls="example-collapse-text"
                                                aria-expanded={openKandidat}
                                                onChange={e => {changeSwitchKandidat(aksesKandidat&&aksesKandidat.name, e)}}
                                            /> <span className="ml-3">{aksesKandidat&&aksesKandidat.name}</span>
                                            <Collapse in={openKandidat}>
                                                <Col id="example-collapse-text" className="mt-3">
                                                    {aksesKandidat&&aksesKandidat.child.length > 0 ?
                                                        <Row className="ml-3">
                                                            {aksesKandidat&&aksesKandidat.child.map((child, i) => (
                                                                <Col sm="4 my-1" key={i}><Checkbox onChange={name => {changeCheckKandidat(name)}} value={child.name}>{child.name}</Checkbox></Col>
                                                            ))}
                                                        </Row>
                                                    :
                                                        <Row className="ml-3">
                                                            <Col sm="12"><Empty image={Empty.PRESENTED_IMAGE_SIMPLE} /></Col> 
                                                        </Row>
                                                    }
                                                </Col>
                                            </Collapse>
                                        </Col>
                                    </Card>
                                    {/* e.kandidat */}
                                    
                                    {/* s.bookmark */}
                                    <Card style={{border: 'unset', marginBottom: 'unset'}} className="border-bottom mt-5">
                                        <Col sm="12" className=" pb-4 ml-3">
                                            <Switch 
                                                onClick={() => setOpenBookmark(!openBookmark)}
                                                aria-controls="example-collapse-text"
                                                aria-expanded={openBookmark}
                                                onChange={e => {changeSwitchBookmark(aksesBookmark&&aksesBookmark.name, e)}}
                                            /> <span className="ml-3">{aksesBookmark&&aksesBookmark.name}</span>
                                            <Collapse in={openBookmark}>
                                                <Col id="example-collapse-text" className="mt-3">
                                                    {aksesBookmark&&aksesBookmark.child.length > 0 ?
                                                        <Row className="ml-3">
                                                            {aksesBookmark&&aksesBookmark.child.map((child, i) => (
                                                                <Col sm="4 my-1" key={i}><Checkbox onChange={name => {changeCheckBookmark(name)}} value={child.name}>{child.name}</Checkbox></Col>
                                                            ))}
                                                        </Row>
                                                    :
                                                        <Row className="ml-3">
                                                            <Col sm="12"><Empty image={Empty.PRESENTED_IMAGE_SIMPLE} /></Col> 
                                                        </Row>
                                                    }
                                                </Col>
                                            </Collapse>
                                        </Col>
                                    </Card>
                                    {/* e.bookmark */}
                                    
                                    {/* s.judul */}
                                    <Card style={{border: 'unset', marginBottom: 'unset'}} className="border-bottom mt-5">
                                        <Col sm="12" className=" pb-4 ml-3">
                                            <Switch 
                                                onClick={() => setOpenJudul(!openJudul)}
                                                aria-controls="example-collapse-text"
                                                aria-expanded={openJudul}
                                                onChange={e => {changeSwitchJudul(aksesJudul&&aksesJudul.name, e)}}
                                            /> <span className="ml-3">{aksesJudul&&aksesJudul.name}</span>
                                            <Collapse in={openJudul}>
                                                <Col id="example-collapse-text" className="mt-3">
                                                    {aksesJudul&&aksesJudul.child.length > 0 ?
                                                        <>
                                                        <Row className="ml-3">
                                                            {aksesJudul&&aksesJudul.child.map((child, i) => (
                                                                <Col sm="4 my-1" key={i}><Checkbox onChange={name => {changeCheckJudul(name)}} value={child.name}>{child.name}</Checkbox></Col>
                                                            ))}
                                                        </Row>

                                                        <Row>
                                                            {/* s.overview */}
                                                            <Card style={{border: 'unset', marginBottom: 'unset'}} className="border-bottom mt-5">
                                                                <Col sm="12" className="pb-4">
                                                                    <Switch 
                                                                        onClick={() => setOpenParentJudulOverview(!openParentJudulOverview)}
                                                                        aria-controls="example-collapse-text"
                                                                        aria-expanded={openParentJudulOverview}
                                                                        onChange={e => {changeSwitchParentJudulOverview(aksesParentJudulOverview&&aksesParentJudulOverview.name, e)}}
                                                                    /> <span className="ml-3">{aksesParentJudulOverview&&aksesParentJudulOverview.name}</span>
                                                                    <Collapse in={openParentJudulOverview}>
                                                                        <Col id="example-collapse-text" className="mt-3">
                                                                                {aksesParentJudulOverview&&aksesParentJudulOverview.step_child.length > 0 ?
                                                                                    <Row className="ml-3">
                                                                                        {aksesParentJudulOverview&&aksesParentJudulOverview.step_child.map((step_child, i) => (
                                                                                            <Col sm="4 my-1" key={i}><Checkbox onChange={name => {changeCheckParentJudulOverview(name)}} value={step_child.name}>{step_child.name}</Checkbox></Col>
                                                                                        ))}
                                                                                    </Row>
                                                                                :
                                                                                    <Row className="ml-3">
                                                                                        <Col sm="12"><Empty image={Empty.PRESENTED_IMAGE_SIMPLE} /></Col> 
                                                                                    </Row>
                                                                                }
                                                                        </Col>
                                                                    </Collapse>
                                                                </Col>
                                                            </Card>
                                                            {/* e.overview */}
                                                            
                                                            {/* s.time table */}
                                                            <Card style={{border: 'unset', marginBottom: 'unset'}} className="border-bottom mt-5">
                                                                <Col sm="12" className="pb-4">
                                                                    <Switch 
                                                                        onClick={() => setOpenParentJudulTimeTable(!openParentJudulTimeTable)}
                                                                        aria-controls="example-collapse-text"
                                                                        aria-expanded={openParentJudulTimeTable}
                                                                        onChange={e => {changeSwitchParentJudulTimeTable(aksesParentJudulTimeTable&&aksesParentJudulTimeTable.name, e)}}
                                                                    /> <span className="ml-3">{aksesParentJudulTimeTable&&aksesParentJudulTimeTable.name}</span>
                                                                    <Collapse in={openParentJudulTimeTable}>
                                                                        <Col id="example-collapse-text" className="mt-3">
                                                                                {aksesParentJudulTimeTable&&aksesParentJudulTimeTable.step_child.length > 0 ?
                                                                                    <Row className="ml-3">
                                                                                        {aksesParentJudulTimeTable&&aksesParentJudulTimeTable.step_child.map((step_child, i) => (
                                                                                            <Col sm="4 my-1" key={i}><Checkbox onChange={name => {changeCheckParentJudulTimeTable(name)}} value={step_child.name}>{step_child.name}</Checkbox></Col>
                                                                                        ))}
                                                                                    </Row>
                                                                                :
                                                                                    <Row className="ml-3">
                                                                                        <Col sm="12"><Empty image={Empty.PRESENTED_IMAGE_SIMPLE} /></Col> 
                                                                                    </Row>
                                                                                }
                                                                        </Col>
                                                                    </Collapse>
                                                                </Col>
                                                            </Card>
                                                            {/* e.time table */}
                                                            
                                                            {/* s.budgeting */}
                                                            <Card style={{border: 'unset', marginBottom: 'unset'}} className="border-bottom mt-5">
                                                                <Col sm="12" className="pb-4">
                                                                    <Switch 
                                                                        onClick={() => setOpenParentJudulBudgeting(!openParentJudulBudgeting)}
                                                                        aria-controls="example-collapse-text"
                                                                        aria-expanded={openParentJudulBudgeting}
                                                                        onChange={e => {changeSwitchParentJudulBudgeting(aksesParentJudulBudgeting&&aksesParentJudulBudgeting.name, e)}}
                                                                    /> <span className="ml-3">{aksesParentJudulBudgeting&&aksesParentJudulBudgeting.name}</span>
                                                                    <Collapse in={openParentJudulBudgeting}>
                                                                        <Col id="example-collapse-text" className="mt-3">
                                                                                {aksesParentJudulBudgeting&&aksesParentJudulBudgeting.step_child.length > 0 ?
                                                                                    <Row className="ml-3">
                                                                                        {aksesParentJudulBudgeting&&aksesParentJudulBudgeting.step_child.map((step_child, i) => (
                                                                                            <Col sm="4 my-1" key={i}><Checkbox onChange={name => {changeCheckParentJudulBudgeting(name)}} value={step_child.name}>{step_child.name}</Checkbox></Col>
                                                                                        ))}
                                                                                    </Row>
                                                                                :
                                                                                    <Row className="ml-3">
                                                                                        <Col sm="12"><Empty image={Empty.PRESENTED_IMAGE_SIMPLE} /></Col> 
                                                                                    </Row>
                                                                                }
                                                                        </Col>
                                                                    </Collapse>
                                                                </Col>
                                                            </Card>
                                                            {/* e.budgeting */}

                                                            {/* s.casting */}
                                                            <Card style={{border: 'unset', marginBottom: 'unset'}} className="border-bottom mt-5">
                                                                <Col sm="12" className="pb-4">
                                                                    <Switch 
                                                                        onClick={() => setOpenParentJudulCasting(!openParentJudulCasting)}
                                                                        aria-controls="example-collapse-text"
                                                                        aria-expanded={openParentJudulCasting}
                                                                        onChange={e => {changeSwitchParentJudulCasting(aksesParentJudulCasting&&aksesParentJudulCasting.name, e)}}
                                                                    /> <span className="ml-3">{aksesParentJudulCasting&&aksesParentJudulCasting.name}</span>
                                                                    <Collapse in={openParentJudulCasting}>
                                                                        <Col id="example-collapse-text" className="mt-3">
                                                                                {aksesParentJudulCasting&&aksesParentJudulCasting.step_child.length > 0 ?
                                                                                    <Row className="ml-3">
                                                                                        {aksesParentJudulCasting&&aksesParentJudulCasting.step_child.map((step_child, i) => (
                                                                                            <Col sm="4 my-1" key={i}><Checkbox onChange={name => {changeCheckParentJudulCasting(name)}} value={step_child.name}>{step_child.name}</Checkbox></Col>
                                                                                        ))}
                                                                                    </Row>
                                                                                :
                                                                                    <Row className="ml-3">
                                                                                        <Col sm="12"><Empty image={Empty.PRESENTED_IMAGE_SIMPLE} /></Col> 
                                                                                    </Row>
                                                                                }
                                                                        </Col>
                                                                    </Collapse>
                                                                </Col>
                                                            </Card>
                                                            {/* e.casting */}
                                                            
                                                            {/* s.bagan */}
                                                            <Card style={{border: 'unset', marginBottom: 'unset'}} className="border-bottom mt-5">
                                                                <Col sm="12" className="pb-4">
                                                                    <Switch 
                                                                        onClick={() => setOpenParentJudulBagan(!openParentJudulBagan)}
                                                                        aria-controls="example-collapse-text"
                                                                        aria-expanded={openParentJudulBagan}
                                                                        onChange={e => {changeSwitchParentJudulBagan(aksesParentJudulBagan&&aksesParentJudulBagan.name, e)}}
                                                                    /> <span className="ml-3">{aksesParentJudulBagan&&aksesParentJudulBagan.name}</span>
                                                                    <Collapse in={openParentJudulBagan}>
                                                                        <Col id="example-collapse-text" className="mt-3">
                                                                                {aksesParentJudulBagan&&aksesParentJudulBagan.step_child.length > 0 ?
                                                                                    <Row className="ml-3">
                                                                                        {aksesParentJudulBagan&&aksesParentJudulBagan.step_child.map((step_child, i) => (
                                                                                            <Col sm="4 my-1" key={i}><Checkbox onChange={name => {changeCheckParentJudulBagan(name)}} value={step_child.name}>{step_child.name}</Checkbox></Col>
                                                                                        ))}
                                                                                    </Row>
                                                                                :
                                                                                    <Row className="ml-3">
                                                                                        <Col sm="12"><Empty image={Empty.PRESENTED_IMAGE_SIMPLE} /></Col> 
                                                                                    </Row>
                                                                                }
                                                                        </Col>
                                                                    </Collapse>
                                                                </Col>
                                                            </Card>
                                                            {/* e.bagan */}
                                                            
                                                            {/* s.scenario */}
                                                            <Card style={{border: 'unset', marginBottom: 'unset'}} className="border-bottom mt-5">
                                                                <Col sm="12" className="pb-4">
                                                                    <Switch 
                                                                        onClick={() => setOpenParentJudulScenario(!openParentJudulScenario)}
                                                                        aria-controls="example-collapse-text"
                                                                        aria-expanded={openParentJudulScenario}
                                                                        onChange={e => {changeSwitchParentJudulScenario(aksesParentJudulScenario&&aksesParentJudulScenario.name, e)}}
                                                                    /> <span className="ml-3">{aksesParentJudulScenario&&aksesParentJudulScenario.name}</span>
                                                                    <Collapse in={openParentJudulScenario}>
                                                                        <Col id="example-collapse-text" className="mt-3">
                                                                                {aksesParentJudulScenario&&aksesParentJudulScenario.step_child.length > 0 ?
                                                                                    <Row className="ml-3">
                                                                                        {aksesParentJudulScenario&&aksesParentJudulScenario.step_child.map((step_child, i) => (
                                                                                            <Col sm="4 my-1" key={i}><Checkbox onChange={name => {changeCheckParentJudulScenario(name)}} value={step_child.name}>{step_child.name}</Checkbox></Col>
                                                                                        ))}
                                                                                    </Row>
                                                                                :
                                                                                    <Row className="ml-3">
                                                                                        <Col sm="12"><Empty image={Empty.PRESENTED_IMAGE_SIMPLE} /></Col> 
                                                                                    </Row>
                                                                                }
                                                                        </Col>
                                                                    </Collapse>
                                                                </Col>
                                                            </Card>
                                                            {/* e.scenario */}
                                                            
                                                            {/* s.shoot */}
                                                            <Card style={{border: 'unset', marginBottom: 'unset'}} className="border-bottom mt-5">
                                                                <Col sm="12" className="pb-4">
                                                                    <Switch 
                                                                        onClick={() => setOpenParentJudulPlanShootingHarian(!openParentJudulPlanShootingHarian)}
                                                                        aria-controls="example-collapse-text"
                                                                        aria-expanded={openParentJudulPlanShootingHarian}
                                                                        onChange={e => {changeSwitchParentJudulPlanShootingHarian(aksesParentJudulPlanShootingHarian&&aksesParentJudulPlanShootingHarian.name, e)}}
                                                                    /> <span className="ml-3">{aksesParentJudulPlanShootingHarian&&aksesParentJudulPlanShootingHarian.name}</span>
                                                                    <Collapse in={openParentJudulPlanShootingHarian}>
                                                                        <Col id="example-collapse-text" className="mt-3">
                                                                                {aksesParentJudulPlanShootingHarian&&aksesParentJudulPlanShootingHarian.step_child.length > 0 ?
                                                                                    <Row className="ml-3">
                                                                                        {aksesParentJudulPlanShootingHarian&&aksesParentJudulPlanShootingHarian.step_child.map((step_child, i) => (
                                                                                            <Col sm="4 my-1" key={i}><Checkbox onChange={name => {changeCheckParentJudulPlanShootingHarian(name)}} value={step_child.name}>{step_child.name}</Checkbox></Col>
                                                                                        ))}
                                                                                    </Row>
                                                                                :
                                                                                    <Row className="ml-3">
                                                                                        <Col sm="12"><Empty image={Empty.PRESENTED_IMAGE_SIMPLE} /></Col> 
                                                                                    </Row>
                                                                                }
                                                                        </Col>
                                                                    </Collapse>
                                                                </Col>
                                                            </Card>
                                                            {/* e.shoot */}
                                                            
                                                            {/* s.pm */}
                                                            <Card style={{border: 'unset', marginBottom: 'unset'}} className="border-bottom mt-5">
                                                                <Col sm="12" className="pb-4">
                                                                    <Switch 
                                                                        onClick={() => setOpenParentJudulProjectManagement(!openParentJudulProjectManagement)}
                                                                        aria-controls="example-collapse-text"
                                                                        aria-expanded={openParentJudulProjectManagement}
                                                                        onChange={e => {changeSwitchParentJudulProjectManagement(aksesParentJudulProjectManagement&&aksesParentJudulProjectManagement.name, e)}}
                                                                    /> <span className="ml-3">{aksesParentJudulProjectManagement&&aksesParentJudulProjectManagement.name}</span>
                                                                    <Collapse in={openParentJudulProjectManagement}>
                                                                        <Col id="example-collapse-text" className="mt-3">
                                                                                {aksesParentJudulProjectManagement&&aksesParentJudulProjectManagement.step_child.length > 0 ?
                                                                                    <Row className="ml-3">
                                                                                        {aksesParentJudulProjectManagement&&aksesParentJudulProjectManagement.step_child.map((step_child, i) => (
                                                                                            <Col sm="4 my-1" key={i}><Checkbox onChange={name => {changeCheckParentJudulProjectManagement(name)}} value={step_child.name}>{step_child.name}</Checkbox></Col>
                                                                                        ))}
                                                                                    </Row>
                                                                                :
                                                                                    <Row className="ml-3">
                                                                                        <Col sm="12"><Empty image={Empty.PRESENTED_IMAGE_SIMPLE} /></Col> 
                                                                                    </Row>
                                                                                }
                                                                        </Col>
                                                                    </Collapse>
                                                                </Col>
                                                            </Card>
                                                            {/* e.pm */}
                                                            
                                                            {/* s.laporan */}
                                                            <Card style={{border: 'unset', marginBottom: 'unset'}} className="mt-5">
                                                                <Col sm="12" className="pb-4">
                                                                    <Switch 
                                                                        onClick={() => setOpenParentJudulLaporan(!openParentJudulLaporan)}
                                                                        aria-controls="example-collapse-text"
                                                                        aria-expanded={openParentJudulLaporan}
                                                                        onChange={e => {changeSwitchParentJudulLaporan(aksesParentJudulLaporan&&aksesParentJudulLaporan.name, e)}}
                                                                    /> <span className="ml-3">{aksesParentJudulLaporan&&aksesParentJudulLaporan.name}</span>
                                                                    <Collapse in={openParentJudulLaporan}>
                                                                        <Col id="example-collapse-text" className="mt-3">
                                                                                {aksesParentJudulLaporan&&aksesParentJudulLaporan.step_child.length > 0 ?
                                                                                    <Row className="ml-3">
                                                                                        {aksesParentJudulLaporan&&aksesParentJudulLaporan.step_child.map((step_child, i) => (
                                                                                            <Col sm="4 my-1" key={i}><Checkbox onChange={name => {changeCheckParentJudulLaporan(name)}} value={step_child.name}>{step_child.name}</Checkbox></Col>
                                                                                        ))}
                                                                                    </Row>
                                                                                :
                                                                                    <Row className="ml-3">
                                                                                        <Col sm="12"><Empty image={Empty.PRESENTED_IMAGE_SIMPLE} /></Col> 
                                                                                    </Row>
                                                                                }
                                                                        </Col>
                                                                    </Collapse>
                                                                </Col>
                                                            </Card>
                                                            {/* e.laporan */}

                                                        </Row>
                                                        </>
                                                    :
                                                        <Row className="ml-3">
                                                            <Col sm="12"><Empty image={Empty.PRESENTED_IMAGE_SIMPLE} /></Col> 
                                                        </Row>
                                                    }
                                                </Col>
                                            </Collapse>
                                        </Col>
                                    </Card>
                                    {/* e.judul */}
                                    
                                    {/* s.user */}
                                    <Card style={{border: 'unset', marginBottom: 'unset'}} className="border-bottom mt-5">
                                        <Col sm="12" className=" pb-4 ml-3">
                                            <Switch 
                                                onClick={() => setOpenUser(!openUser)}
                                                aria-controls="example-collapse-text"
                                                aria-expanded={openUser}
                                                onChange={e => {changeSwitchUser(aksesUser&&aksesUser.name, e)}}
                                            /> <span className="ml-3">{aksesUser&&aksesUser.name}</span>
                                            <Collapse in={openUser}>
                                                <Col id="example-collapse-text" className="mt-3">
                                                    {aksesUser&&aksesUser.child.length > 0 ?
                                                        <Row className="ml-3">
                                                            {aksesUser&&aksesUser.child.map((child, i) => (
                                                                <Col sm="4 my-1" key={i}><Checkbox onChange={name => {changeCheckUser(name)}} value={child.name}>{child.name}</Checkbox></Col>
                                                            ))}
                                                        </Row>
                                                    :
                                                        <Row className="ml-3">
                                                            <Col sm="12"><Empty image={Empty.PRESENTED_IMAGE_SIMPLE} /></Col> 
                                                        </Row>
                                                    }
                                                </Col>
                                            </Collapse>
                                        </Col>
                                    </Card>
                                    {/* e.user */}
                                    
                                    {/* s.role */}
                                    <Card style={{border: 'unset', marginBottom: 'unset'}} className="border-bottom mt-5">
                                        <Col sm="12" className=" pb-4 ml-3">
                                            <Switch 
                                                onClick={() => setOpenRole(!openRole)}
                                                aria-controls="example-collapse-text"
                                                aria-expanded={openRole}
                                                onChange={e => {changeSwitchRole(aksesRole&&aksesRole.name, e)}}
                                            /> <span className="ml-3">{aksesRole&&aksesRole.name}</span>
                                            <Collapse in={openRole}>
                                                <Col id="example-collapse-text" className="mt-3">
                                                    {aksesRole&&aksesRole.child.length > 0 ?
                                                        <Row className="ml-3">
                                                            {aksesRole&&aksesRole.child.map((child, i) => (
                                                                <Col sm="4 my-1" key={i}><Checkbox onChange={name => {changeCheckRole(name)}} value={child.name}>{child.name}</Checkbox></Col>
                                                            ))}
                                                        </Row>
                                                    :
                                                        <Row className="ml-3">
                                                            <Col sm="12"><Empty image={Empty.PRESENTED_IMAGE_SIMPLE} /></Col> 
                                                        </Row>
                                                    }
                                                </Col>
                                            </Collapse>
                                        </Col>
                                    </Card>
                                    {/* e.role */}
                                    
                                    {/* s.report */}
                                    <Card style={{border: 'unset', marginBottom: 'unset'}} className="border-bottom mt-5">
                                        <Col sm="12" className=" pb-4 ml-3">
                                            <Switch 
                                                onClick={() => setOpenReport(!openReport)}
                                                aria-controls="example-collapse-text"
                                                aria-expanded={openReport}
                                                onChange={e => {changeSwitchReport(aksesReport&&aksesReport.name, e)}}
                                            /> <span className="ml-3">{aksesReport&&aksesReport.name}</span>
                                            <Collapse in={openReport}>
                                                <Col id="example-collapse-text" className="mt-3">
                                                    {aksesReport&&aksesReport.child.length > 0 ?
                                                        <Row className="ml-3">
                                                            {aksesReport&&aksesReport.child.map((child, i) => (
                                                                <Col sm="4 my-1" key={i}><Checkbox onChange={name => {changeCheckReport(name)}} value={child.name}>{child.name}</Checkbox></Col>
                                                            ))}
                                                        </Row>
                                                    :
                                                        <Row className="ml-3">
                                                            <Col sm="12"><Empty image={Empty.PRESENTED_IMAGE_SIMPLE} /></Col> 
                                                        </Row>
                                                    }
                                                </Col>
                                            </Collapse>
                                        </Col>
                                    </Card>
                                    {/* e.report */}
                                    
                                    {/* s.crew */}
                                    <Card style={{border: 'unset', marginBottom: 'unset'}} className="border-bottom mt-5">
                                        <Col sm="12" className=" pb-4 ml-3">
                                            <Switch 
                                                onClick={() => setOpenCrew(!openCrew)}
                                                aria-controls="example-collapse-text"
                                                aria-expanded={openCrew}
                                                onChange={e => {changeSwitchCrew(aksesCrew&&aksesCrew.name, e)}}
                                            /> <span className="ml-3">{aksesCrew&&aksesCrew.name}</span>
                                            <Collapse in={openCrew}>
                                                <Col id="example-collapse-text" className="mt-3">
                                                    {aksesCrew&&aksesCrew.child.length > 0 ?
                                                        <Row className="ml-3">
                                                            {aksesCrew&&aksesCrew.child.map((child, i) => (
                                                                <Col sm="4 my-1" key={i}><Checkbox onChange={name => {changeCheckCrew(name)}} value={child.name}>{child.name}</Checkbox></Col>
                                                            ))}
                                                        </Row>
                                                    :
                                                        <Row className="ml-3">
                                                            <Col sm="12"><Empty image={Empty.PRESENTED_IMAGE_SIMPLE} /></Col> 
                                                        </Row>
                                                    }
                                                </Col>
                                            </Collapse>
                                        </Col>
                                    </Card>
                                    {/* e.crew */}

                                </Row>
                                
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default TambahRole