import React, { useContext } from "react";
import ReactPaginate from "react-paginate";
import { Context } from "../../Context/Store";

function PaginationCard(prop) {
  function handlePageClick(event) {
    prop.setPagination({
      ...prop.item,
      page: event.selected + 1,
    });
    // dispatch({ ...state, submit: true });
  }

  return (
    <div className="artis-footer">
      <ReactPaginate
        forcePage={prop.item.page - 1}
        className="artis-paginate"
        breakLabel="..."
        nextLabel="Next"
        onPageChange={(evt) => handlePageClick(evt)}
        pageRangeDisplayed={3}
        marginPagesDisplayed={2}
        pageCount={prop.item.last_page}
        previousLabel="Previous"
        renderOnZeroPageCount={null}
      />
    </div>
  );
}

export default PaginationCard;
