import React, { useState, useEffect, useContext } from "react";

import SiteWrapper from "../../Layouts/SiteWrapper";
import {
  Row,
  Col,
  Form,
  Button,
  InputGroup,
  FormControl,
  Pagination,
} from "react-bootstrap";
import { Card, Grid } from "tabler-react";
import { Link, useHistory } from "react-router-dom";
import axios from "axios";
import Loader from "react-loader-spinner";

import ArtisCard from "../../Components/Artis/ArtisCardComponent";
import FilterCard from "../../Components/Artis/FilterCardComponent";
import PaginationCard from "../../Components/Artis/PaginationCardComponent";
import PaginationSelectedCard from "../../Components/Artis/PaginationSelectedCardComponent";
import { Context } from "../../Context/Store";
import { ContextPermission } from "../../Context/Permission";
import { ContextAlert } from "../../Context/Alert";
import { GetArtis } from "../../actions/actions-artis";
import { GetTalentProspect } from "../../actions/actions-kandidat-artis";

function Artis() {
  type breadcrumbItem = {|
    +value: string,
    +to?: string,
    +active?: boolean,
  |};

  const breadcrumbItems: Array<breadcrumbItem> = [
    {
      value: "Artis",
      to: "/artis",
      active: false,
    },
  ];

  const urlMicroservice = process.env.REACT_APP_BE_DBTALENT_URL;
  const loginUrl = process.env.REACT_APP_LOGIN_TOKEN_URL;
  const token = localStorage.getItem("token");
  const [dataValue, setDataValue] = useState([]);
  const [talentProsepect, setTalentProspect] = useState([]);
  const [showButton, setShowButton] = useState(false);
  const [state, dispatch] = useContext(Context);
  const statePermission = useContext(ContextPermission);
  const [message, setMessage] = useContext(ContextAlert);
  const [isLoading, setLoading] = useState(false);

  const [pagination, setPagination] = useState({
    page: 1,
    per_page: 12,
    prev_page_url: "",
    next_page_url: "",
    from: "",
    to: "",
    last_page: "",
    total: "",
    tampil: "",
  });
  const [loader, setLoader] = useState(true);
  const history = useHistory();

  useEffect(() => {
    if (statePermission.first_access.home !== "Artis") {
      let link = "";
      if (statePermission.first_access.home === "Kandidat") {
        link = "kandidat-artis";
      } else if (statePermission.first_access.home === "Bookmark") {
        link = "bookmark";
      } else if (statePermission.first_access.home === "Judul") {
        link = "judul";
      } else if (statePermission.first_access.home === "User") {
        link = "manajemen-user";
      } else if (statePermission.first_access.home === "Role") {
        link = "manajemen-role";
      } else if (statePermission.first_access.home === "Report") {
        link = "report";
      }
      history.push(link);
    }

    if (
      statePermission.permission.artis.tambah_bookmark.active === 1 ||
      statePermission.permission.artis.tambah_casting.active === 1 ||
      statePermission.permission.artis.tambah_ke_grade_talent_report.active ===
        1 ||
      statePermission.permission.artis.tombol_edit_biografi.active === 1
    ) {
      setShowButton(true);
    }
  }, []);

  useEffect(() => {
    if (loader === true) {
      GetTalentProspect(token, ({ data, isFound, isError }) => {
        if (isFound) {
          setTalentProspect(data.data);
        } else if (isError) {
          handleError(data);
        }
      });
    }
  }, [loader]);

  useEffect(() => {
    fetchData();
  }, [pagination.page]);

  useEffect(() => {
    if (state.submit === true && pagination.page !== 1) {
      setPagination({ ...pagination, page: 1 });
    } else if (state.submit === true) {
      fetchData();
    }
  }, [state.submit]);

  function fetchData() {
    if (isLoading) return;
    GetArtis(
      `page=${pagination.page}&per_page=${pagination.per_page}`,
      state,
      token,
      ({ data, isFetching, isFound, isError }) => {
        setLoading(isFetching);
        if (isFound) {
          setDataValue(data.data.data.data);
          setPagination({
            ...pagination,
            prev_page_url: data.data.prev_page_url,
            next_page_url: data.data.next_page_url,
            from: data.data.from,
            to: data.data.to,
            last_page: data.data.last_page,
            total: data.data.total,
            tampil: data.data.data.data.length,
          });
          if (data.data.data.id) {
            dispatch({
              ...state,
              filter_id: data.data.data.id,
              submit: false,
            });
          } else {
            dispatch({ ...state, submit: false });
          }
          setLoader(false);
        } else if (isError) {
          let message = data.response.data.message;
          if (!message) {
            message = e.message;
          }
          setMessage({
            type: "danger",
            message: JSON.stringify(message),
            show: "d-block",
          });
        }
      }
    );
  }

  const rightBreadcrumb = (
    <Link to={"/artis/laporan"}>
      <Button variant="outline-danger" className="w-100" type="button">
        <i className="bx bxs-report"></i> Laporan Penerimaan Artis
      </Button>
    </Link>
  );

  return (
    <SiteWrapper
      breadcrumbItems={breadcrumbItems}
      title={"Artis"}
      rightBreadcrumb={
        statePermission.permission.artis.buat_laporan_artis.active === 1
          ? rightBreadcrumb
          : ""
      }
    >
      <Loader
        visible={loader}
        className="loaders"
        type="ThreeDots"
        color="#ED1C24"
        height="100"
        width="100"
      />
      {statePermission.home_menu.artis.active === 1 ? (
        <Grid.Row>
          <Grid.Col md="3">
            <FilterCard
              item={talentProsepect}
              filter={state}
              setFilter={dispatch}
            ></FilterCard>
          </Grid.Col>
          <Col md="9">
            <PaginationSelectedCard
              item={pagination}
              filter={state}
              setFilter={dispatch}
              talentProsepect={talentProsepect}
            ></PaginationSelectedCard>
            <Grid.Row>
              {dataValue &&
                dataValue.map((item, index) => {
                  return (
                    <Grid.Col width="3" className="px-1">
                      <ArtisCard
                        fetch={() => {
                          fetchData();
                        }}
                        button={showButton}
                        item={item}
                      ></ArtisCard>
                    </Grid.Col>
                  );
                })}
            </Grid.Row>

            <PaginationCard
              item={pagination}
              setPagination={setPagination}
            ></PaginationCard>
          </Col>
        </Grid.Row>
      ) : (
        <Grid.Row>
          <Grid.Col width="12" className="text-center">
            Anda Tidak Memiliki Akses Artis
          </Grid.Col>
        </Grid.Row>
      )}
    </SiteWrapper>
  );
}

export default Artis;
