import React, { useState } from "react";
import { Button, Image } from "react-bootstrap";
import { Grid } from "tabler-react";
import HapusFoto from "../../Components/Foto/HapusFotoComponent";

function Foto(prop) {
  return (
    <>
      <Grid.Col width={3} className="mb-5">
        <div className="image-artis">
          <Image src={prop.data.foto} className="img-artis"></Image>
          <div class="middle">
            <Button
              variant="light"
              type="button"
              onClick={() => {
                prop.setImagePreview({ image: prop.data.foto, isOpen: true });
              }}
            >
              <i class="bx bx-zoom-in"></i>
            </Button>
            <HapusFoto
              jenis={prop.jenis}
              setChange={prop.setChange}
              id={prop.data.id}
            ></HapusFoto>
          </div>
        </div>
        <div className="font-weight-bold mt-3">{prop.data.nama}</div>
      </Grid.Col>
    </>
  );
}

export default Foto;
