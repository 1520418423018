import React, { useState, useEffect, useContext } from "react";
import SiteWrapper from "../../../../Layouts/LayoutManajemen/SiteWrapper";
import { Card } from "tabler-react";
import { Row, Col, Button, ListGroup, Image, CardGroup } from 'react-bootstrap';
import ArtisCardDetailCasting from "../../../../Components/Judul/DetailCasting/ArtisCardDetailCasting";
import axios from 'axios';
import {Empty } from 'antd';
import EditCasting from "./Modal/EditCasting";
import AlertCustom from "../../../../Components/Modal/AlertCustomComponent";
import { Link } from "react-router-dom";
import Loader from 'react-loader-spinner';
import { ContextPermission } from "../../../../Context/Permission";

var createGuest = require('cross-domain-storage/guest');

function DetailCasting(props){

    const [loader, setLoader]     = useState(true);
    const token                   = localStorage.getItem('token');
    const [paginate, setPaginate] = useState({
        page: 1,
        id: props.match.params.id,
        per_page: '',
    });
    const [daftarKandidatTalent, setDaftarKandidatTalent] = useState('');
    const [daftarTalent, setDaftarTalent]                 = useState('');
    const [dataCasting, setDataCasting]                   = useState('');
    const [message, setMessage]                           = useState({type:'',message:'', show:'d-none'});
    const statePermission                                 = useContext(ContextPermission);
    const loginUrl                                        = process.env.REACT_APP_LOGIN_TOKEN_URL;

    type breadcrumbItem = {|
        +value: string,
        +to?: string,
        +active?: boolean,
    |};

    const breadcrumbItems: Array<breadcrumbItem> = [
      {
        value: "Casting",
        to: `/casting/${dataCasting&&dataCasting.title_id}`,
        active: true,
      },
      {
        value: "Detail Casting",
        to: "/detail-casting",
        active: false,
      }
    ];

    useEffect( () => {
        axios.get(process.env.REACT_APP_BE_DBTALENT_URL+`talent/casting/show?id=`+props.match.params.id,
        { 
          headers: {
              'Authorization' : `Bearer ${token}`, 
              'Accept'        : 'application/json', 
              'Content-Type'  : 'application/json'
          }
        })
        .then((res) =>{
            setDataCasting(res.data.data);
            setLoader(false)
        })
        .catch(error =>{
            if (error.response) {
                if(error.response.status===401){
                    window.open(loginUrl,"_parent")
                }
            }
        })
        
    }, [token]);

    useEffect( () => {
        axios.get(process.env.REACT_APP_BE_DBTALENT_URL+`talent/casting/talent/daftar-kandidat-talent?casting_id=${paginate.id}&per_page=${paginate.per_page}&page=${paginate.page}`,
        { 
          headers: {
              'Authorization' : `Bearer ${token}`, 
              'Accept'        : 'application/json', 
              'Content-Type'  : 'application/json'
          }
        })
        .then((res) =>{
            setDaftarKandidatTalent(res.data.data.data);
            setLoader(false)
        })
        .catch(error =>{
            if (error.response) {
                if(error.response.status===401){
                    window.open(loginUrl,"_parent")
                }
            }
        })
        
    }, [token, paginate, daftarTalent]);
    
    useEffect( () => {
        axios.get(process.env.REACT_APP_BE_DBTALENT_URL+`talent/casting/talent/daftar-talent?casting_id=${paginate.id}&per_page=${paginate.per_page}&page=${paginate.page}`,
        { 
          headers: {
              'Authorization' : `Bearer ${token}`, 
              'Accept'        : 'application/json', 
              'Content-Type'  : 'application/json'
          }
        })
        .then((res) =>{
            setDaftarTalent(res.data.data.data);
            setLoader(false)
        })
        .catch(error =>{
            if (error.response) {
                if(error.response.status===401){
                    window.open(loginUrl,"_parent")
                }
            }
        })
        
    }, [token, paginate]);
    
    const uncekTalent = (index, id) =>{

        const data = new FormData();
        data.append('id', id);
        data.append('status', 0);
        
        axios.post(process.env.REACT_APP_BE_DBTALENT_URL+`talent/casting/talent/update-status`, data,
        { 
        headers: {
            'Authorization' : `Bearer ${token}`, 
            'Accept'        : 'application/json', 
            'Content-Type'  : 'application/json'
        }
        })
        .then((res) =>{
            let filteredArray = daftarTalent.filter((item, i) => (i !== index));
            setDaftarTalent(filteredArray);
        })
        .catch(error =>{
            var message = error.message;
            if(typeof error.response != "undefined"){
                message = error.response.data.message;
            }
            setMessage({
                type:'danger',
                message:message,
                show:'d-block'
            });

            if (error.response) {
                if(error.response.status===401){
                    window.open(loginUrl,"_parent")
                }
            }
        })

    }
    
    const pilihTalent = (index, id) =>{

        const data = new FormData();
        data.append('id', id);
        data.append('status', 1);
        
        axios.post(process.env.REACT_APP_BE_DBTALENT_URL+`talent/casting/talent/update-status`, data,
        { 
        headers: {
            'Authorization' : `Bearer ${token}`, 
            'Accept'        : 'application/json', 
            'Content-Type'  : 'application/json'
        }
        })
        .then((res) =>{
            console.log(res);
            let filteredArray = daftarKandidatTalent.filter((item, i) => (i !== index));
            setDaftarKandidatTalent(filteredArray);
            setDaftarTalent([...daftarTalent, res.data.data.artist]);
        })
        .catch(error =>{
            var message = error.message;
            if(typeof error.response != "undefined"){
                message = error.response.data.message;
            }
            setMessage({
                type:'danger',
                message:message,
                show:'d-block'
            });

            if (error.response) {
                if(error.response.status===401){
                    window.open(loginUrl,"_parent")
                }
            }
        })

    }

    const hapusTalent = (index, id) =>{

        axios.delete(process.env.REACT_APP_BE_DBTALENT_URL+`talent/casting/talent/delete?id=`+id,
        { 
        headers: {
            'Authorization' : `Bearer ${token}`, 
            'Accept'        : 'application/json', 
            'Content-Type'  : 'application/json'
        }
        })
        .then((res) =>{
            let filteredArray = daftarKandidatTalent.filter((item, i) => (i !== index));
            setDaftarKandidatTalent(filteredArray);
        })
        .catch(error =>{
            var message = error.message;
            if(typeof error.response != "undefined"){
                message = error.response.data.message;
            }
            setMessage({
                type:'danger',
                message:message,
                show:'d-block'
            });

            if (error.response) {
                if(error.response.status===401){
                    window.open(loginUrl,"_parent")
                }
            }
        })

    }

    console.log(daftarTalent);

    return(
        <SiteWrapper breadcrumbItems={breadcrumbItems} title={"Detail Casting"} title_id={props.match.params.title_id} linkBack={`/casting/${dataCasting&&dataCasting.title_id}`}>
            <Loader visible={loader} className="loaders" type="ThreeDots" color="#ED1C24" height="100" width="100" />
            {statePermission.detail_judul_menu.casting.active === 1 ?
                <>
                    <AlertCustom 
                        setMessage={setMessage}
                        showError={message.show}
                        message={message.message}
                        type={message.type}
                    ></AlertCustom>
                    <Row>
                        <Col sm="3">
                            <Card className="border-card mt-5">
                                <Card.Body>
                                    <Row className="justify-content-center align-items-center">
                                        <Col sm="8">
                                            <h5 className="font-weight-bold">Informasi Casting</h5>
                                        </Col>
                                        <Col sm="4">
                                            {statePermission.permission.casting.atur_casting.active === 1 ?
                                                    <EditCasting token={token} id={props.match.params.id}/>
                                                :
                                                    <></>
                                            }
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm="12 mt-3">
                                            <span>Nama Peran</span><br/>
                                            <span className="font-weight-bold">{dataCasting&&dataCasting.nama_peran}</span>
                                        </Col>
                                        <Col sm="12 mt-4">
                                            <span>Deskripsi Pesan</span><br/>
                                            <span className="font-weight-bold">{dataCasting&&dataCasting.deskripsi}</span>
                                        </Col>
                                    
                                    </Row>
                                </Card.Body>
                            </Card>

                            <Card className="border-card mt-5">
                                <Card.Body>
                                    <Row className="justify-content-center align-items-center">
                                        <Col sm="12">
                                            <h5 className="font-weight-bold">Talent Dipilih</h5>
                                        </Col>
                                    </Row>

                                    {daftarTalent&&daftarTalent.length > 0 ?
                                            <Row>
                                                <Col sm="12 mt-3">
                                                    <Button variant="light" className="w-100" type="button" style={{fontSize: "11px", color: "blue"}}> Talent yang telah dipilih akan muncul <br/>juga di Bagan.</Button>
                                                </Col>
                                                <Col sm="12 mt-4">
                                                    <Row>
                                                        <ListGroup variant="flush">
                                                            {daftarTalent&&daftarTalent.map((item, i) =>(
                                                                <ListGroup.Item key={i}>
                                                                    <Row>
                                                                        <Col sm="12">
                                                                                <div class="d-flex flex-row justify-content-center align-items-center">
                                                                                    <Link to={"/artis/detail/"+item.artist_id}>
                                                                                        <div class="pr-0"><Image src={item.foto} roundedCircle className="img-rounded img-card-bookmark" style={{height: "35px", width: "35px"}}/></div>
                                                                                    </Link>

                                                                                    <Link to={"/artis/detail/"+item.artist_id}>
                                                                                        <div class="pl-1">
                                                                                            <span className="font-weight-bold" style={{fontSize: "13px"}}> {item.nama_artis}</span><br/>
                                                                                            <span className="font-10" style={{fontSize: "12px"}}>{item.usia} - {item.gender === 1 ? 'Laki-Laki' : 'Perempuan'}</span>
                                                                                        </div>
                                                                                    </Link>
                                                                                    <div class="pl-1" >
                                                                                        <i className="bx bx-x p-0 rounded bg-light" style={{fontSize:"24px", cursor: "pointer"}} onClick={() => uncekTalent(i, item.id)}/>
                                                                                    </div>
                                                                                </div>
                                                                        </Col>
                                                                    </Row>
                                                                </ListGroup.Item>

                                                            ))}
                                                        </ListGroup>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        :
                                            <Row className="px-4 justify-content-md-center">
                                                <Col sm="12 my-5"><Empty image={Empty.PRESENTED_IMAGE_SIMPLE} /></Col> 
                                            </Row>
                                    }
                                </Card.Body>
                            </Card>

                        </Col>
                        
                        <Col sm="9">
                            <Card className="border-card mt-5">
                                <Card.Body>
                                    <Row className="justify-content-center align-items-center mb-5">
                                        <Col md="6">
                                            <h className="font-weight-bold">Daftar Talent</h>
                                        </Col>
                                        <Col md="6" className="text-right">
                                            <Button variant="danger" as={Link} to={"/artis"} className="text-white">+ Tambah Talent</Button>
                                        </Col>
                                    </Row>

                                    {daftarKandidatTalent&&daftarKandidatTalent.length > 0 ?
                                            <Row className="px-0">
                                                <CardGroup className="col-12">
                                                    {daftarKandidatTalent&&daftarKandidatTalent.map((item, i) => (
                                                        <div className="d-flex align-items-stretch col-md-3">
                                                            <ArtisCardDetailCasting talent={item} pilihTalent={pilihTalent} hapusTalent={hapusTalent} index={i} permissionArtis={statePermission.permission.casting.atur_artis.active}></ArtisCardDetailCasting>
                                                        </div>
                                                    ))}
                                                </CardGroup>
                                            </Row>
                                        :
                                            <Row className="px-4 justify-content-md-center">
                                                <Col sm="12 my-5"><Empty image={Empty.PRESENTED_IMAGE_SIMPLE} /></Col> 
                                            </Row>
                                    }
                                </Card.Body>
                            </Card>
                        </Col>
                        
                    </Row>
                </>
                :
                    <Row>
                        <Col width="12" className="text-center">
                            Anda Tidak Memiliki Akses Casting
                        </Col>
                    </Row>
            }
        </SiteWrapper>
    )
}

export default DetailCasting