function ServerManager() {
    let responseStatus = null;
  
    function serverRequest({ method = 'POST', url = '', body = undefined, header, conf = {} }, callback) {
      callback({ data: null, isError: false, isFound: false, isFetching: true });
      
      fetch(url, {
        method,
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        redirect: 'follow',
        referrerPolicy: 'no-referrer',
        headers: {
          Accept: 'application/json',
          ...header,
        },
        body,
        ...conf,
      })
        .then((response) => {
          responseStatus = response.status;
  
          if (response.status === 500 || response.status === 401) {
            throw response.json();
          }
  
          return response.json();
        })
        .then((data) => {
          if (data) {
            callback({
              data,
              isError: false,
              isFound: true,
              isFetching: false,
              responseStatus,
            });
          } else {
            callback({
              data,
              isError: true,
              isFound: false,
              isFetching: false,
              responseStatus,
            });
          }
        })
        .catch((error) => {
          callback({
            data: error,
            isError: true,
            isFound: false,
            isFetching: false,
            responseStatus,
          });
        });
    }
  
    function serverRequestXhtml({ method = 'POST', url = '', body = undefined, header, conf = {} }, callback) {
      header = {
        ...header,
        Accept: 'application/json',
      };
  
      const oReq = new XMLHttpRequest();
      callback({
        data: null,
        isError: false,
        isFound: false,
        isFetching: true,
        request: oReq,
        progress: 0,
      });
  
      const onProgress = (e) => {
        const stateProgress = (e.loaded / e.total) * 100;
        callback({
          data: null,
          isError: false,
          isFound: false,
          isFetching: true,
          progress: stateProgress,
          request: oReq,
        });
      };
  
      const onError = (e) => {
        callback({
          data: e,
          isError: true,
          isFound: false,
          isFetching: false,
          progress: 0,
          request: oReq,
        });
      };
  
      if (oReq.upload) {
        oReq.upload.onprogress = onProgress;
        oReq.upload.onerror = onError;
      } else {
        oReq.onprogress = onProgress;
      }
  
      oReq.onerror = onError;
  
      oReq.onload = (e) => {
        if (oReq.readyState === XMLHttpRequest.DONE) {
          let data = JSON.parse(oReq.responseText);
          callback({
            data,
            isError: false,
            isFound: true,
            isFetching: false,
            request: oReq,
            progress: 100,
          });
        } else {
          callback({
            data: oReq,
            isError: true,
            isFound: false,
            isFetching: false,
            request: oReq,
            progress: 0,
          });
        }
      };
  
      oReq.open(method, url, true);
  
      for (const key in header) {
        oReq.setRequestHeader(key, header[key]);
      }
  
      oReq.send(body);
    }
  
    function post({ url, type = 'fetch', body, header = { 'Content-Type': 'application/x-www-form-urlencoded' }, conf }, callback) {
      if (type === 'fetch') {
        serverRequest({ method: 'post', url, body, header, conf }, callback);
      } else {
        serverRequestXhtml({ method: 'POST', url, body, header, conf }, callback);
      }
    }
  
    function get({ url, type = 'fetch', body, header = { 'Content-Type': 'application/json' }, conf }, callback) {
      if (type === 'fetch') {
        serverRequest({ method: 'get', url, body, header, conf }, callback);
      } else {
        serverRequestXhtml({ method: 'GET', url, body, header, conf }, callback);
      }
    }
  
    function del({ url, type = 'fetch', body, header = { 'Content-Type': 'application/json' }, conf }, callback) {
      if (type === 'fetch') {
        serverRequest({ method: 'delete', url, body, header, conf }, callback);
      } else {
        serverRequestXhtml({ method: 'delete', url, body, header, conf }, callback);
      }
    }
  
    function put({ url, type = 'fetch', body, header = { 'Content-Type': 'application/json' }, conf }, callback) {
      if (type === 'fetch') {
        serverRequest({ method: 'put', url, body, header, conf }, callback);
      } else {
        serverRequestXhtml({ method: 'PUT', url, body, header, conf }, callback);
      }
    }
  
    return {
      post,
      get,
      del,
      put,
    };
}
  
  
export default ServerManager;