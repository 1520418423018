import { Divider } from "antd";
import React, { useState, useEffect, useContext } from "react";
import { Button, Form, FloatingLabel } from "react-bootstrap";
import { Card } from "tabler-react";
import axios from "axios";
import { ContextPermission } from "../../Context/Permission";
import { ContextAlert } from "../../Context/Alert";

function CatatanCard(props) {
  const urlMicroservice = process.env.REACT_APP_BE_DBTALENT_URL;
  const token = localStorage.getItem("token");
  const [editable, setEditable] = useState(false);
  const [showMore, setShowMore] = useState(false);
  const [log, setLog] = useState("");
  const [text, setText] = useState("");
  const [initText, setInitText] = useState("");
  const statePermission = useContext(ContextPermission);
  const [message, setMessage] = useContext(ContextAlert);

  useEffect(() => {
    var value = props.data.catatan ? props.data.catatan : "";
    setInitText(value);
    setText(value);
    setLog(props.data.catatan_log);
  }, [props.data]);

  const [buttonEdit, setButtonEdit] = useState();

  function handleChange(evt) {
    const value = evt.target.value;
    setText(value);
  }

  function cancelEdit() {
    setText(initText);
    setEditable(false);
  }

  function saveEdit() {
    const data = {
      id: props.data.id,
      catatan: text,
    };
    axios
      .post(`${urlMicroservice}talent/artis/catatan`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        setInitText(text);
        setEditable(false);
      })
      .catch((e) => {
        var message = e.response.data.message;
        if (!message) {
          message = e.message;
        }
        setMessage({
          type: "danger",
          message: JSON.stringify(message),
          show: "d-block",
        });
      });
  }

  return (
    <Card className="border-card mt-5">
      <Card.Body className="p-4">
        <Form>
          <div className="d-flex align-items-center mb-4">
            <h4 className="font-weight-bold mb-0">Catatan</h4>
            {statePermission.permission.artis.tambah_catatan.active === 1 ? (
              <>
                {editable === true ? (
                  <div className="ml-auto">
                    <Button
                      variant="outline-danger"
                      onClick={() => cancelEdit()}
                      className="mr-3"
                      type="button"
                    >
                      Batal
                    </Button>
                    <Button
                      variant="danger"
                      onClick={() => saveEdit()}
                      type="button"
                    >
                      Simpan
                    </Button>
                  </div>
                ) : (
                  <Button
                    variant="outline-danger"
                    className="ml-auto"
                    onClick={() => setEditable(true)}
                    type="button"
                  >
                    <i class="bx bxs-edit-alt"></i> Edit Catatan
                  </Button>
                )}
              </>
            ) : (
              ""
            )}
          </div>
          {editable === true ? (
            <Form.Control
              as="textarea"
              name="catatan"
              onChange={handleChange}
              placeholder="Masukkan catatan disini"
              value={text}
              style={{ height: "100px" }}
            />
          ) : (
            <div>
              <div>
                {showMore || !text ? text : `${text.substring(0, 150)}`}
                {text && text.length > 150 ? (
                  <div>
                    <a
                      className="text-danger mt-2"
                      onClick={() => setShowMore(!showMore)}
                    >
                      {showMore
                        ? "Tampilkan lebih sedikit"
                        : "Tampilkan lebih banyak"}
                    </a>
                  </div>
                ) : (
                  ""
                )}
              </div>
              {text ? (
                <div className="text-grey mt-3">
                  Terakhir diperbarui pada {log}
                </div>
              ) : (
                ""
              )}
            </div>
          )}
        </Form>
      </Card.Body>
    </Card>
  );
}
export default CatatanCard;
