import React, {useState} from 'react';
import {Modal, Button, Row, Col, Form} from "react-bootstrap";
import axios from 'axios';
import AlertCustom from '../../../Components/Modal/AlertCustomComponent';
import { AiFillEdit } from "react-icons/ai";

function EditGroup(props) {
    const [show, setShow]           = useState(false);
    const handleClose               = () => setShow(false);
    const handleShow                = () => setShow(true);
    const [groupName, setGroupName] = useState('');
    const [message, setMessage]     = useState({type:'',message:'', show:'d-none'});
    const [disable, setDisable]     = useState(false);
    const loginUrl                  = process.env.REACT_APP_LOGIN_TOKEN_URL;

    const getDataEdit = () =>{
      setDisable(true);
      axios.get(process.env.REACT_APP_BE_DBTALENT_URL+`talent/group/edit/`+props.idGroup,
      { 
        headers: {
            'Authorization' : `Bearer ${props.token}`, 
            'Accept'        : 'application/json', 
            'Content-Type'  : 'application/json'
        }
      })
      .then((res) =>{
        setGroupName(res.data.data.group_name);
        setDisable(false);
      })
      .catch(error =>{
        if (error.response) {
          if(error.response.status===401){
              window.open(loginUrl,"_parent")
          }
        }
      })
    }

    const submitEditGroup = () =>{

      const data = new FormData();
      data.append('group_name', groupName);
     
      if(groupName === ''){
        var message = 'Nama group tidak boleh kosong';
        setMessage({
            type:'danger',
            message:message,
            show:'d-block'
        });
      }else{
        setDisable(true);
        axios.post(process.env.REACT_APP_BE_DBTALENT_URL+`talent/group/update/`+props.idGroup, data,
        { 
          headers: {
              'Authorization' : `Bearer ${props.token}`, 
              'Accept'        : 'application/json', 
              'Content-Type'  : 'application/json'
          }
        })
        .then((res) =>{
          setMessage({
            type:'success',
            message:"Berhasil Mengubah Group",
            show:'d-block'
          });
          setTimeout(() => {
            window.location.reload();
          }, 1000);
          console.log('return tambah group', res);
        })
        .catch(error =>{
          setDisable(false);
          var message = error.message;
          if(typeof error.response != "undefined"){
            message = error.response.data.message;
          }
          setMessage({
            type:'danger',
            message:message,
            show:'d-block'
          });

          if (error.response) {
            if(error.response.status===401){
                window.open(loginUrl,"_parent")
            }
          }
        })

      }
    }

    const changeGroupNama = (e) => {
      let val = e.target.value;
      setGroupName(val);
    }

    return (
      <>
        <Button variant="warning" className="mr-4" size="sm" onClick={() =>{handleShow(); getDataEdit();}}><AiFillEdit size="16px" color="white"/></Button>
  
        <Modal 
          show={show} 
          onHide={handleClose}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <AlertCustom 
            setMessage={setMessage}
            showError={message.show}
            message={message.message}
            type={message.type}
          ></AlertCustom>
          <Modal.Header closeButton>
            <Modal.Title>Edit Group</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row className="mb-0">
                <Col sm="12" className="mt-0">
                  <Form.Group className="mb-0" controlId="nama">
                      <Form.Label>Edit Group <span className='text-danger'>*</span></Form.Label>
                      <Form.Control type="text" placeholder="Masukan Nama Group" onChange={changeGroupNama} value={groupName}/>
                  </Form.Group>
                </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
          <Button variant="outline-danger" className="px-5 mr-2" onClick={handleClose}>Batal</Button>
                    <Button variant="danger" className="px-5 py-2" disabled={disable} onClick={submitEditGroup}>Simpan</Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
  
  export default EditGroup