import React, {useState} from 'react';
import {Modal, Button, Row, Col, Form} from "react-bootstrap";
import { Radio } from 'antd';
import axios from 'axios';
import AlertCustom from '../../../../../Components/Modal/AlertCustomComponent';

function BuatCasting(props) {
    const handleClose                             = () => setShow(false);
    const handleShow                              = () => setShow(true);
    const [show, setShow]                         = useState(false);
    const [activeTab, setActiveTab]               = useState(1);
    const [message, setMessage]                   = useState({type:'',message:'', show:'d-none'});
    const [disable, setDisable]                   = useState(false);
    const [namaCasting, setNamaCasting]           = useState('');
    const [deskripsiCasting, setDeskripsiCasting] = useState('');
    const [statusPeran, setStatusPeran]           = useState(1);
    const loginUrl                                = process.env.REACT_APP_LOGIN_TOKEN_URL;
    const [errorNama, setErrorNama]               = useState('none');
    const [errorDeskripsi, setErrorDeskripsi]     = useState('none');

    function toggleClass(index){
        setActiveTab(index);
        setStatusPeran(index);
    };

    const submitCastingBaru = () =>{
      const data = new FormData();
      data.append('title_id', props.id);
      data.append('nama_peran', namaCasting);
      data.append('deskripsi', deskripsiCasting);
      data.append('status_peran', statusPeran);
     
      if(namaCasting === ''){
        setErrorNama('inline')
      }else if(deskripsiCasting === ''){
        setErrorDeskripsi('inline')
      }else{
        setDisable(true);
        axios.post(process.env.REACT_APP_BE_DBTALENT_URL+`talent/casting/create`, data,
        { 
          headers: {
              'Authorization' : `Bearer ${props.token}`, 
              'Accept'        : 'application/json', 
              'Content-Type'  : 'application/json'
          }
        })
        .then((res) =>{
          setMessage({
            type:'success',
            message:"Berhasil Menambahkan Casting",
            show:'d-block'
          });
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        })
        .catch(error =>{
          setDisable(false);
          var message = error.message;
          if(typeof error.response != "undefined"){
            message = error.response.data.message;
          }
          setMessage({
            type:'danger',
            message:message,
            show:'d-block'
          });

          if (error.response) {
            if(error.response.status===401){
                window.open(loginUrl,"_parent")
            }
          }
        })

      }
    }

    const changeNamaCasting = (e) => {
      let val = e.target.value;
      setNamaCasting(val);
      if(val !== ''){
        setErrorNama('none')
      }
    }

    const changeDeskripsiCasting = (e) => {
      let val = e.target.value;
      setDeskripsiCasting(val);
      if(val !== ''){
        setErrorDeskripsi('none')
      }
    }

    const changeStatusCasting = e => {
      setStatusPeran(e.target.value);
    };

    return (
      <>
        <Button variant="danger" onClick={handleShow}>+ Buat Casting Baru</Button>
        <Modal 
          show={show} 
          onHide={handleClose}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >

          <AlertCustom 
            setMessage={setMessage}
            showError={message.show}
            message={message.message}
            type={message.type}
          ></AlertCustom>

          <Modal.Header closeButton>
            <Modal.Title>Buat Casting Baru</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row className="mb-0">
                <Col sm="12" className="mt-0">
                  <Form.Group className="mb-4" controlId="nama">
                      <Form.Label>Buat Casting</Form.Label>
                      <Form.Control type="text" placeholder="Masukan Nama Casting" onChange={changeNamaCasting}/>
                      <span className="text-danger" style={{display : errorNama}}>* nama tidak boleh kosong</span>
                  </Form.Group>

                  <Form.Group className="mb-4" controlId="nama">
                      <Form.Label>Jenis Peran</Form.Label>

                      <Radio.Group onChange={changeStatusCasting} value={statusPeran}>
                        <div className="mt-0">
                          <div className="ml-0">
                            <a className={(activeTab==1?'active':'')+' mini-nav p-2 rounded'} style={{width: "220px", border: "#e6dfdf solid 1px"}} onClick={(e) => toggleClass(1)} type="button"><Radio value={1}>Utama</Radio></a>
                            <a className={(activeTab==0?'active':'')+' mini-nav p-2 rounded ml-5'} style={{width: "220px", border: "#e6dfdf solid 1px"}} onClick={(e) => toggleClass(0)} type="button"><Radio value={0}>SP</Radio></a>
                          </div>
                        </div>
                      </Radio.Group>
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                    <Form.Label>Deskripsi Peran</Form.Label>
                    <Form.Control as="textarea" rows={3} onChange={changeDeskripsiCasting}/>
                    <span className="text-danger" style={{display : errorDeskripsi}}>* deskripsi tidak boleh kosong</span>
                  </Form.Group>
                </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
          <Button variant="outline-danger" className="px-5 mr-2" onClick={handleClose}>Batal</Button>
              <Button disabled={disable} variant="danger" className="px-5 py-2" onClick={submitCastingBaru}>Buat</Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
  
  export default BuatCasting