import React from "react";

import {
  Button,
  Image,
  Row,
  Col,
  Card,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { Badge } from "tabler-react";
import { Link } from "react-router-dom";
import { BsTrashFill } from "react-icons/bs";
import { Menu, Dropdown } from "antd";
import { DownOutlined } from "@ant-design/icons";

function ArtisCard(props) {
  const { SubMenu } = Menu;

  const menu = (
    <Menu>
      {props.listCategory.map((items, i) => (
        <SubMenu title={items.category} key={i}>
          {items.child.map((item, i) => (
            <Menu.Item
              key={item.id}
              onClick={() =>
                props.klikUbahKategori(
                  props.listArtis.id,
                  item.id,
                  item.report_id
                )
              }
            >
              {item.name}
            </Menu.Item>
          ))}
        </SubMenu>
      ))}
    </Menu>
  );

  return (
    <Card className="card-rounded">
      <Card.Body className="p-0 ">
        <Link to={"/kandidat-artis/detail/" + props.listArtis.id}>
          <Image
            src={props.listArtis.foto}
            className="img-rounded img-card-bookmark"
          />
        </Link>
        <div className="p-2">
          <Link to={"/kandidat-artis/detail/" + props.listArtis.id}>
            {props.listArtis.nama_artis.length > 15 ? (
              <OverlayTrigger
                placement="bottom"
                overlay={
                  <Tooltip id="button-tooltip-2">
                    {props.listArtis.nama_artis}
                  </Tooltip>
                }
              >
                <h4 className="font-weight-bold">
                  {props.listArtis.nama_artis.substring(0, 12)}...
                </h4>
              </OverlayTrigger>
            ) : (
              <h4 className="font-weight-bold">{props.listArtis.nama_artis}</h4>
            )}
            <div>
              {props.listArtis.usia} &#183; {props.listArtis.gender}
            </div>

            {props.listArtis.talent_prospects.length > 2 ? (
              <>
                <div className="mt-3" style={{ display: "inline" }}>
                  {props.listArtis.talent_prospects.length === 1 ? (
                    <Badge
                      color="light"
                      key={1}
                      className="mr-1 p-2 text-blue border"
                    >
                      {props.listArtis.talent_prospects[0].nama}
                    </Badge>
                  ) : props.listArtis.talent_prospects.length > 1 ? (
                    <>
                      <Badge
                        color="light"
                        key={1}
                        className="mr-1 p-2 text-blue border"
                      >
                        {props.listArtis.talent_prospects[0].nama}
                      </Badge>
                      <Badge
                        color="light"
                        key={2}
                        className="mr-1 p-2 text-blue border"
                      >
                        {props.listArtis.talent_prospects[1].nama}
                      </Badge>
                    </>
                  ) : (
                    <></>
                  )}
                </div>

                <OverlayTrigger
                  placement="bottom"
                  overlay={
                    <Tooltip id="button-tooltip-2">
                      {props.listArtis.talent_prospects
                        .slice(2)
                        .map((item, i) => (
                          <Badge
                            color="light"
                            key={i}
                            className="mr-1 p-2 text-blue border"
                          >
                            {item.nama}
                          </Badge>
                        ))}
                    </Tooltip>
                  }
                >
                  <div className="mt-3" style={{ display: "inline" }}>
                    <Badge color="primary" className="mr-1 p-2 border mt-1">
                      {props.listArtis.talent_prospects.length - 2} +
                    </Badge>
                  </div>
                </OverlayTrigger>
              </>
            ) : (
              <div className="mt-3">
                {props.listArtis.talent_prospects.map((item, i) => (
                  <Badge
                    color="light"
                    key={i}
                    className="mr-1 p-2 text-blue border"
                  >
                    {item.nama}
                  </Badge>
                ))}
              </div>
            )}

            {/* <div className="mt-3">
                            {props.listArtis.talent_prospects.map((item, i) => (
                                <Badge color="light" key={i} className="mr-1 p-2 text-blue border">{item.nama}</Badge>
                            ))}
                            <Badge color="primary" className="mr-1 p-2 text-blue border">3 +</Badge>
                        </div> */}

            <div class="clearfix mb-2 mt-3">
              <span
                style={{ fontSize: "11px", paddingLeft: "unset" }}
                className="float-md-start text-gray col-5"
              >
                Karakter
              </span>
              {props.listArtis.karakter !== null &&
              props.listArtis.karakter.length > 15 ? (
                <OverlayTrigger
                  placement="bottom"
                  overlay={
                    <Tooltip id="button-tooltip-2">
                      {props.listArtis.karakter}
                    </Tooltip>
                  }
                >
                  <span
                    style={{ fontSize: "11px", paddingLeft: "unset" }}
                    className="float-md-start font-weight-regular col-7"
                  >
                    {props.listArtis.karakter.substring(0, 12)}...
                  </span>
                </OverlayTrigger>
              ) : (
                <span
                  style={{ fontSize: "11px", paddingLeft: "unset" }}
                  className="float-md-start font-weight-regular col-7"
                >
                  {props.listArtis.karakter}
                </span>
              )}
            </div>
            <div class="clearfix mb-2">
              <span
                style={{ fontSize: "11px", paddingLeft: "unset" }}
                className="float-md-start text-gray col-5"
              >
                Status
              </span>
              <span
                style={{ fontSize: "11px" }}
                className="font-weight-regular float-md-start"
              >
                {props.listArtis.status_pernikahan}
              </span>
            </div>
            <div class="clearfix mb-2" style={{ textAlign: "justify" }}>
              <span
                style={{ fontSize: "11px", paddingLeft: "unset" }}
                className="float-md-start text-gray col-5"
              >
                Remark
              </span>
              {props.listArtis.remark.length > 30 ? (
                <OverlayTrigger
                  placement="bottom"
                  overlay={
                    <Tooltip id="button-tooltip-2">
                      {props.listArtis.remark}
                    </Tooltip>
                  }
                >
                  <span
                    style={{ fontSize: "11px", paddingLeft: "unset" }}
                    className="font-weight-regular float-md col-7"
                  >
                    {props.listArtis.remark.substring(0, 65)}...
                  </span>
                </OverlayTrigger>
              ) : (
                <span
                  style={{ fontSize: "11px", paddingLeft: "unset" }}
                  className="font-weight-regular float-md col-7"
                >
                  {props.listArtis.remark}
                </span>
              )}
            </div>
          </Link>
          <Row className="mt-3 mb-2">
            {props.disable && (
              <>
                <Col className="col-2 pt-1">
                  <BsTrashFill
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      props.klikHapusArtis(props.listArtis.id, props.idFile)
                    }
                  />
                </Col>
                <Col className="col-10">
                  <Dropdown overlay={menu}>
                    <Button
                      variant="outline-danger"
                      className="ant-dropdown-link w-100"
                      size="sm"
                      onClick={(e) => e.preventDefault(props.listArtis.id)}
                    >
                      Ubah Kategori <DownOutlined />
                    </Button>
                  </Dropdown>
                </Col>
              </>
            )}
          </Row>
        </div>
      </Card.Body>
    </Card>
  );
}
export default ArtisCard;
