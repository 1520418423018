import React, { useState } from "react";
import { Modal, Button, Row, Col, Image, Dropdown } from "react-bootstrap";
import axios from "axios";
import AlertCustom from "../Modal/AlertCustomComponent";

function HapusKandidat(props) {
  const [show, setShow] = useState(false);
  const token = localStorage.getItem("token");
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [urlImageAvatar] = useState(
    process.env.REACT_APP_FRONTEND_URL + "images/delete.png"
  );
  const [disable, setDisable] = useState(false);
  const [message, setMessage] = useState({
    type: "",
    message: "",
    show: "d-none",
  });
  const loginUrl = process.env.REACT_APP_LOGIN_TOKEN_URL;

  const hapusKandidat = () => {
    setDisable(true);
    axios
      .delete(
        process.env.REACT_APP_BE_DBTALENT_URL +
          `talent/kandidat-artis/delete?id=` +
          props.data.id,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        setMessage({
          type: "success",
          message: "Berhasil Menghapus Kandidat",
          show: "d-block",
        });
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((error) => {
        setDisable(false);
        var message = error.message;
        if (typeof error.response != "undefined") {
          message = error.response.data.message;
        }
        setMessage({
          type: "danger",
          message: message,
          show: "d-block",
        });

        if (error.response) {
          if (error.response.status === 401) {
            window.open(loginUrl, "_parent");
          }
        }
      });
  };

  return (
    <>
      {/* <span onClick={handleShow} style={{ cursor: "pointer" }}>
        <i class="bx bxs-trash"></i> Hapus Kandidat
      </span> */}

      <Dropdown.Item className="text-dark" onClick={handleShow}>
        <i class="bx bxs-trash"></i> Hapus Kandidat
      </Dropdown.Item>
      <Modal show={show} onHide={handleClose}>
        <AlertCustom
          setMessage={setMessage}
          showError={message.show}
          message={message.message}
          type={message.type}
        ></AlertCustom>
        <Modal.Body>
          <Row className="my-5">
            <Col sm="12" className="text-center">
              <Image
                src={urlImageAvatar}
                roundedCircle
                style={{ height: "100px", width: "100px" }}
              />
            </Col>
            <Col sm="12" className="text-center mt-5">
              <p className="font-weight-bold">{`Hapus Kandidat ${props.data.nama_artis} ?`}</p>
              <p>Kandidat yang telah dihapus tidak dapat dikembalikan lagi</p>
            </Col>
          </Row>
          <Row className="mb-5">
            <Col sm="6" className="text-right">
              <Button
                variant="outline-danger"
                className="px-5"
                onClick={handleClose}
              >
                Batal
              </Button>
            </Col>
            <Col sm="6">
              <Button
                variant="danger"
                className="px-5"
                disabled={disable}
                onClick={hapusKandidat}
              >
                Hapus
              </Button>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default HapusKandidat;
