import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { Grid } from "tabler-react";
import HapusVideo from "./HapusVideoComponent";

function Video(prop) {
  const [controls, setControls] = useState(false);
  return (
    <>
      <Grid.Col width={3}>
        <div className="image-artis video">
          <div className="artist-video">
            <video src={prop.data.video} onMouseEnter={()=>setControls(true)} onMouseLeave={()=>setControls(false)} controls={controls}/>
          </div>
          <div class="middle">
            <a target="_blank" href={prop.data.video}>
              <Button variant="light" type="button">
                <i class="bx bx-zoom-in"></i>
              </Button>
            </a>
            <HapusVideo
              jenis={prop.jenis}
              setChange={prop.setChange}
              id={prop.data.id}
            ></HapusVideo>
          </div>
        </div>
        <div className="font-weight-bold mt-3">{prop.data.nama}</div>
      </Grid.Col>
    </>
  );
}

export default Video;
