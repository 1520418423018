import React, { useContext } from "react";

import {
  Button,
  Image,
  Dropdown,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { Card, Badge, Grid } from "tabler-react";
import { Link } from "react-router-dom";
import TambahBookmark from "./TambahBookmarkCardComponent";
import EditNPWP from "./EditNpwpCardComponent";
import TambahCasting from "./TambahCastingCardComponent";
import TambahReport from "./TambahReportCardComponent";
import { ContextPermission } from "../../Context/Permission";

function ArtisCard(prop) {
  const shouldShowTooltip =
    prop.item.catatan &&
    prop.item.catatan !== "-" &&
    prop.item.catatan.length > 20;

  const statePermission = useContext(ContextPermission);
  return (
    <Card className="card-rounded">
      <Card.Body className="p-0 ">
        <Link to={"/artis/detail/" + prop.item.id}>
          <Image src={prop.item.foto} className="img-rounded img-list-artis" />
        </Link>
        <div className="p-3">
          <Link to={"/artis/detail/" + prop.item.id}>
            <OverlayTrigger
              key={"top"}
              placement={"top-start"}
              overlay={
                <Tooltip id={"tooltip-name"}>{prop.item.nama_artis}</Tooltip>
              }
            >
              <h4 className="font-weight-bold text-truncate">
                {prop.item.nama_artis}
              </h4>
            </OverlayTrigger>
            <div>
              {prop.item.usia} &#183;{" "}
              {prop.item.gender === 1 ? "Laki-Laki" : "Perempuan"}
            </div>
            <div className="mt-3">
              {prop?.item?.availability === 1 ? (
                <Badge color="success" className="mr-1 p-2">
                  Available
                </Badge>
              ) : (
                <Badge color="danger" className="mr-1 p-2">
                  Not Available
                </Badge>
              )}
            </div>
            <div className="mt-3">
              {/* <div>
                <i class="bx bxs-camera-movie text-danger"></i>{" "}
                {prop.item.artis_sinemart === 1 ? "Sinemart" : "Luar Sinemart"}
              </div> */}
              <div>
                <i class="bx bxs-camera-movie text-danger"></i>{" "}
                {prop.item.is_exclusive === 1 ? "Eksklusif" : "Non Eksklusif"}
              </div>
              <div className="mt-2">
                <i class="bx bxs-buildings text-warning"></i>{" "}
                {prop.item.manajemen}
              </div>
              <div className="mt-2">
                {shouldShowTooltip ? (
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip id="tooltip-top">{prop.item.catatan}</Tooltip>
                    }
                  >
                    <p className="ellipsis-text">
                      <i className="bx bxs-notepad text-dark"></i>{" "}
                      {prop.item.catatan}
                    </p>
                  </OverlayTrigger>
                ) : (
                  <p className="ellipsis-text">
                    <i className="bx bxs-notepad text-dark"></i>{" "}
                    {prop.item.catatan}
                  </p>
                )}
              </div>
              {/* {!prop.item.erp_id ? (
                <div className="mt-2 ml-1">
                  <i size="" class="fas fa-exclamation text-danger mr-1"></i>{" "}
                  Data belum di konfirmasi
                </div>
              ) : (
                <div className="mt-2 ml-1">
                  <i class="fas fa-check text-success"></i> Data sudah di
                  konfirmasi
                </div>
              )} */}
            </div>
            {/* <div className="mt-3 text-grey">
              Menjadi artis pada {prop.item.tanggal_dijadikan_artis}
            </div> */}
          </Link>
          {prop.button ? (
            <Dropdown className="artis w-100 mt-3">
              <Dropdown.Toggle bsPrefix=" ">
                <i className="fa fa-ellipsis-h"></i>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {statePermission.permission.artis.tambah_bookmark.active ===
                1 ? (
                  <TambahBookmark data={prop.item}></TambahBookmark>
                ) : (
                  ""
                )}
                {statePermission.permission.artis.tambah_casting.active ===
                1 ? (
                  <TambahCasting data={prop.item}></TambahCasting>
                ) : (
                  ""
                )}
                {statePermission.permission.artis.tambah_ke_grade_talent_report
                  .active === 1 ? (
                  <TambahReport data={prop.item} jenis="grade"></TambahReport>
                ) : (
                  ""
                )}
                {statePermission.permission.artis.tombol_edit_biografi
                  .active === 1 ? (
                  <EditNPWP
                    fetch={() => {
                      prop.fetch();
                    }}
                    data={prop.item}
                  ></EditNPWP>
                ) : (
                  ""
                )}
              </Dropdown.Menu>
            </Dropdown>
          ) : (
            ""
          )}
        </div>
      </Card.Body>
    </Card>
  );
}
export default ArtisCard;
