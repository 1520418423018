import React, { useContext, useState, useEffect } from "react";

import { Button, Image, Row, Col } from "react-bootstrap";
import { Card, Badge } from "tabler-react";
import { Link } from "react-router-dom";
import { AiFillNotification, AiFillGold } from "react-icons/ai";
import EditJudul from "../../Pages/Judul/Modal/EditJudul";
import { ContextPermission } from "../../Context/Permission";
import AlertDelete from "../Modal/AlertDelete";
import EditInfoJudul from "../../Pages/Judul/Modal/EditInfoJudul";

function CardJudul(props) {
  const statePermission = useContext(ContextPermission);
  const [isBaganActive, setIsBaganActive] = useState(false);
  const [isCastingActive, setIsCastingActive] = useState(false);

  const style = {
    cardImage: {
      width: "120px",
      height: "160px",
      objectFit: "cover",
      objectPosition: "100%, 0",
      borderRadius: "4px",
    },
    cursorPointer: {
      cursor: "pointer",
    },
  };

  const checkIfAnyActiveIsOne = (data) => {
    const dataArray = Object.values(data);
    const hasActiveOne = dataArray.some((item) => item.active === 1);
    return hasActiveOne;
  };

  useEffect(() => {
    const isBaganActive = checkIfAnyActiveIsOne(
      statePermission.permission.bagan
    );
    const isCastingActive = checkIfAnyActiveIsOne(
      statePermission.permission.casting
    );

    setIsBaganActive(isBaganActive);
    setIsCastingActive(isCastingActive);
  }, [statePermission]);

  const BaganLink = () => {
    if (isBaganActive) {
      return "/bagan/" + props.dataJudul.id;
    }
    return "#";
  };

  const CastingLink = () => {
    if (isCastingActive) {
      return "/casting/" + props.dataJudul.id;
    }
    return "#";
  };
  return (
    <Card className="card-rounded flex flex-row">
      <Card.Body className="p-0 ">
        <Row>
          <Col md="7">
            <div className="p-3">
              <a
                href={`${process.env.REACT_APP_FRONTEND_URL}${props.link.path}/${props.dataJudul.id}`}
              >
                <h5 className="font-weight-bold">
                  {props.dataJudul.title_old &&
                  props.dataJudul.title_old.toLowerCase() !=
                    props.dataJudul.title.toLowerCase()
                    ? props.dataJudul.title_old + " - "
                    : ""}
                  {props.dataJudul.title}
                </h5>
                <div>Sutradara</div>
                <div>
                  <span className="font-weight-bold">
                    {props.dataJudul.director ? props.dataJudul.director : "-"}
                  </span>
                </div>
                <div>Produser</div>
                <div>
                  <span className="font-weight-bold">
                    {props.dataJudul.producer ? props.dataJudul.producer : "-"}
                  </span>
                </div>
                <div>Cerita & Skenario</div>
                <div>
                  <span className="font-weight-bold">
                    {props.dataJudul.writer ? props.dataJudul.writer : "-"}
                  </span>
                </div>
              </a>
            </div>
          </Col>
          <Col md="5" className="mt-3">
            {/* <a href={(props.link.url==="pm"?`${process.env.REACT_APP_FRONTEND_PM_URL}${props.dataJudul.id}/${props.link.path}`:`${process.env.REACT_APP_FRONTEND_URL}${props.link.path}/${props.dataJudul.id}`)}> */}
            <Link to={BaganLink}>
              <Image
                src={props.dataJudul.title_cover}
                style={style.cardImage}
              />
            </Link>
          </Col>
          <Col md="12" className="p-3">
            <div className=" border-top">
              <Row>
                <Col sm="5" className="d-flex mt-3">
                  <Badge color="light" className="mx-2 p-2 text-danger">
                    {props.dataJudul.categories[0].category_name}
                  </Badge>
                  <Badge
                    color={
                      props.dataJudul.status === "Persiapan"
                        ? "primary"
                        : props.dataJudul.status === "Sedang Berjalan"
                        ? "warning"
                        : "success"
                    }
                    className="p-2"
                  >
                    {props.dataJudul.status}
                  </Badge>
                </Col>
              </Row>
            </div>
          </Col>

          <Col md="auto" style={{ width: "100%" }}>
            <div
              className="p-3 d-flex justify-content-end"
              style={{ width: "100%" }}
            >
              <Row>
                {statePermission.permission.judul.edit_judul.active === 1 &&
                props.dataJudul.enabled_edit ? (
                  <>
                    {props.dataJudul.status !== "Perencanaan" && (
                      <EditInfoJudul id={props.dataJudul.erp_id} />
                    )}
                    <EditJudul
                      id={props.dataJudul.id}
                      title={props.title}
                      sutradara={props.sutradara}
                      produser={props.produser}
                      skenario={props.skenario}
                      castingTeam={props.castingTeam}
                    />
                  </>
                ) : (
                  ""
                )}
                {isBaganActive ? (
                  <Link to={BaganLink}>
                    <Button
                      variant="light"
                      className="mr-1 d-flex justify-content-between align-items-center"
                    >
                      <AiFillGold className="mr-2" size={16} /> Bagan
                    </Button>
                  </Link>
                ) : null}
                {isCastingActive ? (
                  <Link to={CastingLink}>
                    <Button
                      variant="light"
                      className="mr-1 d-flex justify-content-between align-items-center"
                    >
                      <AiFillNotification className="mr-2" size={16} /> Casting
                    </Button>
                  </Link>
                ) : null}
              </Row>
            </div>
          </Col>
        </Row>
      </Card.Body>
      {props.dataJudul.status === "Perencanaan" &&
      statePermission.permission.judul?.hapus_judul?.active === 1 ? (
        <AlertDelete name={props.dataJudul.title} id={props.dataJudul.id} />
      ) : (
        ""
      )}
    </Card>
  );
}
export default CardJudul;
