import React, { useState, useEffect, useContext } from "react";
import { Modal, Button, Dropdown } from "react-bootstrap";
import { FormTextInput, Form } from "tabler-react";
import axios from "axios";
import Loader from "react-loader-spinner";
import { ContextAlert } from "../../Context/Alert";

function TambahReport(prop) {
  const urlMicroservice = process.env.REACT_APP_BE_DBTALENT_URL;
  const token = localStorage.getItem("token");
  const [show, setShow] = useState([false, false]);
  const handleClose = () => setShow([false, false]);
  const handleShow = () => {
    setShow([true, false]);
    setLoader(true);
  };
  const handleCloseSecond = () => setShow([true, false]);
  const handleShowSecond = () => {
    setShow([false, true]);
    setDisabled(true);
  };
  const [dataValue, setDataValue] = useState([]);
  const [selected, setSelected] = useState([]);
  const [disabled, setDisabled] = useState(true);
  const [loader, setLoader] = useState(false);
  const [message, setMessage] = useContext(ContextAlert);

  const [input, setInput] = useState({
    report_name: "",
  });

  function handleChange(evt) {
    let value = evt.target.value;
    if (evt.target.name === "report_name") {
      if (value) {
        setDisabled(false);
      } else {
        setDisabled(true);
      }
    }
    setInput({
      ...input,
      [evt.target.name]: value,
    });
  }

  function handleChangeCheckbox(evt, index) {
    let value = evt.target.value;
    let check = evt.target.checked;
    if (check === true) {
      axios
        .post(
          `${urlMicroservice}talent/report/${prop.jenis}/store/report-artist/${prop.data.id}`,
          { report_id: [value] },
          {
            headers: {
              Authorization: `Bearer ${token}`,
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          setSelected((selected) => {
            return [
              ...selected.slice(0, index),
              1,
              ...selected.slice(index + 1),
            ];
          });
          setMessage({
            type: "success",
            message: `${
              prop.jenis === "prospect" ? "Kandidat" : ""
            } Artis telah ditambahkan ke file report`,
            show: "d-block",
          });
        })
        .catch((e) => {
          var message = e.response.data.message;
          if (!message) {
            message = e.message;
          }
          setMessage({
            type: "danger",
            message: JSON.stringify(message),
            show: "d-block",
          });
        });
    } else {
      axios
        .delete(
          `${urlMicroservice}talent/report/${prop.jenis}/delete-report/${value}/artist/${prop.data.id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          setSelected((selected) => {
            return [
              ...selected.slice(0, index),
              0,
              ...selected.slice(index + 1),
            ];
          });
          setMessage({
            type: "success",
            message: `${
              prop.jenis === "prospect" ? "Kandidat" : ""
            } Artis telah dihapus dari file report`,
            show: "d-block",
          });
        })
        .catch((e) => {
          var message = e.response.data.message;
          if (!message) {
            message = e.message;
          }
          setMessage({
            type: "danger",
            message: JSON.stringify(message),
            show: "d-block",
          });
        });
    }
  }

  useEffect(() => {
    if (show[0] === true) {
      axios
        .get(
          `${urlMicroservice}talent/report/${prop.jenis}/create/report-artist/${prop.data.id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          setDataValue(res.data.data);
          res.data.data &&
            res.data.data.map((item, index) => {
              setSelected((selected) => [...selected, item.selected]);
            });
          setLoader(false);
        })
        .catch((e) => {
          var message = e.response.data.message;
          if (!message) {
            message = e.message;
          }
          setMessage({
            type: "danger",
            message: JSON.stringify(message),
            show: "d-block",
          });
        });
    } else {
      setSelected([]);
      setDataValue([]);
      setInput({ report_name: "" });
    }
  }, [show]);

  function submit() {
    setDisabled(true);
    axios
      .post(`${urlMicroservice}talent/report/${prop.jenis}/store`, input, {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        handleShow();
      })
      .catch((e) => {
        var message = e.response.data.message;
        if (!message) {
          message = e.message;
        }
        setMessage({
          type: "danger",
          message: JSON.stringify(message),
          show: "d-block",
        });
      });
  }

  return (
    <>
      {prop.jenis === "grade" ? (
        <Dropdown.Item className="text-dark" onClick={handleShow}>
          <i class="bx bxs-report"></i> Tambah ke Grade Talent Report
        </Dropdown.Item>
      ) : (
        /* <Button
          variant="light"
          className="w-100 mt-2"
          type="button"
          onClick={handleShow}
        >
          Tambahkan ke {prop.jenis === "grade" ? "Grade Talent" : ""} Report
        </Button> */

        <Dropdown.Item className="text-dark" onClick={handleShow}>
          <i class="bx bxs-file"></i> Tambah ke Report
        </Dropdown.Item>
        // <span onClick={handleShow} style={{cursor:"pointer"}}><i class="bx bxs-file"></i> Tambah ke Report</span>
      )}

      <Modal show={show[0]} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            Tambah ke File {prop.jenis === "grade" ? "Grade Talent" : ""} Report
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="my-2">
          {loader === true ? (
            <Loader
              visible={loader}
              type="ThreeDots"
              color="#ED1C24"
              height="100"
              width="100"
            />
          ) : (
            <Form.Group>
              {dataValue &&
                dataValue.map((item, index) => {
                  return (
                    <Form.Checkbox
                      name="file"
                      label={item.report_name}
                      value={item.id}
                      className="mb-3"
                      checked={selected[index] === 1 ? true : false}
                      onChange={(evt) => handleChangeCheckbox(evt, index)}
                    />
                  );
                })}
            </Form.Group>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-danger" onClick={handleShowSecond}>
            <i className="bx bx-plus"></i> Buat File Baru
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={show[1]} onHide={handleCloseSecond}>
        <Modal.Header closeButton>
          <Modal.Title>Buat File Baru</Modal.Title>
        </Modal.Header>
        <Modal.Body className="my-2">
          <FormTextInput
            name="report_name"
            label="Nama File"
            placeholder="Masukkan Nama File"
            value={input.report_name}
            onChange={handleChange}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-danger" onClick={handleCloseSecond}>
            Batal
          </Button>
          <Button variant="danger" onClick={() => submit()} disabled={disabled}>
            Buat
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default TambahReport;
