import React, { useState, useEffect } from "react";
import { Modal, Button, Image } from "react-bootstrap";
import { Grid, Card, Button as Buttons } from "tabler-react";
import jsPDF from "jspdf";
import axios from "axios";
import html2canvas from "html2canvas-objectfit-fix";
import imageToBase64 from "image-to-base64/browser";

const INITIAL_LIST = Array.from({ length: 5 }, () => null);

function BuatComposite(prop) {
  const [show, setShow] = useState(false);
  const [dataImage, setDataImage] = useState([]);
  const token = localStorage.getItem("token");
  const [loading, setLoading] = useState(false);
  const urlMicroservice = process.env.REACT_APP_BE_DBTALENT_URL;

  const handleClose = () => setShow(false);
  const handleShow = (fotoBase64) => {
    setShow(true);
    setDataImage([]);

    const sortCompositeSelected = prop.dataComposite.sort((a, b) => a.index - b.index);
    const result = sortCompositeSelected.map(val => {
      if (val) {
        const foto = fotoBase64.find(item => item.id === val.item.id);
        if (foto) {
          return foto.foto;
        }
        return val.foto
      }
      
    });
    setDataImage(result);
  };

  const handleCloseAll = () => {
    prop.handleClose();
    setShow(false);
  };

  const getFotoBase64 = () => {
    setLoading(true)
    axios
      .get(`${urlMicroservice}talent/artis/foto/foto-base64?artist_id=${prop.dataComposite[0].item.artist_id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        handleShow(res.data.data);
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
        var message = e.response.data.message;
        if (!message) {
          message = e.message;
        }
        setMessage({
          type: "danger",
          message: JSON.stringify(message),
          show: "d-block",
        });
      });
  };
  
  function printDocument() {
    const input = document.getElementById("custom-width-modal");
    html2canvas(input, { backgroundColor: null }).then((canvas) => {
      // var a = document.createElement('a');
      // a.href = canvas.toDataURL("image/jpeg").replace("image/jpeg", "image/octet-stream");
      // a.download = 'CHELSEA ISLAN - TALENT COMPOSITE.jpg';
      // a.click();

      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF({
        orientation: "landscape",
        unit: "in",
        format: [9, 8.25],
      });
      pdf.addImage(imgData, "JPEG", 0, 0);
      pdf.save(prop.item.nama_artis + " - TALENT COMPOSITE.pdf");
      handleCloseAll();
    });
  }
  
  return (
    <>
      <Buttons
        className="w-25"
        type="button"
        color="danger"
        onClick={() => getFotoBase64()}
        disabled={prop.disabled || loading}
        loading={loading}>
        <i class="bx bx-images"></i> Buat Composite
      </Buttons>
      <Modal
        show={show}
        onHide={handleClose}
        size="lg"
        className="custom-width-modal"
      >
        <Modal.Body>
          <Grid.Row className="mb-5 mt-3">
            <Grid.Col width="6">
              <h5 className="font-weight-bold">Hasil Composite</h5>
            </Grid.Col>
            <Grid.Col width="6" className="text-right">
              <Button
                type="button"
                variant="light"
                className="close p-1"
                onClick={handleCloseAll}
              ></Button>
            </Grid.Col>
            <Grid.Col width="12">
              <div id="custom-width-modal">
                <Card className="mt-5">
                  <Card.Body>
                    <Grid.Row className="my-3">
                      <Grid.Col width="6">
                        <Grid.Row className="mt-3">
                          <Grid.Col width="12">
                            <Image
                              src={dataImage[0] ? dataImage[0] : ""}
                              className="img-composite-primary"
                            ></Image>
                          </Grid.Col>
                          <Grid.Col width="12" className="mt-5">
                            <Image
                              src="/images/logo.png"
                              className="img-composite-logo"
                            ></Image>
                          </Grid.Col>
                          <Grid.Col width="12" className="mt-3">
                            <span className="font-16">TALENT COMPOSITE</span>
                          </Grid.Col>
                        </Grid.Row>
                      </Grid.Col>
                      <Grid.Col width="6">
                        <Grid.Row className="mt-3">
                          <Grid.Col width="6" className="pr-1">
                            <Image
                              src={dataImage[1] ? dataImage[1] : ""}
                              className="img-composite-secondary"
                            ></Image>
                          </Grid.Col>
                          <Grid.Col width="6" className="pl-1">
                            <Image
                              src={dataImage[2] ? dataImage[2] : ""}
                              className="img-composite-secondary"
                            ></Image>
                          </Grid.Col>
                          <Grid.Col width="6" className="pr-1 pt-2">
                            <Image
                              src={dataImage[3] ? dataImage[3] : ""}
                              className="img-composite-secondary"
                            ></Image>
                          </Grid.Col>
                          <Grid.Col width="6" className="pl-1 pt-2">
                            <Image
                              src={dataImage[4] ? dataImage[4] : ""}
                              className="img-composite-secondary"
                            ></Image>
                          </Grid.Col>
                          <Grid.Col width="12" className="mt-5">
                            <h4 className="font-weight-bold text-uppercase">
                              {prop.item.nama_artis}
                            </h4>
                            <div className="my-3">
                              {prop.item.tanggal_lahir}
                              <span className="px-2">&bull;</span>
                              {prop.item.tinggi} cm
                              <span className="px-2">&bull;</span>
                              {prop.item.berat} kg
                            </div>
                            <div>{prop.item.manajemen}</div>
                          </Grid.Col>
                        </Grid.Row>
                      </Grid.Col>
                    </Grid.Row>
                  </Card.Body>
                </Card>
              </div>
            </Grid.Col>
          </Grid.Row>
          <Grid.Row className="mb-2">
            <Grid.Col width="12" className="text-right">
              <Button
                variant="outline-danger"
                className="px-5 mr-3"
                onClick={handleClose}
              >
                Buat Ulang
              </Button>
              <Button variant="danger" className="px-5" onClick={printDocument}>
                <i class="bx bxs-file-pdf"></i>Simpan Sebagai PDF
              </Button>
            </Grid.Col>
          </Grid.Row>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default BuatComposite;
