import React, { createContext, useState } from "react";

const initialState = {
  type: "",
  message: "",
  show: "d-none",
};

const Alert = ({ children }) => {
  const [state, dispatch] = useState(initialState);
  return (
    <ContextAlert.Provider value={[state, dispatch]}>
      {children}
    </ContextAlert.Provider>
  );
};

export const ContextAlert = createContext(initialState);
export default Alert;
