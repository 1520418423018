import React, { useState, useEffect, useContext } from "react";
import { Card, Badge, Form, Grid } from "tabler-react";
import { Button, Image } from "react-bootstrap";
import { Tabs } from "antd";
import {
  DiagramComponent,
  NodeConstraints,
  ConnectorConstraints,
  NodeModel,
} from "@syncfusion/ej2-react-diagrams";
import { Link } from "react-router-dom";
import axios from "axios";
import Loader from "react-loader-spinner";

import "./DetailBagan.css";
import SiteWrapper from "../../../../Layouts/LayoutManajemen/SiteWrapper";
import FinalBagan from "../../../../Components/Bagan/DetailBagan/FinalBaganComponent";
import HapusFinalBagan from "../../../../Components/Bagan/DetailBagan/HapusFinalBaganComponent";
import { ContextPermission } from "../../../../Context/Permission";
import { ContextAlert } from "../../../../Context/Alert";

const { TabPane } = Tabs;

function DetailBagan(prop) {
  const urlMicroservice = process.env.REACT_APP_BE_DBTALENT_URL;
  const token = localStorage.getItem("token");
  const [loader, setLoader] = useState(true);
  const [submit, setSubmit] = useState(true);
  const [bagan, setBagan] = useState({
    id: prop.match.params.id,
    title_id: "",
    bagan_name: "",
    status: "",
    comments: [],
    bagan: [],
    bagan_sp: [],
    diagram: "",
  });
  const [baganData, setBaganData] = useState([]);
  const [input, setInput] = useState({
    comment: "",
  });
  const [inputReply, setInputReply] = useState([
    { reply: "", comment_id: "", show: false },
  ]);
  const [node, setNode] = useState([]);
  const [connector, setConnector] = useState([]);
  const statePermission = useContext(ContextPermission);
  const [message, setMessage] = useContext(ContextAlert);
  let diagramInstance;

  const castingImage = (
    nama_peran,
    talent
  ) => `<div class="text-center font-weight-bold">
          <div class="d-flex">
            ${
              talent
                ? talent
                : '<div class="casting-bagan-component ml-2"><i class="bx bxs-user"></i></div>'
            }
          </div>
          <div class="pt-2 font-14">${nama_peran}</div>
        </div>`;

  function onItemClick(args) {
    switch (args) {
      case "zoom-in":
        let zoomin = { type: "ZoomIn", zoomFactor: 0.2 };
        diagramInstance.zoomTo(zoomin);
        break;
      case "zoom-out":
        let zoomout = { type: "ZoomOut", zoomFactor: 0.2 };
        diagramInstance.zoomTo(zoomout);
        break;
    }
  }

  function handleChange(evt) {
    let value = evt.target.value;
    setInput({
      ...input,
      [evt.target.name]: value,
    });
  }

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const reply = [...inputReply];
    reply[index][name] = value;
    setInputReply(reply);
  };

  const handleReply = (index) => {
    const reply = [...inputReply];
    reply[index]["show"] = true;
    setInputReply(reply);
  };

  const handleSubmit = (type, index) => {
    var url = `${urlMicroservice}talent/bagan/comment/store/${bagan.id}`;
    if (type === 1) {
      url = `${urlMicroservice}talent/bagan/comment/reply/store/${inputReply[index].comment_id}`;
    }
    setSubmit(false);
    axios
      .post(url, type === 0 ? input : inputReply[index], {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        setSubmit(true);
        setInput({ comment: "" });
      })
      .catch(function (e) {
        setSubmit(true);
        setInput({ comment: "" });
        var message = e.response.data.message;
        if (!message) {
          message = e.message;
        }
        setMessage({
          type: "danger",
          message: JSON.stringify(message),
          show: "d-block",
        });
      });
  };

  type breadcrumbItem = {|
    +value: string,
    +to?: string,
    +active?: boolean,
  |};

  const breadcrumbItems: Array<breadcrumbItem> = [
    {
      value: "Bagan",
      to: `/bagan/${bagan.title_id}`,
      active: true,
    },
    {
      value: bagan.bagan_name,
      to: `/bagan/detail/${bagan.id}`,
      active: false,
    },
  ];

  useEffect(() => {
    if (submit === true) {
      axios
        .get(`${urlMicroservice}talent/bagan/detail/${prop.match.params.id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        })
        .then((res) => {})
        .catch((e) => {
          var message = e.response.data.message;
          if (!message) {
            message = e.message;
          }
          setMessage({
            type: "danger",
            message: JSON.stringify(message),
            show: "d-block",
          });
        });
      axios
        .get(`${urlMicroservice}talent/bagan/detail/${prop.match.params.id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          setBagan({
            id: res.data.data.bagan.id,
            title_id: res.data.data.bagan.title_id,
            status: res.data.data.bagan.status,
            bagan_name: res.data.data.bagan.bagan_name,
            comments: res.data.data.bagan.comments,
            bagan: res.data.data.bagan.shapes,
            bagan_sp: res.data.data.bagan_sp,
            diagram: res.data.data.bagan.diagram,
          });
          setBaganData(res.data.data.bagan.shapes);
          setLoader(false);
        })
        .catch((e) => {
          var message = e.response.data.message;
          if (!message) {
            message = e.message;
          }
          setMessage({
            type: "danger",
            message: JSON.stringify(message),
            show: "d-block",
          });
        });
    }
  }, [submit]);

  useEffect(() => {
    setInputReply(
      bagan.comments.map((comment) => ({
        reply: "",
        comment_id: comment.id,
        show: false,
      }))
    );
  }, [bagan.comments]);

  useEffect(() => {
    if (baganData) {
      let oldId = [];
      baganData &&
        baganData.map((item, index) => {
          oldId.push(item.id);
          if (item.is_selected !== 0) {
            let talent = "";
            let newCastingImage = castingImage(item.casting.nama_peran, null);
            let width = 95;

            var res = item.detail_shapes.filter((val) => {
              return val.is_selected == 1;
            });
            if (res.length > 0) {
              width = 0;
            }

            if (item.detail_shapes.some((data) => data.is_selected == 1)) {
              item.detail_shapes &&
                item.detail_shapes.map((subItem, subIndex) => {
                  if (subItem.is_selected == 1) {
                    width += 90;
                    talent += `<div class="casting-bagan-component ml-2">
                                  <img src="${subItem.casting_talent.artist.image}">
                              </div>
                            `;
                  }
                });
              newCastingImage = castingImage(item.casting.nama_peran, talent);
            }

            if (item.old_id) {
              var newIndex = diagramInstance.nodes.findIndex((p) =>
                p.addInfo ? p.addInfo.id == item.old_id : false
              );

              if(newIndex === -1){
                var newIndex = diagramInstance.nodes.findIndex((p) =>
                  p.addInfo ? p.addInfo.id == item.id : false
                );
              }
              if (newIndex !== -1) {
                diagramInstance.nodes[newIndex].addInfo.id = item.id;
                diagramInstance.nodes[newIndex].addInfo.index = index;
              }
              item.old_id = null;
            } else {
              var newIndex = diagramInstance.nodes.findIndex((p) =>
                p.addInfo ? p.addInfo.id == item.id : false
              );
            }

            if (newIndex !== -1) {
              diagramInstance.nodes[newIndex].shape = {
                type: "HTML",
                content: newCastingImage,
              };
              diagramInstance.nodes[newIndex].width = width;
            }
          }
        });

      let oldIdDiagram = [];
      if (oldId.length > 0) {
        diagramInstance.nodes.map((item2, index2) => {
          if (item2.addInfo) {
            if (!oldId.includes(item2.addInfo.id)) {
              item2.addInfo.jenis = "old";
              oldIdDiagram.push(item2.addInfo.id);
            }
          }
        });
      }

      oldIdDiagram.map((item3, index3) => {
        var oldIndex = diagramInstance.nodes.findIndex((p) =>
          p.addInfo ? p.addInfo.id == item3 : false
        );
        diagramInstance.remove(diagramInstance.nodes[oldIndex]);
      });
    }
  }, [baganData]);

  useEffect(() => {
    var baganNew = bagan.diagram;
    if (!baganNew) {
      baganNew = { width: "100%", height: "720" };
    }
    diagramInstance.loadDiagram(JSON.stringify(baganNew));
  }, [bagan.diagram]);

  const status_bagan = (status) => {
    if (status === 1) {
      return (
        <Badge color="light" className="text-dark px-3 py-1 font-14px mr-2">
          Draft
        </Badge>
      );
    } else if (status === 2) {
      return (
        <Badge
          color="outline-primary"
          className="text-dark px-3 py-1 font-14px mr-2"
        >
          Highlight
        </Badge>
      );
    } else {
      return (
        <Badge
          color="outline-success"
          className="text-dark px-3 py-1 font-14px mr-2"
        >
          Final
        </Badge>
      );
    }
  };

  return (
    <SiteWrapper
      breadcrumbItems={breadcrumbItems}
      title_id={bagan.title_id}
      title={bagan.bagan_name}
      linkBack={`/bagan/${bagan.title_id}`}
    >
      <Loader
        visible={loader}
        className="loaders"
        type="ThreeDots"
        color="#ED1C24"
        height="100"
        width="100"
      />
      <Card className="border-card mt-5">
        <Card.Body className="d-flex justify-content-between align-items-center">
          <div>Status: {status_bagan(bagan.status)}</div>
          <div className="w-75">
            <div className="d-flex justify-content-end align-items-center">
              <div className="bg-light mr-2 border">
                <Button
                  variant="none"
                  type="button"
                  className="border"
                  onClick={() => onItemClick("zoom-out")}
                >
                  <i className="bx bx-zoom-out font-16"></i>
                </Button>
                <Button
                  variant="none"
                  type="button"
                  className="border"
                  onClick={() => onItemClick("zoom-in")}
                >
                  <i className="bx bx-zoom-in font-16"></i>
                </Button>
              </div>
              {statePermission.permission.bagan.atur_bagan.active === 1 ? (
                <>
                  {bagan.status === 3 ? (
                    <HapusFinalBagan id={bagan.id}></HapusFinalBagan>
                  ) : (
                    <>
                      <Link to={"/bagan/edit/" + bagan.id}>
                        <Button variant="outline-danger" className="mr-3">
                          {" "}
                          Atur Bagan{" "}
                        </Button>
                      </Link>
                      <FinalBagan id={bagan.id}></FinalBagan>
                    </>
                  )}
                </>
              ) : (
                ""
              )}
            </div>
          </div>
        </Card.Body>
      </Card>
      <Card className="border-card mt-5">
        <Card.Body className="pb-0">
          <Tabs defaultActiveKey="1" className="pb-0">
            <TabPane tab="Pemain Kontrak" key="1">
              <div className="p-0">
                <Grid.Row>
                  <Grid.Col width="9">
                    <DiagramComponent
                      ref={(diagram) => (diagramInstance = diagram)}
                      id="diagram"
                      width={"100%"}
                      height={"550px"}
                      getNodeDefaults={(symbol: NodeModel): void => {
                        setTimeout(function () {
                          symbol.constraints = NodeConstraints.Select;
                        }, 500);
                      }}
                      getConnectorDefaults={(symbol: ConnectorModel): void => {
                        symbol.constraints = ConnectorConstraints.Select;
                      }}
                    />
                  </Grid.Col>
                  <Grid.Col width="3" className="pt-5 casting-scroll">
                    {bagan.bagan.length > 0 &&
                    bagan.bagan.some((data) => data.is_selected == 1) ? (
                      bagan.bagan &&
                      bagan.bagan.map((item) => {
                        if (item.is_selected === 1) {
                          return (
                            <div className="bagan-pemeran mb-5">
                              <div className="text-grey mb-2">
                                Pemeran {item.casting.nama_peran}
                              </div>
                              {item.detail_shapes ? (
                                item.detail_shapes.map((subItem) => {
                                  if (subItem.is_selected === 1) {
                                    return (
                                      <div className="mb-2">
                                        <Image
                                          src={
                                            subItem.casting_talent.artist.image
                                          }
                                        ></Image>
                                        <span className="font-weight-bold font-16 ml-4">
                                          {
                                            subItem.casting_talent.artist
                                              .nama_artis
                                          }
                                        </span>
                                      </div>
                                    );
                                  }
                                })
                              ) : (
                                <div className="null">Belum Ada Talent</div>
                              )}
                            </div>
                          );
                        }
                      })
                    ) : (
                      <div className="bagan-pemeran mb-5">
                        <div className="null">Belum Ada Casting</div>
                      </div>
                    )}
                  </Grid.Col>
                </Grid.Row>
              </div>
            </TabPane>
            <TabPane tab="Pemain SP" key="2">
              <div className="mt-4">
                {bagan.bagan_sp &&
                  bagan.bagan_sp.map((item) => {
                    if (
                      item.sp_cast_details &&
                      item.sp_cast_details.some((data) => data.is_selected == 1)
                    ) {
                      return (
                        <div className="mb-5">
                          <div className="text-grey mb-2">
                            {item.casting.nama_peran}
                          </div>
                          <div className="bagan-sp">
                            {item.sp_cast_details.map((subItem) => {
                              if (subItem.is_selected == 1) {
                                return (
                                  <div className="mb-2 list-sp">
                                    <Image
                                      src={subItem.casting_talent.artist.image}
                                      className="rounded"
                                    ></Image>
                                    <div className="font-weight-bold mt-2 text-center">
                                      {subItem.casting_talent.artist.nama_artis}
                                    </div>
                                  </div>
                                );
                              }
                            })}
                          </div>
                        </div>
                      );
                    } else {
                      return (
                        <div className="bagan-pemeran mb-5">
                          <div className="text-grey mb-2">
                            {item.casting.nama_peran}
                          </div>
                          <div className="null">Belum Ada Talent</div>
                        </div>
                      );
                    }
                  })}
              </div>
            </TabPane>
          </Tabs>
        </Card.Body>
      </Card>
      <Card className="border-card mt-5">
        <Card.Body>
          <h4 className="font-weight-bold">Komentar</h4>
          {statePermission.permission.bagan.komentar.active === 1 ? (
            <Form.InputGroup className="mr-5 my-4">
              <Form.Input
                name="comment"
                placeholder="Tulis Komentar"
                value={input.comment}
                onChange={handleChange}
              />
              <Form.InputGroupAppend>
                <Form.InputGroupText className="p-0">
                  <Button
                    variant="danger"
                    className="h-100"
                    type="button"
                    onClick={() => handleSubmit(0)}
                  >
                    Submit
                  </Button>
                </Form.InputGroupText>
              </Form.InputGroupAppend>
            </Form.InputGroup>
          ) : (
            ""
          )}
          {bagan.comments &&
            bagan.comments.map((item, i) => {
              return (
                <div>
                  <div className="d-flex">
                    <Image
                      src={item.user_photo}
                      className="komentar-image mr-3"
                    ></Image>
                    <div className="w-100">
                      <div className="font-weight-bold">{item.user_name}</div>
                      <div className="my-2">{item.comment}</div>
                      <div className="text-grey font-12">
                        {item.dibuat_pada}
                      </div>
                      {item.replies &&
                        item.replies.map((subItem) => {
                          return (
                            <div className="d-flex mt-3">
                              <Image
                                src={subItem.user_photo}
                                className="komentar-image mr-3"
                              ></Image>
                              <div>
                                <div className="font-weight-bold">
                                  {subItem.user_name}
                                </div>
                                <div className="my-2">{subItem.reply}</div>
                                <div className="text-grey font-12">
                                  {subItem.dibuat_pada}
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      {statePermission.permission.bagan.komentar.active ===
                      1 ? (
                        inputReply[i] ? (
                          inputReply[i]["show"] === false ? (
                            <Button
                              variant="none"
                              className="text-danger px-0 my-3"
                              onClick={() => handleReply(i)}
                            >
                              <i className="bx bx-reply font-18"></i> Balas
                              Komentar
                            </Button>
                          ) : (
                            <Form.InputGroup className="mr-5 my-4">
                              <Form.Input
                                name="reply"
                                placeholder="Tulis Komentar"
                                value={inputReply[i]["reply"]}
                                onChange={(e) => handleInputChange(e, i)}
                              />
                              <Form.InputGroupAppend>
                                <Form.InputGroupText className="p-0">
                                  <Button
                                    variant="danger"
                                    className="h-100"
                                    type="button"
                                    onClick={() => handleSubmit(1, i)}
                                  >
                                    Submit
                                  </Button>
                                </Form.InputGroupText>
                              </Form.InputGroupAppend>
                            </Form.InputGroup>
                          )
                        ) : (
                          ""
                        )
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <hr></hr>
                </div>
              );
            })}
        </Card.Body>
      </Card>
    </SiteWrapper>
  );
}

export default DetailBagan;
