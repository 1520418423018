import React, { useState, useRef, useEffect } from "react";
import { Container, Button, Image, Navbar } from "react-bootstrap";
import { Card, Grid, Form, FormTextInput } from "tabler-react";
import { Link, useHistory } from "react-router-dom";
import { DatePicker } from "antd";
import axios from "axios";
import moment from "moment";
import Select from "react-select";

import AlertCustom from "../../Components/Modal/AlertCustomComponent";
import KarakterDropdown from "../../Components/DropDown/KarakterDropdown";

function EditBiografi(prop) {
  const history = useHistory();
  const roleUser = localStorage.getItem("role");
  const EmailUser = localStorage.getItem("email");

  const urlMicroservice = process.env.REACT_APP_BE_DBTALENT_URL;
  const token = localStorage.getItem("token");
  const loginUrl = process.env.REACT_APP_LOGIN_TOKEN_URL;
  const inputFile = useRef(null);
  const [dataTalentProspect, setDataTalentProspect] = useState([]);
  const [dataBank, setDataBank] = useState([]);
  const [message, setMessage] = useState({
    type: "",
    message: "",
    show: "d-none",
  });

  const jenis_film = (value) => {
    let data = ["None", "Film TV", "Iklan", "TV Series", "Layar Lebar"];
    return data[value - 1];
  };

  const peran = (value) => {
    let data = [
      "None",
      "Peran Utama",
      "P. Pembantu Utama",
      "Peran Pembantu",
      "Figuran",
    ];
    return data[value - 1];
  };

  const [image, setImage] = useState("/images/avatar.png");
  const [disable, setDisable] = useState(false);
  const [input, setInput] = useState({
    foto: "",
    id: prop.match.params.id,
    nama_depan: "",
    nama_belakang: "",
    nama_artis: "",
    gender: "",
    tempat_lahir: "",
    tanggal_lahir: moment(),
    agama: "",
    tinggi: "",
    berat: "",
    status_pernikahan: "",
    attitude: "",
    remark: "",
    group: "",
    artis_sinemart: 0,
    tanggal_casting: moment(),
    nilai_casting: "",
    grade: "",
    peran: "",
    karakter: "",
    talent_prospect: [],
    alamat: "",
    region: "",
    email: "",
    fax: "",
    telepon_1: "",
    // telepon_2: "",
    hp_1: "",
    // hp_2: "",
    person_in_charge: "",
    manajer: "",
    telepon_manajer: "",
    manajemen: "",
    telepon_manajemen_1: "",
    // telepon_manajemen_2: "",
    agency: "",
    telepon_agency_1: "",
    // telepon_agency_2: "",
    nama_ktp: "",
    id_ktp: "",
    erp_id: "",
    flag_data_minimalis: 0,
    is_exclusive: 0,
    talent_team: "",
    talent_team_name: "",
    category_casting_id: "",
    category_casting_name: "",
  });

  const [inputNpwp, setInputNpwp] = useState([
    { name: "", number: "", alamat_npwp: "" },
  ]);

  const [inputRekening, setInputRekening] = useState([
    { name: "", number: "" },
  ]);
  const [dataCastingTeam, setDataCastingTeam] = useState([]);
  const [categoryCasting, setCategoryCasting] = useState([]);

  const [inputPengalaman, setInputPengalaman] = useState([
    {
      pengalaman_judul: "",
      pengalaman_tahun: "",
      pengalaman_jenis_film: "",
      pengalaman_peran: "",
    },
  ]);

  const [talentProsepect, setTalentProspect] = useState([]);
  const [bank, setBank] = useState([]);

  const colourStyles: StylesConfig<ColourOption, true> = {
    multiValue: (styles, { data }) => {
      return {
        ...styles,
        color: "#EB4B56",
        backgroundColor: "#FFEBEC",
      };
    },
    multiValueLabel: (styles, { data }) => ({
      ...styles,
      color: "#EB4B56",
    }),
  };

  useEffect(() => {
    axios
      .get(`${urlMicroservice}talent/category-casting`, {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        setCategoryCasting(
          res.data.data &&
            res.data.data.map((item) => {
              return {
                value: item.id,
                label: item.name,
              };
            })
        );
      })
      .catch((e) => {
        var message = e.response.data.message;
        if (!message) {
          message = e.message;
        }
        setMessage({
          type: "danger",
          message: JSON.stringify(message),
          show: "d-block",
        });
      });

    axios
      .get(`${urlMicroservice}management/team-talent`, {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        let datas = [];

        if (Array.isArray(res.data.data) && res.data.data.length > 0) {
          datas = res.data.data.map((items) => ({
            value: items.talent_team,
            label: items.talent_team_name,
          }));
        }

        setDataCastingTeam(datas);
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 401) {
            window.open(loginUrl, "_parent");
          }
        }
      });

    axios
      .get(`${urlMicroservice}talent/talent-prospect`, {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        setTalentProspect(
          res.data.data &&
            res.data.data.map((item) => {
              return {
                value: item.id,
                label: item.nama,
              };
            })
        );
      })
      .catch((e) => {
        var message = e.response.data.message;
        if (!message) {
          message = e.message;
        }
        setMessage({
          type: "danger",
          message: JSON.stringify(message),
          show: "d-block",
        });
      });

    axios
      .get(`${urlMicroservice}talent/bank`, {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        setBank(
          res.data.data &&
            res.data.data.map((item) => {
              return {
                value: item.id,
                label: "(" + item.kode + ") " + item.nama,
              };
            })
        );
      })
      .catch((e) => {
        var message = e.response.data.message;
        if (!message) {
          message = e.message;
        }
        setMessage({
          type: "danger",
          message: JSON.stringify(message),
          show: "d-block",
        });
      });

    axios
      .get(`${urlMicroservice}talent/kandidat-artis/show?id=${input.id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        var data = res.data.data;
        setImage(res.data.data.foto);
        setInput({
          ...input,
          nama_depan: data.nama_depan,
          nama_belakang: data.nama_belakang ? data.nama_belakang : "",
          nama_artis: data.nama_artis ? data.nama_artis : "",
          gender: data.gender ? data.gender : "",
          tempat_lahir: data.tempat_lahir ? data.tempat_lahir : "",
          tanggal_lahir: moment(data.tanggal_lahir, "DD/MM/YYYY"),
          agama: data.agama ? data.agama : "",
          tinggi: data.tinggi ? data.tinggi : "",
          berat: data.berat ? data.berat : "",
          status_pernikahan: data.status_pernikahan
            ? data.status_pernikahan
            : "",
          attitude: data.attitude ? data.attitude : "",
          remark: data.remark ? data.remark : "",
          group: data.group ? data.group : "",
          artis_sinemart: data.artis_sinemart ? data.artis_sinemart : "",
          tanggal_casting: moment(data.tanggal_casting, "DD/MM/YYYY"),
          nilai_casting: data.nilai_casting ? data.nilai_casting : "",
          grade: data.grade ? data.grade : "",
          peran: data.peran ? data.peran : "",
          karakter: data.karakter ? data.karakter : "",
          alamat: data.alamat ? data.alamat : "",
          region: data.region ? data.region : "",
          email: data.email ? data.email : "",
          fax: data.fax ? data.fax : "",
          telepon_1: data.telepon_1 ? data.telepon_1 : "",
          // telepon_2: data.telepon_2,
          hp_1: data.hp_1 ? data.hp_1 : "",
          // hp_2: data.hp_2,
          person_in_charge: data.person_in_charge ? data.person_in_charge : "",
          manajer: data.manajer ? data.manajer : "",
          telepon_manajer: data.telepon_manajer ? data.telepon_manajer : "",
          manajemen: data.manajemen ? data.manajemen : "",
          telepon_manajemen_1: data.telepon_manajemen_1
            ? data.telepon_manajemen_1
            : "",
          // telepon_manajemen_2: data.telepon_manajemen_2,
          agency: data.agency ? data.agency : "",
          telepon_agency_1: data.telepon_agency_1 ? data.telepon_agency_1 : "",
          // telepon_agency_2: data.telepon_agency_2,
          id_ktp: data.id_ktp ? data.id_ktp : "",
          nama_ktp: data.nama_ktp ? data.nama_ktp : "",
          erp_id: data.erp_id ? data.erp_id : "",
          flag_data_minimalis: data.flag_data_minimalis
            ? data.flag_data_minimalis
            : 0,
          is_exclusive: data.is_exclusive,
          talent_team: data.talent_team_id,
          talent_team_name: data.talent_team_name,
          category_casting_id: data.category_casting_id ?? 0,
          category_casting_name: data.category_casting_name,
        });

        var newTalentProspect = [];
        data.talent_prospects &&
          data.talent_prospects.map((item) => {
            newTalentProspect.push({
              value: item.id,
              label: item.nama,
            });
          });
        setDataTalentProspect(newTalentProspect);

        var newNpwp = [];
        data.taxes &&
          data.taxes.map((item) => {
            newNpwp.push({
              name: item.nama_npwp,
              number: item.npwp,
              alamat_npwp: item.alamat_npwp ? item.alamat_npwp : "-",
            });
          });
        setInputNpwp(newNpwp);

        var newRekening = [];
        var newBank = [];
        data.bank_accounts &&
          data.bank_accounts.map((item) => {
            if (item.bank) {
              newRekening.push({
                name: item.pemilik_rekening,
                number: item.nomor_rekening,
              });
              newBank.push({
                value: item.bank.id,
                label: item.bank.nama,
              });
            }
          });
        setInputRekening(newRekening);
        setDataBank(newBank);

        var newPengalaman = [];
        data.experiences &&
          data.experiences.map((item) => {
            newPengalaman.push({
              pengalaman_judul: item.judul ? item.judul : "",
              pengalaman_tahun: item.tahun,
              pengalaman_jenis_film: item.jenis_film,
              pengalaman_peran: item.peran,
            });
          });
        setInputPengalaman(newPengalaman);
      })
      .catch((e) => {
        var message = e.response.data.message;
        if (!message) {
          message = e.message;
        }
        setMessage({
          type: "danger",
          message: JSON.stringify(message),
          show: "d-block",
        });
      });
  }, []);

  const onButtonClick = () => {
    inputFile.current.click();
  };

  function handleChangeTalentCasting(evt) {
    var value = [];
    var newTalentProspect = [];
    evt &&
      evt.map((item) => {
        newTalentProspect.push({
          value: item.value,
          label: item.label,
        });
      });
    setDataTalentProspect(newTalentProspect);
    setInput({
      ...input,
      talent_prospect: value,
    });
  }

  function handleChange(evt) {
    let name = evt.target.name;
    let value = evt.target.value;
    const re = /^[0-9\b]+$/;
    let newName = [
      "tinggi",
      "berat",
      "nilai_casting",
      "fax",
      "telepon_1",
      // "telepon_2",
      "hp_1",
      // "hp_2",
      "telepon_manajer",
      "telepon_manajemen_1",
      // "telepon_manajemen_2",
      "telepon_agency_1",
      // "telepon_agency_2",
      "id_ktp",
    ];

    if (newName.includes(name)) {
      if (value === "" || re.test(value)) {
        setInput({
          ...input,
          [evt.target.name]: value,
        });
      }
    } else if (name === "flag_data_minimalis") {
      setInput({
        ...input,
        [evt.target.name]: value == 0 ? 1 : 0,
      });
    } else if (name == "talent_team") {
      const selectedItem = dataCastingTeam.find(
        (item) => item.value === evt.target.value
      );
      const label = selectedItem ? selectedItem.label : null;

      setInput({
        ...input,
        [evt.target.name]: value,
        talent_team_name: label,
      });
    } else if (name == "category_casting_id") {
      const selectedItem = categoryCasting.find(
        (item) => item.value == evt.target.value
      );
      const label = selectedItem ? selectedItem.label : null;

      setInput({
        ...input,
        [evt.target.name]: value,
        category_casting_name: label,
      });
    } else {
      if (name === "foto") {
        value = evt.target.files[0];
        setImage(URL.createObjectURL(value));
      }
      setInput({
        ...input,
        [evt.target.name]: value,
      });
    }
  }

  const handleAddNpwpFields = () => {
    const values = [...inputNpwp];
    values.push({ name: "", number: "", alamat_npwp: "" });
    setInputNpwp(values);
  };

  const handleRemoveNpwpFields = (index) => {
    const values = [...inputNpwp];
    values.splice(index, 1);
    setInputNpwp(values);
  };

  const handleInputNpwpChange = (index, event) => {
    const values = [...inputNpwp];
    if (event.target.name === "name") {
      values[index].name = event.target.value;
    } else if (event.target.name === "alamat_npwp") {
      values[index].alamat_npwp = event.target.value;
    } else {
      const re = /^[0-9\b]+$/;
      if (event.target.value === "" || re.test(event.target.value)) {
        values[index].number = event.target.value;
      }
    }
    setInputNpwp(values);
  };

  const handleAddRekeningFields = () => {
    const values = [...inputRekening];
    values.push({ name: "", number: "" });
    setInputRekening(values);
  };

  const handleRemoveRekeningFields = (index) => {
    const values = [...inputRekening];
    values.splice(index, 1);
    setInputRekening(values);
  };

  const handleInputBankChange = (index, event) => {
    const value = [...dataBank];
    value[index] = {
      value: event.value,
      label: event.label,
    };
    setDataBank(value);
  };

  const handleInputRekeningChange = (index, event) => {
    const values = [...inputRekening];
    if (event.target.name === "name") {
      values[index].name = event.target.value;
    } else {
      const re = /^[0-9\b]+$/;
      if (event.target.value === "" || re.test(event.target.value)) {
        values[index].number = event.target.value;
      }
    }
    setInputRekening(values);
  };

  const handleAddPengalamanFields = () => {
    const values = [...inputPengalaman];
    values.push({
      pengalaman_judul: "",
      pengalaman_tahun: "",
      pengalaman_jenis_film: "",
      pengalaman_peran: "",
    });
    setInputPengalaman(values);
  };

  const handleRemovePengalamanFields = (index) => {
    const values = [...inputPengalaman];
    values.splice(index, 1);
    setInputPengalaman(values);
  };

  const handleInputPengalamanChange = (index, event, name = null) => {
    const values = [...inputPengalaman];
    if (event.target) {
      if (event.target.name === "pengalaman_tahun") {
        const re = /^[0-9\b]+$/;
        if (event.target.value === "" || re.test(event.target.value)) {
          values[index].pengalaman_tahun = event.target.value;
        }
      } else if (event.target.name === "pengalaman_judul") {
        values[index].pengalaman_judul = event.target.value;
      }
    } else {
      values[index][name] = event.value;
    }
    setInputPengalaman(values);
  };

  const submit = () => {
    const data = new FormData();
    data.append("id", input.id);
    if (input.foto) {
      data.append("foto", input.foto);
    }
    data.append("nama_depan", input.nama_depan);
    data.append("nama_belakang", input.nama_belakang);
    data.append("nama_artis", input.nama_artis);
    data.append("gender", input.gender);
    data.append("tempat_lahir", input.tempat_lahir);
    data.append(
      "tanggal_lahir",
      moment(input.tanggal_lahir).format("DD/MM/YYYY")
    );
    data.append("berat", input.berat);
    data.append("tinggi", input.tinggi);
    data.append("agama", input.agama);
    data.append("attitude", input.attitude);
    data.append("artis_sinemart", input.artis_sinemart);
    data.append(
      "tanggal_casting",
      moment(input.tanggal_casting).format("DD/MM/YYYY")
    );
    data.append("nilai_casting", input.nilai_casting);
    data.append("grade", input.grade);
    data.append("alamat", input.alamat);
    data.append("telepon_1", input.telepon_1);
    // data.append("telepon_2", input.telepon_2?input.telepon_2:"");
    data.append("hp_1", input.hp_1);
    // data.append("hp_2", input.hp_2?input.hp_2:"");
    data.append("manajer", input.manajer);
    data.append("telepon_manajer", input.telepon_manajer);
    data.append("manajemen", input.manajemen);
    data.append("telepon_manajemen_1", input.telepon_manajemen_1);
    // data.append("telepon_manajemen_2", input.telepon_manajemen_2?input.telepon_manajemen_2:"");
    data.append("agency", input.agency);
    data.append("telepon_agency_1", input.telepon_agency_1);
    // data.append("telepon_agency_2", input.telepon_agency_2?input.telepon_agency_2:"");
    data.append("peran", input.peran);
    data.append("remark", input.remark);
    data.append("group", input.group);
    data.append("region", input.region);
    data.append("person_in_charge", input.person_in_charge);
    data.append("status_pernikahan", input.status_pernikahan);
    data.append("karakter", input.karakter);
    data.append("email", input.email);
    data.append("fax", input.fax);
    data.append("id_ktp", input.id_ktp);
    data.append("nama_ktp", input.nama_ktp);
    data.append("erp_id", input.erp_id);
    data.append("is_exclusive", input.is_exclusive);
    data.append(
      "talent_team_id",
      input.is_exclusive == "1" ? input.talent_team : ""
    );
    data.append(
      "talent_team_name",
      input.is_exclusive == "1" ? input.talent_team_name : ""
    );
    data.append("category_casting_id", input.category_casting_id ?? 0);
    data.append("category_casting_name", input.category_casting_name);

    inputNpwp &&
      inputNpwp.map((item) => {
        data.append("nama_npwp[]", item.name ? item.name : "");
        data.append("npwp[]", item.number ? item.number : "");
        data.append("alamat_npwp[]", item.alamat_npwp ? item.alamat_npwp : "");
      });

    dataTalentProspect &&
      dataTalentProspect.map((item) => {
        data.append("talent_prospect[]", item.value);
      });

    inputRekening &&
      inputRekening.map((item) => {
        data.append("pemilik_rekening[]", item.name);
        data.append("nomor_rekening[]", item.number);
      });

    dataBank &&
      dataBank.map((item) => {
        data.append("bank_id[]", item.value);
      });

    inputPengalaman &&
      inputPengalaman.map((item) => {
        data.append("pengalaman_judul[]", item.pengalaman_judul);
        data.append("pengalaman_tahun[]", item.pengalaman_tahun);
        data.append("pengalaman_jenis_film[]", item.pengalaman_jenis_film);
        data.append("pengalaman_peran[]", item.pengalaman_peran);
      });

    // for (var pair of data.entries()) {
    //     console.log(pair[0]+ ', '+ pair[1]);
    // }

    setDisable(true);
    axios
      .post(`${urlMicroservice}talent/kandidat-artis/update`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        setMessage({
          type: "success",
          message: `Berhasil mengubah biografi kandidat artis`,
          show: "d-block",
        });
        setTimeout(function () {
          history.push("/kandidat-artis/detail/" + prop.match.params.id);
        }, 1000);
      })
      .catch((e) => {
        setDisable(false);
        var message = e.response.data.message;
        if (!message) {
          message = e.message;
        }
        setMessage({
          type: "danger",
          message: JSON.stringify(message),
          show: "d-block",
        });
      });
  };

  return (
    <>
      <Container fluid>
        <AlertCustom
          setMessage={setMessage}
          showError={message.show}
          message={message.message}
          type={message.type}
        ></AlertCustom>
        <Form>
          <Grid.Row className="px-0">
            <Grid.Col width="12">
              <Navbar expand="lg" variant="light" bg="white" fixed="top">
                <Container>
                  <Navbar.Brand className="d-flex align-self-center font-weight-bold">
                    <Link to={`/kandidat-artis/detail/${prop.match.params.id}`}>
                      <i className="bx bx-x p-1 rounded bg-light"></i>
                    </Link>{" "}
                    <span className="ml-3">Edit Biografi</span>
                  </Navbar.Brand>
                  <Navbar.Toggle />
                  <Navbar.Collapse className="justify-content-end">
                    <Navbar.Text>
                      <Button
                        variant="danger"
                        disabled={disable}
                        onClick={submit}
                      >
                        Simpan Perubahan
                      </Button>
                    </Navbar.Text>
                  </Navbar.Collapse>
                </Container>
              </Navbar>
            </Grid.Col>
          </Grid.Row>
          <Grid.Row className="mt-5 justify-content-md-center page-top">
            <Grid.Col width="8">
              <Card className="border-card">
                <Card.Body>
                  <Grid.Row>
                    <Grid.Col width={2}>
                      <Image
                        src="/images/icon/biodata.png"
                        className="artis-icon"
                      />
                      <h4 className="font-weight-bold mt-4">Biodata</h4>
                    </Grid.Col>
                    <Grid.Col width={10}>
                      <Grid.Row className="mt-4">
                        <Grid.Col className="mb-4" width={6}>
                          <div className="mb-1">Foto Profil</div>
                          <Image src={image} className="profil-icon" />
                          <input
                            type="file"
                            id="foto"
                            name="foto"
                            ref={inputFile}
                            style={{ display: "none" }}
                            onChange={handleChange}
                            accept="image/png, image/jpeg"
                          />
                          <Button
                            variant="outline-danger"
                            className="mr-3 ml-5"
                            onClick={onButtonClick}
                            type="button"
                          >
                            Ganti Foto
                          </Button>
                        </Grid.Col>
                        <Grid.Col
                          className="mt-6 d-flex justify-content-end"
                          width={6}
                        >
                          <Form.Group className="">
                            <Form.Checkbox
                              checked={
                                input.flag_data_minimalis == 1 ? true : false
                              }
                              name="flag_data_minimalis"
                              label="Input data minimalis"
                              value={input.flag_data_minimalis}
                              onChange={handleChange}
                              className={
                                input.flag_data_minimalis == 1
                                  ? "active w-100"
                                  : "w-100"
                              }
                              isInline
                            />
                          </Form.Group>
                        </Grid.Col>
                        <Grid.Col className="mb-4" width={6}>
                          <FormTextInput
                            name="nama_depan"
                            label="Nama Depan"
                            placeholder="Masukkan Nama Depan"
                            value={input.nama_depan}
                            onChange={handleChange}
                          />
                        </Grid.Col>
                        <Grid.Col className="mb-4" width={6}>
                          <FormTextInput
                            name="nama_belakang"
                            label="Nama Belakang"
                            placeholder="Masukkan Nama Belakang"
                            value={input.nama_belakang}
                            onChange={handleChange}
                          />
                        </Grid.Col>
                        <Grid.Col className="mb-4" width={6}>
                          <FormTextInput
                            name="nama_artis"
                            label="* Nama Artis"
                            placeholder="Masukkan Nama Artis"
                            value={input.nama_artis}
                            onChange={handleChange}
                          />
                        </Grid.Col>
                        <Grid.Col className="mb-4" width={6}>
                          <Form.Group label="* Gender">
                            <Form.Radio
                              checked={input.gender == 1 ? true : false}
                              name="gender"
                              label="Laki-Laki"
                              value={1}
                              onChange={handleChange}
                              className={
                                "custom-radios " +
                                (input.gender == 1 ? "active" : "")
                              }
                              isInline
                            />
                            <Form.Radio
                              checked={input.gender == 2 ? true : false}
                              name="gender"
                              label="Perempuan"
                              value={2}
                              onChange={handleChange}
                              className={
                                "custom-radios " +
                                (input.gender == 2 ? "active" : "")
                              }
                              isInline
                            />
                          </Form.Group>
                        </Grid.Col>
                        <Grid.Col className="mb-4" width={6}>
                          <FormTextInput
                            name="tempat_lahir"
                            label="* Tempat Lahir"
                            placeholder="Masukkan Tempat Lahir"
                            value={input.tempat_lahir}
                            onChange={handleChange}
                          />
                        </Grid.Col>
                        <Grid.Col className="mb-4" width={6}>
                          <div>* Tanggal Lahir</div>
                          <DatePicker
                            className={"form-control"}
                            onChange={(date) =>
                              setInput((prevState) => ({
                                ...prevState,
                                tanggal_lahir: date,
                              }))
                            }
                            value={moment(
                              input.tanggal_lahir
                                ? input.tanggal_lahir
                                : moment(),
                              "DD/MM/YYYY"
                            )}
                            format="DD/MM/YYYY"
                          />
                        </Grid.Col>
                        <Grid.Col className="mb-4" width={3}>
                          <Form.Group label="Berat">
                            <Form.InputGroup>
                              <Form.Input
                                name="berat"
                                type="number"
                                placeholder="Masukkan Berat"
                                value={input.berat}
                                onChange={handleChange}
                              />
                              <Form.InputGroupAppend>
                                <Form.InputGroupText>kg</Form.InputGroupText>
                              </Form.InputGroupAppend>
                            </Form.InputGroup>
                          </Form.Group>
                        </Grid.Col>
                        <Grid.Col className="mb-4" width={3}>
                          <Form.Group label="Tinggi">
                            <Form.InputGroup>
                              <Form.Input
                                name="tinggi"
                                type="number"
                                placeholder="Masukkan Tinggi"
                                value={input.tinggi}
                                onChange={handleChange}
                              />
                              <Form.InputGroupAppend>
                                <Form.InputGroupText>cm</Form.InputGroupText>
                              </Form.InputGroupAppend>
                            </Form.InputGroup>
                          </Form.Group>
                        </Grid.Col>
                        <Grid.Col className="mb-4" width={6}>
                          <Form.Group label="* Agama">
                            <Form.Select name="agama" onChange={handleChange}>
                              <option value={""}>- Pilih Agama -</option>
                              <option
                                selected={
                                  input.agama === "Islam" ? true : false
                                }
                                value="Islam"
                              >
                                Islam
                              </option>
                              <option
                                selected={
                                  input.agama === "Katolik" ? true : false
                                }
                                value="Katolik"
                              >
                                Katolik
                              </option>
                              <option
                                selected={
                                  input.agama === "Protestan" ? true : false
                                }
                                value="Protestan"
                              >
                                Protestan
                              </option>
                              <option
                                selected={
                                  input.agama === "Hindu" ? true : false
                                }
                                value="Hindu"
                              >
                                Hindu
                              </option>
                              <option
                                selected={
                                  input.agama === "Buddha" ? true : false
                                }
                                value="Buddha"
                              >
                                Buddha
                              </option>
                              <option
                                selected={
                                  input.agama === "Khonghucu" ? true : false
                                }
                                value="Khonghucu"
                              >
                                Khonghucu
                              </option>
                            </Form.Select>
                          </Form.Group>
                        </Grid.Col>
                        <Grid.Col className="mb-4" width={6}>
                          <Form.Group label="Group">
                            <Form.Select name="group" onChange={handleChange}>
                              <option value={""}>- Pilih Group -</option>
                              <option
                                selected={input.group === "RP" ? true : false}
                                value="RP"
                              >
                                RP (Related Party)
                              </option>
                              <option
                                selected={input.group === "TP" ? true : false}
                                value="TP"
                              >
                                TP (Third Party)
                              </option>
                            </Form.Select>
                          </Form.Group>
                        </Grid.Col>
                        <Grid.Col className="mb-4" width={6}>
                          <Form.Group label="* Status">
                            <Form.Select
                              name="status_pernikahan"
                              onChange={handleChange}
                            >
                              <option value={""}>- Pilih Status -</option>
                              <option
                                selected={
                                  input.status_pernikahan === 1 ? true : false
                                }
                                value="1"
                              >
                                Single
                              </option>
                              <option
                                selected={
                                  input.status_pernikahan === 2 ? true : false
                                }
                                value="2"
                              >
                                Menikah
                              </option>
                            </Form.Select>
                          </Form.Group>
                        </Grid.Col>
                        {/* penambahan id ktp dan nama ktp */}
                        {/* <Grid.Col className="mb-4" width={6}>
                          <FormTextInput
                            name="nama_ktp"
                            label="* Nama Sesuai KTP"
                            placeholder="Masukkan Nama Sesuai KTP"
                            value={input.nama_ktp}
                            onChange={handleChange}
                          />
                        </Grid.Col>
                        <Grid.Col className="mb-4" width={6}>
                          <FormTextInput
                            name="id_ktp"
                            label="* Nomor KTP"
                            placeholder="Masukkan Nomor KTP"
                            value={input.id_ktp}
                            onChange={handleChange}
                          />
                        </Grid.Col> */}
                        {/* penambahan id ktp dan nama ktp */}
                        {/* <Grid.Col className="mb-4" width={12}>
                          <Form.Group
                            label={<Form.Label children="Attitude" />}
                          >
                            <Form.Textarea
                              name="attitude"
                              rows={2}
                              placeholder="Masukkan Attitude"
                              value={input.attitude}
                              onChange={handleChange}
                            />
                          </Form.Group>
                        </Grid.Col> */}
                        <Grid.Col className="mb-4" width={12}>
                          <Form.Group label={<Form.Label children="Remark" />}>
                            <Form.Textarea
                              name="remark"
                              rows={3}
                              placeholder="Masukkan Remark"
                              value={input.remark}
                              onChange={handleChange}
                            />
                          </Form.Group>
                        </Grid.Col>
                      </Grid.Row>
                    </Grid.Col>
                  </Grid.Row>
                  <hr className="h-dashed"></hr>
                  <Grid.Row>
                    <Grid.Col width={2}>
                      <Image
                        src="/images/icon/casting.png"
                        className="artis-icon"
                      />
                      <h4 className="font-weight-bold mt-4">Info Casting</h4>
                    </Grid.Col>
                    <Grid.Col width={10}>
                      <Grid.Row className="mt-4">
                        {/* <Grid.Col className="mb-4" width={6}>
                          <Form.Group label="Artis Sinemart?">
                            <Form.Radio
                              checked={input.artis_sinemart == 1 ? true : false}
                              name="artis_sinemart"
                              label="Ya"
                              value={1}
                              onChange={handleChange}
                              className={
                                "custom-radios " +
                                (input.artis_sinemart == 1 ? "active" : "")
                              }
                              isInline
                            />
                            <Form.Radio
                              checked={input.artis_sinemart == 0 ? true : false}
                              name="artis_sinemart"
                              label="Bukan"
                              value={0}
                              onChange={handleChange}
                              className={
                                "custom-radios " +
                                (input.artis_sinemart == 0 ? "active" : "")
                              }
                              isInline
                            />
                          </Form.Group>
                        </Grid.Col> */}

                        {/* penambahan baru untuk eksklusif dan casting team 5555 */}
                        <Grid.Col className="mb-4" width={6}>
                          <Form.Group label="eksklusif ?">
                            <Form.Radio
                              checked={input.is_exclusive == "1" ? true : false}
                              name="is_exclusive"
                              label="Ya"
                              value={1}
                              onChange={handleChange}
                              className={
                                "custom-radios " +
                                (input.is_exclusive == "1" ? "active" : "")
                              }
                              isInline
                            />
                            <Form.Radio
                              checked={input.is_exclusive == "0" ? true : false}
                              name="is_exclusive"
                              label="Bukan"
                              value={0}
                              onChange={handleChange}
                              className={
                                "custom-radios " +
                                (input.is_exclusive == "0" ? "active" : "")
                              }
                              isInline
                            />
                          </Form.Group>
                        </Grid.Col>
                        <Grid.Col className="mb-4" width={6}>
                          <div>Tanggal Casting</div>
                          <DatePicker
                            className={"form-control"}
                            onChange={(date) =>
                              setInput((prevState) => ({
                                ...prevState,
                                tanggal_casting: date,
                              }))
                            }
                            value={moment(
                              input.tanggal_casting
                                ? input.tanggal_casting
                                : moment(),
                              "DD/MM/YYYY"
                            )}
                            format="DD/MM/YYYY"
                          />
                        </Grid.Col>
                        {/* <Grid.Col className="mb-4" width={6}>
                          <FormTextInput
                            name="nilai_casting"
                            type="number"
                            label="Nilai Casting"
                            placeholder="Masukkan Nilai Casting"
                            value={input.nilai_casting}
                            onChange={handleChange}
                          />
                        </Grid.Col> */}
                        <Grid.Col className="mb-4" width={6}>
                          <Form.Group label="Grade">
                            <Form.Radio
                              checked={input.grade === "A" ? true : false}
                              name="grade"
                              label="A"
                              value="A"
                              onChange={handleChange}
                              className={
                                "custom-radios w-30 " +
                                (input.grade === "A" ? "active" : "")
                              }
                              isInline
                            />
                            <Form.Radio
                              checked={input.grade === "B" ? true : false}
                              name="grade"
                              label="B"
                              value="B"
                              onChange={handleChange}
                              className={
                                "custom-radios w-30 " +
                                (input.grade === "B" ? "active" : "")
                              }
                              isInline
                            />
                            <Form.Radio
                              checked={input.grade === "C" ? true : false}
                              name="grade"
                              label="C"
                              value="C"
                              onChange={handleChange}
                              className={
                                "custom-radios w-30 " +
                                (input.grade === "C" ? "active" : "")
                              }
                              isInline
                            />
                          </Form.Group>
                        </Grid.Col>

                        {input.is_exclusive == 1 && (
                          <Grid.Col className="mb-4" width={6}>
                            <Form.Group label="Talent Tim" width={6}>
                              <Form.Select
                                name="talent_team"
                                onChange={handleChange}
                              >
                                <option value={""}>- Pilih Casting -</option>
                                {dataCastingTeam.length > 0 &&
                                  dataCastingTeam.map((items) => (
                                    <option
                                      value={items.value}
                                      selected={
                                        input.talent_team === items.value
                                          ? true
                                          : false
                                      }
                                    >
                                      {items.label}
                                    </option>
                                  ))}
                              </Form.Select>
                            </Form.Group>
                          </Grid.Col>
                        )}

                        <Grid.Col className="mb-4" width={6}>
                          <Form.Group label="Talent Prospect">
                            <Select
                              name="talent_prospect"
                              options={talentProsepect}
                              styles={colourStyles}
                              onChange={handleChangeTalentCasting}
                              value={dataTalentProspect}
                              placeholder="Pilih Talent Prospect"
                              isMulti
                            />
                          </Form.Group>
                        </Grid.Col>

                        <Grid.Col className="mb-4" width={6}>
                          <Form.Group label="Categori Casting" width={6}>
                            <Form.Select
                              name="category_casting_id"
                              onChange={handleChange}
                            >
                              <option value={""}>- Pilih Categori -</option>
                              {categoryCasting.length > 0 &&
                                categoryCasting.map((items) => (
                                  <option
                                    value={items.value}
                                    selected={
                                      input.category_casting_id === items.value
                                        ? true
                                        : false
                                    }
                                  >
                                    {items.label}
                                  </option>
                                ))}
                            </Form.Select>
                          </Form.Group>
                        </Grid.Col>

                        <Grid.Col className="mb-4" width={6}>
                          <Form.Group label="Peran">
                            <Form.Select name="peran" onChange={handleChange}>
                              <option value={""}>- Pilih Peran -</option>
                              <option
                                selected={input.peran === 1 ? true : false}
                                value="1"
                              >
                                Protagonis
                              </option>
                              <option
                                selected={input.peran === 2 ? true : false}
                                value="2"
                              >
                                Antagonis
                              </option>
                              <option
                                selected={input.peran === 3 ? true : false}
                                value="3"
                              >
                                Protagonis & Antagonis
                              </option>
                            </Form.Select>
                          </Form.Group>
                        </Grid.Col>
                        {/* <Grid.Col className="mb-4" width={6}>
                          <FormTextInput
                            name="karakter"
                            label="Karakter"
                            placeholder="Masukkan Karakter"
                            value={input.karakter}
                            onChange={handleChange}
                          />
                        </Grid.Col> */}

                        <Grid.Col className="mb-4" width={6}>
                          <KarakterDropdown
                            value={input.karakter}
                            btnAdd={
                              roleUser == "Super Admin" ||
                              EmailUser == "fariz@sinemart.com"
                            }
                            onChange={(value) => {
                              setInput({
                                ...input,
                                karakter: value,
                              });
                            }}
                          />
                        </Grid.Col>
                      </Grid.Row>
                    </Grid.Col>
                  </Grid.Row>
                  <hr className="h-dashed"></hr>
                  <Grid.Row>
                    <Grid.Col width={2}>
                      <Image
                        src="/images/icon/kontak.png"
                        className="artis-icon"
                      />
                      <h4 className="font-weight-bold mt-4">Kontak</h4>
                    </Grid.Col>
                    <Grid.Col width={10}>
                      <Grid.Row className="mt-4">
                        <Grid.Col className="mb-4" width={12}>
                          <Form.Group label={<Form.Label children="Alamat" />}>
                            <Form.Textarea
                              name="alamat"
                              rows={3}
                              placeholder="Masukkan Alamat"
                              value={input.alamat}
                              onChange={handleChange}
                            />
                          </Form.Group>
                        </Grid.Col>
                        {/* <Grid.Col className="mb-4" width={12}>
                          <FormTextInput
                            name="region"
                            label="Region"
                            placeholder="Masukkan Region"
                            value={input.region}
                            onChange={handleChange}
                          />
                        </Grid.Col> */}
                        <Grid.Col className="mb-4" width={6}>
                          <FormTextInput
                            name="email"
                            label="Email"
                            type="email"
                            placeholder="Masukkan Email"
                            value={input.email}
                            onChange={handleChange}
                          />
                        </Grid.Col>
                        {/* <Grid.Col className="mb-4" width={6}>
                          <FormTextInput
                            name="fax"
                            label="Fax"
                            placeholder="Masukkan Fax"
                            value={input.fax}
                            onChange={handleChange}
                          />
                        </Grid.Col> */}
                        {/* <Grid.Col className="mb-4" width={6}>
                          <FormTextInput
                            name="telepon_1"
                            label="Telepon"
                            placeholder="Masukkan Telepon"
                            value={input.telepon_1}
                            onChange={handleChange}
                          />
                        </Grid.Col> */}
                        {/* <Grid.Col className="mb-4" width={6}>
                          <FormTextInput
                            name="telepon_2"
                            label="Telepon 2"
                            placeholder="Masukkan Telepon 2"
                            value={input.telepon_2}
                            onChange={handleChange}
                          />
                        </Grid.Col> */}
                        <Grid.Col className="mb-4" width={6}>
                          <FormTextInput
                            name="hp_1"
                            label="* Handphone"
                            placeholder="Masukkan Handphone"
                            value={input.hp_1}
                            onChange={handleChange}
                          />
                        </Grid.Col>
                        {/* <Grid.Col className="mb-4" width={6}>
                          <FormTextInput
                            name="hp_2"
                            label="Handphone 2"
                            placeholder="Masukkan Handphone 2"
                            value={input.hp_2}
                            onChange={handleChange}
                          />
                        </Grid.Col> */}
                      </Grid.Row>
                    </Grid.Col>
                  </Grid.Row>
                  <hr className="h-dashed"></hr>
                  <Grid.Row>
                    <Grid.Col width={2}>
                      <Image
                        src="/images/icon/pengalaman.png"
                        className="artis-icon"
                      />
                      <h4 className="font-weight-bold mt-4">Pengalaman</h4>
                    </Grid.Col>
                    <Grid.Col width={10}>
                      {inputPengalaman.map((data, index) => (
                        <Grid.Row
                          className="mt-4 align-items-center"
                          ey={`${data}~${index}`}
                        >
                          <Grid.Col className="mb-4" width={3}>
                            <FormTextInput
                              name="pengalaman_judul"
                              label="Judul"
                              placeholder="Masukkan Judul"
                              value={data.pengalaman_judul}
                              onChange={(event) =>
                                handleInputPengalamanChange(index, event)
                              }
                            />
                          </Grid.Col>
                          <Grid.Col className="mb-4" width={2}>
                            <FormTextInput
                              name="pengalaman_tahun"
                              label="Tahun"
                              placeholder="Masukkan Tahun"
                              value={data.pengalaman_tahun}
                              maxLength={4}
                              onChange={(event) =>
                                handleInputPengalamanChange(index, event)
                              }
                            />
                          </Grid.Col>
                          <Grid.Col className="mb-4" width={3}>
                            <Form.Group label="Jenis Film">
                              <Select
                                name="pengalaman_jenis_film"
                                options={[
                                  { value: "1", label: "None" },
                                  { value: "2", label: "Film TV" },
                                  { value: "3", label: "Iklan" },
                                  { value: "4", label: "TV Series" },
                                  { value: "5", label: "Layar Lebar" },
                                ]}
                                onChange={(event) =>
                                  handleInputPengalamanChange(
                                    index,
                                    event,
                                    "pengalaman_jenis_film"
                                  )
                                }
                                value={{
                                  value: data.pengalaman_jenis_film,
                                  label: jenis_film(data.pengalaman_jenis_film),
                                }}
                                placeholder="Pilih Jenis Film"
                                isMulti={false}
                              />
                            </Form.Group>
                          </Grid.Col>
                          <Grid.Col className="mb-4" width={3}>
                            <Form.Group label="Peran">
                              <Select
                                name="pengalaman_peran"
                                options={[
                                  { value: "1", label: "None" },
                                  { value: "2", label: "Peran Utama" },
                                  { value: "3", label: "P. Pembantu Utama" },
                                  { value: "4", label: "Peran Pembantu" },
                                  { value: "5", label: "Figuran" },
                                ]}
                                onChange={(event) =>
                                  handleInputPengalamanChange(
                                    index,
                                    event,
                                    "pengalaman_peran"
                                  )
                                }
                                value={{
                                  value: data.pengalaman_peran,
                                  label: peran(data.pengalaman_peran),
                                }}
                                placeholder="Pilih Peran"
                                isMulti={false}
                              />
                            </Form.Group>
                          </Grid.Col>
                          {index == 0 ? (
                            ""
                          ) : (
                            <Grid.Col className="mb-4" width={1}>
                              <Button
                                variant="none"
                                onClick={() =>
                                  handleRemovePengalamanFields(index)
                                }
                              >
                                <i className="bx bxs-trash text-danger fa-2x"></i>
                              </Button>
                            </Grid.Col>
                          )}
                        </Grid.Row>
                      ))}
                      <div className="mb-3">
                        <Button
                          variant="outline-danger"
                          onClick={() => handleAddPengalamanFields()}
                        >
                          <i className="bx bx-plus"></i> Tambah Pengalaman
                        </Button>
                      </div>
                    </Grid.Col>
                  </Grid.Row>
                  <hr className="h-dashed"></hr>
                  <Grid.Row>
                    <Grid.Col width={2}>
                      <Image
                        src="/images/icon/management.png"
                        className="artis-icon"
                      />
                      <h4 className="font-weight-bold mt-4">Management</h4>
                    </Grid.Col>
                    <Grid.Col width={10}>
                      <Grid.Row className="mt-4">
                        <Grid.Col className="mb-4" width={12}>
                          <FormTextInput
                            name="person_in_charge"
                            label="PIC"
                            placeholder="Masukkan nama PIC"
                            value={input.person_in_charge}
                            onChange={handleChange}
                          />
                        </Grid.Col>
                        <Grid.Col className="mb-4" width={6}>
                          <FormTextInput
                            name="manajer"
                            label="Manajer"
                            placeholder="Masukkan Manajer"
                            value={input.manajer}
                            onChange={handleChange}
                          />
                        </Grid.Col>
                        <Grid.Col className="mb-4" width={6}>
                          <FormTextInput
                            name="telepon_manajer"
                            label="* Telepon Manajer"
                            placeholder="Masukkan Telepon Manajer"
                            value={input.telepon_manajer}
                            onChange={handleChange}
                          />
                        </Grid.Col>
                        <Grid.Col className="mb-4" width={12}>
                          <FormTextInput
                            name="manajemen"
                            label="Management"
                            placeholder="Masukkan Management"
                            value={input.manajemen}
                            onChange={handleChange}
                          />
                        </Grid.Col>
                        <Grid.Col className="mb-4" width={12}>
                          <FormTextInput
                            name="telepon_manajemen_1"
                            label="* Telepon Management"
                            placeholder="Masukkan Telepon Management"
                            value={input.telepon_manajemen_1}
                            onChange={handleChange}
                          />
                        </Grid.Col>
                        {/* <Grid.Col className="mb-4" width={6}>
                          <FormTextInput
                            name="telepon_manajemen_2"
                            label="Telepon Management 2"
                            placeholder="Masukkan Telepon Management 2"
                            value={input.telepon_manajemen_2}
                            onChange={handleChange}
                          />
                        </Grid.Col> */}
                        {/* <Grid.Col className="mb-4" width={12}>
                          <FormTextInput
                            name="agency"
                            label="Agency"
                            placeholder="Masukkan Agency"
                            value={input.agency}
                            onChange={handleChange}
                          />
                        </Grid.Col>
                        <Grid.Col className="mb-4" width={12}>
                          <FormTextInput
                            name="telepon_agency_1"
                            label="Telepon Agency"
                            placeholder="Masukkan Telepon Agency"
                            value={input.telepon_agency_1}
                            onChange={handleChange}
                          />
                        </Grid.Col> */}
                        {/* <Grid.Col className="mb-4" width={6}>
                          <FormTextInput
                            name="telepon_agency_2"
                            label="Telepon Agency 2"
                            placeholder="Masukkan Telepon Agency 2"
                            value={input.telepon_agency_2}
                            onChange={handleChange}
                          />
                        </Grid.Col> */}
                      </Grid.Row>
                    </Grid.Col>
                  </Grid.Row>
                  {/* <hr className="h-dashed"></hr>
                  <Grid.Row>
                    <Grid.Col width={2}>
                      <Image
                        src="/images/icon/npwp.png"
                        className="artis-icon"
                      />
                      <h4 className="font-weight-bold mt-4">NPWP</h4>
                    </Grid.Col>
                    <Grid.Col width={10}>
                      {inputNpwp.map((data, index) => (
                        <Grid.Row
                          className="mt-4 align-items-center"
                          ey={`${data}~${index}`}
                        >
                          <Grid.Col className="mb-4" width={6}>
                            <FormTextInput
                              name="name"
                              label="Nama NPWP"
                              placeholder="Masukkan Nama NPWP"
                              value={data.name}
                              onChange={(event) =>
                                handleInputNpwpChange(index, event)
                              }
                            />
                          </Grid.Col>
                          <Grid.Col className="mb-4" width={index == 0 ? 6 : 5}>
                            <FormTextInput
                              name="number"
                              label="NPWP"
                              placeholder="Masukkan NPWP"
                              value={data.number}
                              onChange={(event) =>
                                handleInputNpwpChange(index, event)
                              }
                            />
                          </Grid.Col>
                          <Grid.Col className="mb-4" width={12}>
                            <Form.Group
                              label={<Form.Label children="* Alamat" />}
                            >
                              <Form.Textarea
                                name="alamat_npwp"
                                rows={3}
                                placeholder="Masukkan Alamat"
                                value={data.alamat_npwp}
                                onChange={(event) =>
                                  handleInputNpwpChange(index, event)
                                }
                              />
                            </Form.Group>
                          </Grid.Col>
                          {index == 0 ? (
                            ""
                          ) : (
                            <Grid.Col className="mb-4" width={1}>
                              <Button
                                variant="none"
                                onClick={() => handleRemoveNpwpFields(index)}
                              >
                                <i className="bx bxs-trash text-danger fa-2x"></i>
                              </Button>
                            </Grid.Col>
                          )}
                        </Grid.Row>
                      ))}
                      <div className="mb-3">
                        <Button
                          variant="outline-danger"
                          onClick={() => handleAddNpwpFields()}
                        >
                          <i className="bx bx-plus"></i> Tambah NPWP
                        </Button>
                      </div>
                    </Grid.Col>
                  </Grid.Row> */}
                  <hr className="h-dashed"></hr>
                  <Grid.Row>
                    <Grid.Col width={2}>
                      <Image
                        src="/images/icon/rekening.png"
                        className="artis-icon"
                      />
                      <h4 className="font-weight-bold mt-4">Rekening</h4>
                    </Grid.Col>
                    <Grid.Col width={10}>
                      {inputRekening.map((data, index) => (
                        <Grid.Row
                          className="mt-4 align-items-center"
                          ey={`${data}~${index}`}
                        >
                          <Grid.Col className="mb-4" width={4}>
                            <FormTextInput
                              name="name"
                              label="Nama Rekening"
                              placeholder="Masukkan Nama Rekening"
                              value={data.name}
                              onChange={(event) =>
                                handleInputRekeningChange(index, event)
                              }
                            />
                          </Grid.Col>
                          <Grid.Col className="mb-4" width={4}>
                            <Form.Group label="Bank">
                              <Select
                                name="bank_name"
                                options={bank}
                                onChange={(event) =>
                                  handleInputBankChange(index, event)
                                }
                                value={dataBank[index]}
                                placeholder="Pilih Nama Bank"
                                isMulti={false}
                              />
                            </Form.Group>
                          </Grid.Col>
                          <Grid.Col className="mb-4" width={index == 0 ? 4 : 3}>
                            <FormTextInput
                              name="number"
                              label="Nomor Rekening"
                              placeholder="Masukkan Nomor Rekening"
                              value={data.number}
                              onChange={(event) =>
                                handleInputRekeningChange(index, event)
                              }
                            />
                          </Grid.Col>
                          {index == 0 ? (
                            ""
                          ) : (
                            <Grid.Col className="mb-4" width={1}>
                              <Button
                                variant="none"
                                onClick={() =>
                                  handleRemoveRekeningFields(index)
                                }
                              >
                                <i className="bx bxs-trash text-danger fa-2x"></i>
                              </Button>
                            </Grid.Col>
                          )}
                        </Grid.Row>
                      ))}
                      <div className="mb-3">
                        <Button
                          variant="outline-danger"
                          onClick={() => handleAddRekeningFields()}
                        >
                          <i className="bx bx-plus"></i> Tambah Rekening
                        </Button>
                      </div>
                    </Grid.Col>
                  </Grid.Row>
                </Card.Body>
              </Card>
            </Grid.Col>
          </Grid.Row>
        </Form>
      </Container>
    </>
  );
}

export default EditBiografi;
