import React, { useState, useEffect, useContext } from "react";

import SiteWrapper from "../../Layouts/SiteWrapper";
import { Row, Col, Button, Dropdown, Image } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { Card, FormTextInput } from "tabler-react";
import { BsThreeDots } from "react-icons/bs";
import HapusRole from "./Modal/HapusRole";
import { Link } from "react-router-dom";
import { AiFillEdit } from "react-icons/ai";
import axios from "axios";
import Loader from "react-loader-spinner";
import moment from "moment";
import { ContextPermission } from "../../Context/Permission";

function ManajamenRole() {
  type breadcrumbItem = {|
    +value: string,
    +to?: string,
    +active?: boolean,
  |};

  const [loader, setLoader] = useState(true);
  const urlMicroservice = process.env.REACT_APP_BE_DBTALENT_URL;
  const token = localStorage.getItem("token");
  const [dataRole, setDataRole] = useState([]);
  const statePermission = useContext(ContextPermission);
  const loginUrl = process.env.REACT_APP_LOGIN_TOKEN_URL;
  const [total, setTotal] = useState(0);
  const [paginate, setPaginate] = useState({
    page: 1,
    perPage: 10,
    selectedColumn: "",
    sortDirection: "",
    search: "",
  });

  const breadcrumbItems: Array<breadcrumbItem> = [
    {
      value: "Manajemen Role",
      to: "/manajemen-role",
      active: false,
    },
  ];

  const columns = [
    {
      name: "Nama Role",
      selector: "name",
      sortable: true,
    },
    {
      name: "Status",
      selector: "status",
      cell: (row) =>
        row.status === "1" ? (
          <span class="badge bg-lime px-3 py-1">Aktif</span>
        ) : (
          <span class="badge bg-red px-3 py-1">Tidak Aktif</span>
        ),
      sortable: true,
      maxWidth: "20%",
      center: true,
    },
    {
      name: "Dibuat Pada",
      selector: "created_at",
      cell: (row) => moment(row.created_at).format("LL"),
      sortable: true,
      maxWidth: "20%",
    },
    {
      name: "Aksi",
      cell: (row) => (
        <>
          {statePermission.permission.role.edit_role.active === 1 ? (
            <Button
              variant="warning"
              className="mr-4"
              size="sm"
              as={Link}
              to={"/edit-role/" + row.id}
            >
              <AiFillEdit size="16px" color="white" />
            </Button>
          ) : (
            <></>
          )}{" "}
          &nbsp;{" "}
          {statePermission.permission.role.hapus_role.active === 1 ? (
            <HapusRole idRole={row.id} nameRole={row.name} token={token} />
          ) : (
            <></>
          )}
        </>
      ),
      sortable: false,
      maxWidth: "10%",
      center: true,
      omit:
        statePermission.permission.role.edit_role.active === 0 &&
        statePermission.permission.role.hapus_role.active === 0
          ? true
          : false,
    },
  ];

  useEffect(() => {
    axios
      .get(
        urlMicroservice +
          `user/roles?page=${paginate.page}&limit=${paginate.perPage}&sortBy=${paginate.selectedColumn}&desc=${paginate.sortDirection}&field_search=name&search=${paginate.search}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        setDataRole(res.data.data.data);
        setTotal(res.data.data.total);
        setLoader(false);
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 401) {
            window.open(loginUrl, "_parent");
          }
        }
      });
  }, [paginate]);

  return (
    <SiteWrapper breadcrumbItems={breadcrumbItems} title={"Manajemen Role"}>
      <Loader
        visible={loader}
        className="loaders"
        type="ThreeDots"
        color="#ED1C24"
        height="100"
        width="100"
      />
      {statePermission.home_menu.role.active === 1 ? (
        <Card className="border-card mt-5">
          <Card.Body>
            <Row>
              <Col md="6">
                <p>Daftar Role</p>
              </Col>
              <Col md="6" className="text-right">
                {statePermission.permission.role.buat_role.active === 1 ? (
                  <Button
                    variant="danger"
                    className="text-white"
                    as={Link}
                    to={"/tambah-role"}
                  >
                    + Buat Role Baru
                  </Button>
                ) : (
                  <></>
                )}
              </Col>
            </Row>
            <Row className="mt-5">
              <Col sm="12" className="mr-5">
                <div class="row flex-row-reverse ml-1 justify-content-between">
                  <div class="mr-3 d-flex align-items-center">
                    <div class="mdb-datatable-filter flex-row text-left">
                      <FormTextInput
                        className="form-control form-control-sm ml-0 my-1"
                        placeholder="Search"
                        value={paginate.search}
                        onChange={(evt) =>
                          setPaginate({ ...paginate, search: evt.target.value })
                        }
                      />
                    </div>
                  </div>
                </div>

                <DataTable
                  className={"table-dropdown"}
                  columns={columns}
                  data={dataRole}
                  highlightOnHover
                  responsive
                  pagination
                  paginationServer
                  paginationTotalRows={total}
                  paginationPerPage={paginate.perPage}
                  onChangeRowsPerPage={(perPage) =>
                    setPaginate({ ...paginate, page: 1, perPage: perPage })
                  }
                  onChangePage={(page) =>
                    setPaginate({ ...paginate, page: page })
                  }
                  // onSort={(selectedColumn, sortDirection) => {setPaginate({...paginate,selectedColumn:selectedColumn.selector,sortDirection:sortDirection==='desc'?true:false})}}
                />
              </Col>
            </Row>
          </Card.Body>
        </Card>
      ) : (
        <Row>
          <Col width="12" className="text-center">
            Anda Tidak Memiliki Akses Manajemen Role
          </Col>
        </Row>
      )}
    </SiteWrapper>
  );
}

export default ManajamenRole;
