import React, { useState } from "react";
import { Modal, Button, Form, Image } from "react-bootstrap";
import "./TambahJudul.css";
import { BsFillEyeFill, BsFillEyeSlashFill } from "react-icons/bs";
import axios from "axios";
import AlertCustom from "../../../Components/Modal/AlertCustomComponent";

function TambahJudul(props) {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [urlImage, setUrlImage] = useState(
    process.env.REACT_APP_FRONTEND_URL + "demo/imgjudul.png"
  );
  const [fileImage, setFileImage] = useState("");
  const [inputJudul, setInputJudul] = useState("");
  const [inputKategori, setInputKategori] = useState("");
  const [inputGroup, setInputGroup] = useState("");
  const [inputSinopsis, setInputSinopsis] = useState("");
  const [inputSutradara, setInputSutradara] = useState("");
  const [inputSutradaraId, setInputSutradaraId] = useState("");
  const [inputProduser, setInputProduser] = useState("");
  const [inputProduserId, setInputProduserId] = useState("");
  const [inputSkenario, setInputSkenario] = useState("");
  const [inputCastingTeam, setInputCastingTeam] = useState("");
  const [inputCastingTeamId, setInputCastingTeamId] = useState("");
  const [inputSkenarioId, setInputSkenarioId] = useState("");
  const [inputCompanyId, setInputCompanyId] = useState("");
  const [idProduser, setIdProduser] = useState(" ");

  const [message, setMessage] = useState({
    type: "",
    message: "",
    show: "d-none",
  });
  const [disable, setDisable] = useState(false);
  const hiddenFileInput = React.useRef(null);
  const loginUrl = process.env.REACT_APP_LOGIN_TOKEN_URL;
  const [errorImage, setErrorImage] = useState("none");
  const [errorJudul, setErrorJudul] = useState("none");
  const [errorKategori, setErrorKategori] = useState("none");
  const [errorGrup, setErrorGrup] = useState("none");
  const [errorSinopsis, setErrorSinopsis] = useState("none");
  const [errorSutradara, setErrorSutradara] = useState("none");
  const [errorProduser, setErrorProduser] = useState("none");
  const [errorSkenario, setErrorSkenario] = useState("none");
  const [errorCastingTeam, setErrorCastingTeam] = useState("none");
  const [errorCompanyId, setErrorCompanyId] = useState("none");

  function capitalizeWords(str) {
    return str.toLowerCase().replace(/\b\w/g, function (char) {
      return char.toUpperCase();
    });
  }

  const submitTambahUser = () => {
    const data = new FormData();
    data.append("title", inputJudul);
    data.append("title_cover", fileImage);
    data.append(
      "producer",
      inputProduser != "" ? capitalizeWords(inputProduser) : "-"
    );
    data.append("producer_id", inputProduserId != "" ? inputProduserId : "0");
    data.append(
      "director",
      inputSutradara != "" ? capitalizeWords(inputSutradara) : "-"
    );
    data.append("director_id", inputSutradaraId != "" ? inputSutradaraId : 0);
    data.append("writer", capitalizeWords(inputSkenario));
    data.append("writer_id", inputSkenarioId);
    data.append("synopsis", inputSinopsis);
    data.append("category_id", inputKategori);
    data.append("group_id", inputGroup);
    data.append("company_id", inputCompanyId);
    data.append("produser", idProduser);
    data.append("casting_team", inputCastingTeam);
    data.append("casting_team_id", inputCastingTeamId);

    if (fileImage === "") {
      setErrorImage("inline");
    } else if (inputJudul === "") {
      setErrorJudul("inline");
    } else if (inputKategori === "") {
      setErrorKategori("inline");
    } else if (inputGroup === "") {
      setErrorGrup("inline");
    } else if (inputSinopsis === "") {
      setErrorSinopsis("inline");
    }
    //  else if (inputSutradara === "") {
    //   setErrorSutradara("inline");
    // }
    // else if (inputProduser === "") {
    //   setErrorProduser("inline");
    // }
    else if (inputSkenario === "") {
      setErrorSkenario("inline");
    } else if (inputCastingTeam === "") {
      setErrorCastingTeam("inline");
    } else if (
      inputCompanyId === null ||
      inputCompanyId === "null" ||
      inputCompanyId === ""
    ) {
      setErrorCompanyId("inline");
    } else {
      setDisable(true);
      axios
        .post(
          process.env.REACT_APP_BE_DBTALENT_URL + `talent/title/store`,
          data,
          {
            headers: {
              Authorization: `Bearer ${props.token}`,
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          setMessage({
            type: "success",
            message: "Berhasil Menambahkan Judul",
            show: "d-block",
          });
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        })
        .catch((error) => {
          setDisable(false);
          var message = error.message;
          if (typeof error.response != "undefined") {
            message = error.response.data.message;
          }
          setMessage({
            type: "danger",
            message: Object.values(message)[0][0],
            show: "d-block",
          });

          if (error.response) {
            if (error.response.status === 401) {
              window.open(loginUrl, "_parent");
            }
          }
        });
    }
  };

  const changeInputJudul = (e) => {
    let val = e.target.value;
    setInputJudul(val);
    if (val !== "") {
      setErrorJudul("none");
    }
  };
  const changeInputKategori = (e) => {
    let val = e.target.value;
    setInputKategori(val);
    if (val !== "") {
      setErrorKategori("none");
    }
  };
  const changeInputGroup = (e) => {
    let val = e.target.value;
    setInputGroup(val);
    if (val !== "") {
      setErrorGrup("none");
    }
  };
  const changeInputSinopsis = (e) => {
    let val = e.target.value;
    setInputSinopsis(val);
    if (val !== "") {
      setErrorSinopsis("none");
    }
  };
  const changeInputSutradara = (e) => {
    let val = e.target.value;
    const index = e.target.selectedIndex;
    const el = e.target.childNodes[index];
    const option = el.getAttribute("valuea");

    setInputSutradara(option);
    setInputSutradaraId(parseInt(val, 10));

    if (val !== "") {
      setErrorSinopsis("none");
    }
  };
  const changeInputProduser = (e) => {
    let val = e.target.value;
    const index = e.target.selectedIndex;
    const el = e.target.childNodes[index];
    const option = el.getAttribute("valuea");
    const idErp = el.getAttribute("valueb");

    setIdProduser(parseInt(val, 10));
    setInputProduser(option);
    setInputProduserId(parseInt(val, 10));

    if (val !== "") {
      setErrorProduser("none");
    }
  };
  const changeInputSkenario = (e) => {
    let val = e.target.value;
    const index = e.target.selectedIndex;
    const el = e.target.childNodes[index];
    const option = el.getAttribute("valuea");

    setInputSkenario(option);
    setInputSkenarioId(parseInt(val, 10));

    if (val !== "") {
      setErrorSkenario("none");
    }
  };
  const changeInputCastingTeam = (e) => {
    let val = e.target.value;
    const index = e.target.selectedIndex;
    const el = e.target.childNodes[index];
    const option = el.getAttribute("valuea");

    setInputCastingTeam(option);
    setInputCastingTeamId(parseInt(val, 10));

    if (val !== "") {
      setErrorSkenario("none");
    }
  };

  const klikAmbilFoto = (e) => {
    hiddenFileInput.current.click();
  };
  const ambilFoto = (e) => {
    setUrlImage(URL.createObjectURL(e.target.files[0]));
    if (e.target.files[0]) {
      setFileImage(e.target.files[0]);
      setErrorImage("none");
    }
  };

  const changeInputCompany = (e) => {
    let val = e.target.value;

    if (val !== "") {
      setInputCompanyId(val);
      setErrorCompanyId("none");
    }
  };

  function capitalizeWords(str) {
    return str.toLowerCase().replace(/\b\w/g, function (char) {
      return char.toUpperCase();
    });
  }

  return (
    <>
      <Button variant="danger" onClick={handleShow}>
        + Buat Judul Baru
      </Button>

      <Modal show={show} onHide={handleClose}>
        <AlertCustom
          setMessage={setMessage}
          showError={message.show}
          message={message.message}
          type={message.type}
        ></AlertCustom>
        <Modal.Header closeButton className="mx-4">
          <Modal.Title>Buat Judul Baru</Modal.Title>
        </Modal.Header>
        <Modal.Body className="mx-4">
          <Form>
            <Form.Group className="mb-4" controlId="img">
              <Form.Label>Cover Judul</Form.Label>
              <Image
                src={urlImage}
                style={{ height: "150px", width: "120px" }}
              />
              <Form.Control
                type="file"
                style={{ display: "none" }}
                ref={hiddenFileInput}
                onChange={ambilFoto}
              />
              <Button
                variant="outline-danger"
                className="ml-3"
                onClick={klikAmbilFoto}
              >
                Pilih Cover
              </Button>
              <br />
              <p className="text-danger" style={{ display: errorImage }}>
                * cover tidak boleh kosong
              </p>
            </Form.Group>
            <Form.Group className="mb-4" controlId="Judul">
              <Form.Label>Judul</Form.Label>
              <Form.Control
                type="text"
                placeholder="Masukan Judul Lengkap"
                onChange={changeInputJudul}
              />
              <span className="text-danger" style={{ display: errorJudul }}>
                * judul tidak boleh kosong
              </span>
            </Form.Group>
            <Form.Group className="mb-4" controlId="role">
              <Form.Label>Kategori</Form.Label>
              <Form.Control as="select" onChange={changeInputKategori}>
                <option>Pilih Kategori</option>
                {props.title &&
                  props.title.categories.map((item, i) => (
                    <option key={item.id} value={item.id}>
                      {item.category_name}
                    </option>
                  ))}
              </Form.Control>
              <span className="text-danger" style={{ display: errorKategori }}>
                * kategori tidak boleh kosong
              </span>
            </Form.Group>
            <Form.Group className="mb-4" controlId="Group">
              <Form.Label>Group</Form.Label>
              <Form.Control as="select" onChange={changeInputGroup}>
                <option>Pilih Group</option>
                {props.title &&
                  props.title.groups.map((item, i) => (
                    <option key={item.id} value={item.id}>
                      {item.group_name}
                    </option>
                  ))}
              </Form.Control>
              <span className="text-danger" style={{ display: errorGrup }}>
                * group tidak boleh kosong
              </span>
            </Form.Group>
            <Form.Group className="mb-4" controlId="Sinopsis">
              <Form.Label>Sinopsis</Form.Label>
              <Form.Control
                type="text"
                placeholder="Masukan Sinopsis"
                onChange={changeInputSinopsis}
              />
              <span className="text-danger" style={{ display: errorSinopsis }}>
                * sinopsis tidak boleh kosong
              </span>
            </Form.Group>
            <Form.Group className="mb-4" controlId="Sutradara">
              <Form.Label>Sutradara</Form.Label>
              <Form.Control
                as="select"
                onChange={(e) => changeInputSutradara(e)}
              >
                <option>Pilih Sutradara</option>
                {props.sutradara &&
                  props.sutradara.map((item, i) => (
                    <option key={item.id} value={item.id} valuea={item.name}>
                      {capitalizeWords(item.name)}
                    </option>
                  ))}
              </Form.Control>
              <span className="text-danger" style={{ display: errorSutradara }}>
                * sutradara tidak boleh kosong
              </span>
            </Form.Group>
            <Form.Group className="mb-4" controlId="Produser">
              <Form.Label>Produser</Form.Label>
              <Form.Control as="select" onChange={changeInputProduser}>
                <option>Pilih Produser</option>
                {props.produser &&
                  props.produser.map((item, i) => (
                    <option key={item.id} value={item.id} valuea={item.name}>
                      {capitalizeWords(item.name)}
                    </option>
                  ))}
              </Form.Control>
              <span className="text-danger" style={{ display: errorProduser }}>
                * produser tidak boleh kosong
              </span>
            </Form.Group>
            <Form.Group className="mb-4" controlId="Produser">
              <Form.Label>Cerita & Skenario</Form.Label>
              <Form.Control as="select" onChange={changeInputSkenario}>
                <option>Pilih Penulis</option>
                {props.skenario &&
                  props.skenario.map((item, i) => (
                    <option key={item.id} value={item.id} valuea={item.name}>
                      {capitalizeWords(item.name)}
                    </option>
                  ))}
              </Form.Control>
              <span className="text-danger" style={{ display: errorSkenario }}>
                * cerita & skenario tidak boleh kosong
              </span>
            </Form.Group>
            {/* penambahan casting team */}
            <Form.Group className="mb-4" controlId="Produser">
              <Form.Label>Casting Team</Form.Label>
              <Form.Control as="select" onChange={changeInputCastingTeam}>
                <option>Pilih Casting Team</option>
                {props.castingTeam &&
                  props.castingTeam.map((item, i) => (
                    <option key={item.id} value={item.id} valuea={item.name}>
                      {capitalizeWords(item.name)}
                    </option>
                  ))}
              </Form.Control>
              <span
                className="text-danger"
                style={{ display: errorCastingTeam }}
              >
                * Casting Team tidak boleh kosong
              </span>
            </Form.Group>

            {/* penambahan company id */}
            <Form.Group className="mb-4" controlId="Produser">
              <Form.Label>Company</Form.Label>
              <Form.Control
                as="select"
                onChange={changeInputCompany}
                value={inputCompanyId}
              >
                <option value="">Pilih Company</option>
                <option value="001">Sinemart</option>
                <option value="002">ESSJAY</option>
              </Form.Control>
              <span className="text-danger" style={{ display: errorCompanyId }}>
                * Company tidak boleh kosong
              </span>
            </Form.Group>
            {/* penambahan company id */}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-danger" onClick={handleClose}>
            Batal
          </Button>
          <Button
            variant="danger"
            disabled={disable}
            onClick={submitTambahUser}
          >
            Buat
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default TambahJudul;
