import React from "react";

import {Button, Image, Row, Col, Card, OverlayTrigger, Tooltip} from 'react-bootstrap';
import { Badge } from "tabler-react";
import { Link } from "react-router-dom";
import { BsTrashFill } from "react-icons/bs";

function ArtisCardDetailCasting(props) {
    return (
        <Card className="card-rounded">
            <Card.Body className="p-0 ">
                <Link to={"/artis/detail/"+props.talent.artist_id}>
                    <Image src={props.talent.foto} className="img-rounded img-card-bookmark"/>
                </Link>
                <div className="p-3">
                    <Link to={"/artis/detail/"+props.talent.artist_id}>
                        {props.talent.nama_artis.length > 15
                            ?
                                <OverlayTrigger
                                    placement="bottom"
                                    overlay={<Tooltip id="button-tooltip-2">{props.talent.nama_artis}</Tooltip>}
                                >
                                    <h4 className="font-weight-bold">{props.talent.nama_artis.substring(0, 12)}...</h4>
                                </OverlayTrigger>
                            :
                                <h4 className="font-weight-bold">{props.talent.nama_artis}</h4>
                        }
                        <div>{props.talent.usia} &#183; {props.talent.gender === 1 ? 'Laki-Laki' : 'Perempuan'}</div>
                        <div className="mt-3">
                            {props.talent.availability === 1 ?
                                <Badge color="success" className="mr-1 p-2">Available</Badge>
                                :
                                <Badge color="danger" className="mr-1 p-2">Not Available</Badge>
                            }
                        </div>
                        <div className="mt-3">
                            <div>
                                {props.talent.artis_sinemart === 1 ?
                                    <><i class='bx bxs-camera-movie text-danger'></i> <span>Sinemart</span></>
                                    :
                                    <><i class='bx bxs-camera-movie text-danger'></i> <span>Luar Sinemart</span></>
                                }
                            </div>

                            {props.talent.manajemen.length > 15
                                ?
                                    <OverlayTrigger
                                        placement="bottom"
                                        overlay={<Tooltip id="button-tooltip-2">{props.talent.manajemen}</Tooltip>}
                                    >
                                        <div className="mt-2">
                                            <><i class='bx bxs-buildings text-warning'></i> {props.talent.manajemen.substring(0, 12)}...</>
                                        </div>
                                    </OverlayTrigger>
                                :
                            
                                    <div className="mt-2">
                                        <><i class='bx bxs-buildings text-warning'></i> {props.talent.manajemen}</>
                                    </div>
                            }
                            
                        </div>
                    </Link>
                    <Row className="mt-3">
                        {props.permissionArtis === 1 ?
                            <>
                                <Col className="col-2 pt-1">
                                    <BsTrashFill onClick={() => props.hapusTalent(props.index, props.talent.id)} style={{cursor: "pointer"}}/>
                                </Col>
                                <Col className="col-10 mb-1">
                                    <Button variant="outline-danger w-100" size="sm" onClick={() => props.pilihTalent(props.index, props.talent.id)}>Pilih Talent</Button>
                                </Col>
                            </>
                            :
                            <></>
                        }
                    </Row>
                </div>
            </Card.Body>
        </Card>
    );
}
export default ArtisCardDetailCasting;