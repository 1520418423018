import React, { useState, useEffect, useContext } from "react";

import SiteWrapper from "../../Layouts/SiteWrapper";
import { Row, Col, Button, Image, Dropdown } from "react-bootstrap";
import { Card } from "tabler-react";
import axios from "axios";
import Loader from "react-loader-spinner";

import RiwayatFilterCard from "../../Components/Artis/RiwayatFilterCardComponent";
import { Context } from "../../Context/Store";
import { useHistory } from "react-router-dom";
import HapusFilter from "../../Components/Artis/HapusFilterComponent";
import { ContextAlert } from "../../Context/Alert";

function RiwayatFilter() {
  type breadcrumbItem = {|
    +value: string,
    +to?: string,
    +active?: boolean,
  |};

  const breadcrumbItems: Array<breadcrumbItem> = [
    {
      value: "Artis",
      to: "/artis",
      active: true,
    },
    {
      value: "Riwayat Filter",
      to: "/artis/riwayat-filter",
      active: false,
    },
  ];

  const [activeTab, setActiveTab] = useState({ index: 0, tanggal: "" });
  const urlMicroservice = process.env.REACT_APP_BE_DBTALENT_URL;
  const token = localStorage.getItem("token");
  const [loader, setLoader] = useState(true);
  const [dataTanggal, setDataTanggal] = useState([]);
  const [dataValue, setDataValue] = useState([]);
  const [lainnya, setLainnya] = useState(1);
  const [totalData, setTotalData] = useState(0);
  const [state, dispatch] = useContext(Context);
  const [message, setMessage] = useContext(ContextAlert);
  const history = useHistory();

  useEffect(() => {
    dispatch({
      nama_artis: "",
      gender: [1, 2],
      usia_min: "",
      usia_maks: "",
      tinggi_min: "",
      tinggi_maks: "",
      berat_min: "",
      berat_maks: "",
      peran: [],
      nilai_casting_min: "",
      nilai_casting_maks: "",
      talent_prospect: [],
      tampil_filter: false,
      filter_id: "",
      submit: true,
    });

    axios
      .get(`${urlMicroservice}talent/filter/artis`, {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        setDataTanggal(res.data.data.data);
        setLoader(false);
      })
      .catch((e) => {
        var message = e.response.data.message;
        if (!message) {
          message = e.message;
        }
        setMessage({
          type: "danger",
          message: JSON.stringify(message),
          show: "d-block",
        });
      });
  }, []);

  useEffect(() => {
    setLainnya(1);
    axios
      .get(
        `${urlMicroservice}talent/filter/artis/data?tanggal=${activeTab.tanggal}&per_page=12&page=${lainnya}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        setDataValue(Object.values(res.data.data.data));
        setTotalData(res.data.data.total);
        setLoader(false);
      })
      .catch((e) => {
        var message = e.response.data.message;
        if (!message) {
          message = e.message;
        }
        setMessage({
          type: "danger",
          message: JSON.stringify(message),
          show: "d-block",
        });
      });
  }, [activeTab]);

  useEffect(() => {
    if (lainnya != 1) {
      axios
        .get(
          `${urlMicroservice}talent/filter/artis/data?tanggal=${activeTab.tanggal}&per_page=12&page=${lainnya}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          Object.values(res.data.data.data) &&
            Object.values(res.data.data.data).map((item) => {
              setDataValue((dataValue) => [...dataValue, item]);
            });
          setTotalData(res.data.data.total);
        })
        .catch((e) => {
          var message = e.response.data.message;
          if (!message) {
            message = e.message;
          }
          setMessage({
            type: "danger",
            message: JSON.stringify(message),
            show: "d-block",
          });
        });
    }
  }, [lainnya]);

  const dropdown = (id, data) => {
    return (
      <Dropdown>
        <Dropdown.Toggle bsPrefix=" ">
          <i className="fa fa-ellipsis-v"></i>
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item
            className="text-dark"
            onClick={() => gunakanFilter(data, id)}
          >
            Gunakan Sebagai Filter
          </Dropdown.Item>
          <Dropdown.Item className="text-dark" onClick={() => hapusFilter(id)}>
            Hapus dari Riwayat
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    );
  };

  function cekLainnya() {
    setLainnya(lainnya + 1);
  }

  function gunakanFilter(data, id) {
    dispatch({
      ...state,
      nama_artis: data.nama_artis ? String(data.nama_artis) : "",
      gender: data.gender ? data.gender : [],
      usia_min: data.usia_min ? String(data.usia_min) : "",
      usia_maks: data.usia_maks ? String(data.usia_maks) : "",
      tinggi_min: data.tinggi_min ? String(data.tinggi_min) : "",
      tinggi_maks: data.tinggi_maks ? String(data.tinggi_maks) : "",
      berat_min: data.berat_min ? String(data.berat_min) : "",
      berat_maks: data.berat_maks ? String(data.berat_maks) : "",
      peran: data.peran ? data.peran : [],
      nilai_casting_min: data.nilai_casting_min
        ? String(data.nilai_casting_min)
        : "",
      nilai_casting_maks: data.nilai_casting_maks
        ? String(data.nilai_casting_maks)
        : "",
      talent_prospect: data.talent_prospect ? data.talent_prospect : [],
      judul_sinetron: data.judul_sinetron ? data.judul_sinetron : "",
      available: data.available ? data.available : "",
      penerimaan_artis_dari_tanggal: data.penerimaan_artis_dari_tanggal
        ? data.penerimaan_artis_dari_tanggal
        : "",
      penerimaan_artis_sampai_tanggal: data.penerimaan_artis_sampai_tanggal
        ? data.penerimaan_artis_sampai_tanggal
        : "",
      tampil_filter: true,
      filter_id: id,
      submit: true,
    });
    history.push(`/artis`);
  }

  function hapusFilter(id) {
    axios
      .delete(`${urlMicroservice}talent/filter/artis/delete?id=${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        setActiveTab({ ...activeTab });
      })
      .catch((e) => {
        var message = e.response.data.message;
        if (!message) {
          message = e.message;
        }
        setMessage({
          type: "danger",
          message: JSON.stringify(message),
          show: "d-block",
        });
      });
  }

  function toggleClass(index, tanggal) {
    setActiveTab({
      index: index,
      tanggal: tanggal,
    });
    setLainnya(1);
  }

  return (
    <SiteWrapper breadcrumbItems={breadcrumbItems} title={"Riwayat Filter"}>
      <Loader
        visible={loader}
        className="loaders"
        type="ThreeDots"
        color="#ED1C24"
        height="100"
        width="100"
      />
      <Row>
        <Col md="3">
          <Card className="border-card mt-5">
            <Card.Body>
              {dataTanggal.length > 0 ? (
                <div>
                  <HapusFilter jenis="artis"></HapusFilter>
                  <div className="mt-5">
                    <a
                      className={
                        (activeTab.index == 0 ? "active" : "") +
                        " tanggal-riwayat p-2 rounded w-100"
                      }
                      onClick={(e) => toggleClass(0, "")}
                      type="button"
                    >
                      Semua Riwayat
                    </a>
                    <div className="ml-5">
                      {dataTanggal &&
                        dataTanggal.map((item, index) => {
                          return (
                            <a
                              className={
                                (activeTab.index == index + 1 ? "active" : "") +
                                " tanggal-riwayat p-2 rounded w-100"
                              }
                              onClick={(e) => toggleClass(index + 1, item.date)}
                              type="button"
                            >
                              {item.name}
                            </a>
                          );
                        })}
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
            </Card.Body>
          </Card>
        </Col>
        <Col md="9">
          {dataValue &&
            dataValue.map((item, index) => {
              return (
                <RiwayatFilterCard
                  item={item}
                  dropdown={dropdown(item.id, item.paramter)}
                ></RiwayatFilterCard>
              );
            })}
          {parseInt(dataValue.length) < parseInt(totalData) ? (
            <div className="text-center mb-5">
              <Button
                variant="outline-danger"
                className="font-weight-bold"
                onClick={() => cekLainnya()}
              >
                Tampilkan Riwayat Filter Lainnya
              </Button>
            </div>
          ) : (
            ""
          )}
          {dataValue.length <= 0 ? (
            <Card className="border-card mt-5 h-100">
              <Card.Body className="d-flex flex-column align-items-center justify-content-center">
                <Image src="/images/settings.png" />
                <div className="font-weight-bold mt-5 mb-3">
                  Belum Ada Riwayat Filter
                </div>
                <div className="text-center text-grey">
                  Semua riwayat filter yang pernah Anda <br />
                  gunakan akan ditampilkan di sini
                </div>
              </Card.Body>
            </Card>
          ) : (
            ""
          )}
        </Col>
      </Row>
    </SiteWrapper>
  );
}

export default RiwayatFilter;
