import React, { useState, useEffect, useContext } from "react";

import { Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Card, Badge } from "tabler-react";
import DuplikatBagan from "./DuplikatBaganComponent";
import HapusBagan from "./HapusBaganComponent";
import axios from "axios";
import { ContextAlert } from "../../Context/Alert";
import RenameBagan from "./RenameBagan";

function BaganCard(prop) {
  const urlMicroservice = process.env.REACT_APP_BE_DBTALENT_URL;
  const token = localStorage.getItem("token");
  const [message, setMessage] = useContext(ContextAlert);

  function highlightAction(data) {
    var link = "highlighting";
    if (data === false) {
      link = "remove-highlight";
    }
    axios
      .post(
        `${urlMicroservice}talent/bagan/${link}/${prop.id}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        prop.setReload(res);
      })
      .catch(function (e) {
        var message = e.response.data.message;
        if (!message) {
          message = e.message;
        }
        setMessage({
          type: "danger",
          message: JSON.stringify(message),
          show: "d-block",
        });
      });
  }

  return (
    <Card className="bagan-card">
      <Card.Body className="p-3">
        <div className="font-weight-bold font-16 d-flex justify-content-between">
          <Link to={"/bagan/detail/" + prop.id}>
            <div className="font-weight-bold font-16">{prop.judul}</div>
          </Link>
          <Dropdown>
            <Dropdown.Toggle bsPrefix=" ">
              <i className="fa fa-ellipsis-v"></i>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {prop.status === "Highlight" ? (
                <Dropdown.Item
                  onClick={() => highlightAction(false)}
                  className="text-dark"
                >
                  Hapus Highlight
                </Dropdown.Item>
              ) : (
                <Dropdown.Item
                  onClick={() => highlightAction(true)}
                  className="text-dark"
                >
                  Jadikan Highlight
                </Dropdown.Item>
              )}
              <DuplikatBagan id={prop.id} name={prop.judul}></DuplikatBagan>
              <RenameBagan id={prop.id} name={prop.judul}></RenameBagan>
              <HapusBagan id={prop.id}></HapusBagan>
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <Link to={"/bagan/detail/" + prop.id}>
          <div className="text-grey my-3">{prop.deskripsi}</div>
          <div className="d-flex justify-content-between">
            <div className="text-grey">
              <i className="bx bxs-user"></i> {prop.jumlah} Talent
            </div>
            <div>
              <Badge
                color={prop.color}
                className={
                  (prop.status === "Draft" ? "text-dark " : "") +
                  "px-3 py-1 font-14px mr-2"
                }
              >
                {prop.status}
              </Badge>
            </div>
          </div>
        </Link>
      </Card.Body>
    </Card>
  );
}
export default BaganCard;
