import { useHistory } from "react-router-dom";
import Loader from 'react-loader-spinner';
function LogoutPm() {
  let history = useHistory();

    localStorage.clear();
    localStorage.removeItem('token');
    localStorage.removeItem('username');
    localStorage.removeItem('role');
    localStorage.removeItem('image');
    localStorage.removeItem('email');

    sessionStorage.clear();

    window.location.href = process.env.REACT_APP_LOGIN_TOKEN_URL+'/logout';

    // history.push("/login");

  return (
    <>
        <Loader visible={true} className="loaders" type="ThreeDots" color="#ED1C24" height="100" width="100" />
    </>
  );
}

export default LogoutPm