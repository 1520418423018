import React, { useContext, useState } from "react";
import { Button, Modal, Image } from "react-bootstrap";
import { Grid } from "tabler-react";
import axios from "axios";
import { ContextAlert } from "../../Context/Alert";

function HapusJadwal(props) {
  const urlMicroservice = process.env.REACT_APP_BE_DBTALENT_URL;
  const token = localStorage.getItem("token");
  const [message, setMessage] = useContext(ContextAlert);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [urlImageAvatar] = useState(
    process.env.REACT_APP_FRONTEND_URL + "images/delete.png"
  );

  function submit() {
    axios
      .delete(
        `${urlMicroservice}talent/artis/jadwal/delete?id=${props.data.id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        setMessage({
          type: "success",
          message: `Berhasil menghapus jadwal`,
          show: "d-block",
        });

        setTimeout(() => {
          window.location.reload();
        }, 500);
      })
      .catch((e) => {
        var message = e.response.data.message;
        if (!message) {
          message = e.message;
        }
        setMessage({
          type: "danger",
          message: JSON.stringify(message),
          show: "d-block",
        });
      });
  }

  return (
    <>
      <Button
        variant="outline-danger"
        className="btn-jadwal"
        onClick={handleShow}
      >
        <i className="bx bxs-trash" />
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Body>
          <Grid.Row className="my-5">
            <Grid.Col width="12" className="text-center">
              <Image
                src={urlImageAvatar}
                roundedCircle
                style={{ height: "100px", width: "100px" }}
              />
            </Grid.Col>
            <Grid.Col width="12" className="text-center mt-5">
              <p>Hapus Jadwal?</p>
              <p>Jadwal yang telah dihapus tidak dapat dikembalikan</p>
            </Grid.Col>
          </Grid.Row>
          <Grid.Row className="mb-5">
            <Grid.Col width="6" className="text-right">
              <Button
                variant="outline-danger"
                className="px-5"
                onClick={handleClose}
              >
                Batal
              </Button>
            </Grid.Col>
            <Grid.Col width="6">
              <Button
                variant="danger"
                className="px-5"
                onClick={() => submit()}
              >
                Hapus
              </Button>
            </Grid.Col>
          </Grid.Row>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default HapusJadwal;
