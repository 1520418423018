import React, { useState, useEffect } from "react";
import Breadcrumbs from "./Breadcrumbs";
import { Layout } from "./LayoutManajemenTalent/ui.layout";
import { useLocation } from "react-router-dom";

function LayoutApp(props) {
  const location = useLocation();
  const loginUrl = process.env.REACT_APP_LOGIN_TOKEN_URL;
  const logout = process.env.REACT_APP_LOGOUT_TOKEN_URL;

  const account = {
    name: localStorage.getItem("username"),
    access_level: localStorage.getItem("role"),
    image: localStorage.getItem("image"),
    token: localStorage.getItem("token"),
    url: process.env.REACT_APP_FRONTEND_URL,
  };

  const navBarItems = props.navBarItems;
  const breadcrumbItems = props.breadcrumbItems;

  return (
    <Layout
      itemsObjects={navBarItems}
      activePath={location.pathname}
      subHeader="false"
      urlLogo={account.url + "artis"}
      userName={account.name}
      accessLevel={account.access_level}
      fotoAvatar={account.image}
      logoutLink={logout + "logout-db"}
      backendUrl={process.env.REACT_APP_GATEWAY_URL}
      accessToken={account.token}
      dbTalentUrl={process.env.REACT_APP_FRONTEND_URL}
    >
      <Breadcrumbs
        itemsObjects={breadcrumbItems}
        title={props.title}
        rightBreadcrumb={props.rightBreadcrumb}
      ></Breadcrumbs>
      {props.children}
    </Layout>
  );
}

export default LayoutApp;
